import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Chart from "react-apexcharts";
const BarGraph = () => {
  const dummyData = [300, 430, 448, 470, 540];
  const dummyCategory = [
    "MU 1",
    "MU 2",
    "United Kingdom",
    "Netherlands",
    "Italy",
  ];

  const data = {
    series: [
      {
        data: [...dummyData],
      },
    ],
    options: {
      chart: {
        type: "bar",
        // height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#33b2df", "#546E7A", "#d4526e", "#13d8aa", "#A5978B"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [...dummyCategory],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: "Custom DataLabels",
        align: "center",
        floating: true,
      },
      subtitle: {
        text: "Category Names as DataLabels inside bars",
        align: "center",
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  };

  return (
    <>
      <Grid item xs={12} md={5} className="card-grid">
        <Box className="chart-class">
          <Chart
            options={data.options}
            series={data.series}
            type="bar"
            height={380}
          />
        </Box>
      </Grid>
    </>
  );
};

export default BarGraph;
