import { Box } from "@mui/system";
import React, { useContext } from "react";
import { MobileUnitContext } from "./context/MobileUnitContext";
import MobileUnitForm from "./MobileUnitForm";
import MobileUnitWindowRightHeader from "./ui/MobileUnitWindowRightHeader";
import SubLoader from "../layout/SubLoader";

const MobileUnitWindowRight = (props) => {
  const { subLoading } = useContext(MobileUnitContext);
  const styles = {
    windowWidth: {
      width: "calc(100% - 250px)",
    },
    windowRightHeader: {
      // padding : "15px"
    },
  };

  return (
    <>
      <Box className="windowRightBlock" sx={styles.windowWidth}>
        {subLoading ? (
          <SubLoader />
        ) : (
          <>
            <MobileUnitWindowRightHeader
              setAddMobileUnit={props.setAddMobileUnit}
            />
            <MobileUnitForm setAddMobileUnit={props.setAddMobileUnit} />
          </>
        )}
      </Box>
    </>
  );
};

export default MobileUnitWindowRight;
