import { Grid } from "@mui/material";
import React, { useState, useContext, Fragment, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Layout from "../layout/Layout";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ServiceProvider } from "./serviceContext/ServiceContext";
import ServiceMainBlock from "./ServiceMainBlock";

function Service() {
  const drawerWidth = 240;
  const topWidth = 70;
  // const {
  //   seriviceAllData, setServiceAllData
  //  } = useContext(ServiceContext);

  return (
    <Grid
      // sx={{
      //   width: `calc(100% - ${drawerWidth}px)`,
      //   ml: `${drawerWidth}px`,
      //   mt: `${topWidth}px`,
      //   textAlign: "left",
      //   padding: "0 25px 25px 25px",
      //   background: "#D9D9D994",
      //   height: "calc(100vh - 70px)",
      //   overflow: "auto",
      // }}
      className="grid"
    >
      <ServiceProvider>
        <ServiceMainBlock />
      </ServiceProvider>
    </Grid>
  );
}

export default Service;
