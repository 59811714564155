import { Grid } from "@mui/material";
import React from "react";
import DashboardMainBlock from "./DashboardMainBlock";

const Dashboard = (props) => {
  const drawerWidth = 240;
  const topWidth = 70;

  return (
    <>
      <Grid
        // sx={{
        //   width: `calc(100% - ${drawerWidth}px)`,
        //   ml: `${drawerWidth}px`,
        //   mt: `${topWidth}px`,
        //   textAlign: "left",
        //   padding: "25px",
        //   background: "#D9D9D994",
        //   height: "calc(100vh - 64px)",
        //   overflow: "auto",
        // }}
        className="grid"
      >
        <DashboardMainBlock />
      </Grid>
    </>
  );
};

export default Dashboard;
