import { Avatar, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "../../api/auth/login";
import loginLogo from "../../assets/img/logo/logo-fade.png"
import LoginForm from "../login/LoginForm";
import bgImage from "../../assets/img/bg/1.jpg";
import { Box } from "@mui/system";
import isAuthenticated from "../../utils/isAuthenticated";

const Login = () => {
  const loginLeft = {
    height: "100vh",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative"
  }
  const loginRight = {
    height: "100vh",
    display: 'flex',
    alignItems: 'center',
    width: "calc(100% - 50%)",
    background: "#ffffff",
    borderRadius: "50px 0 0 50px",
    justifyContent: "center"
  }

  const loginLogoStyles = {
    width: "140px",
    height: "140px",
    position: "absolute",
    top: "0",
    right: "0"
  }
  const loginBlock = {
    width: "100%",
    maxWidth: "320px",
  }

  const [error, setError] = useState("");
  // const [userCheck, setUserCheck] = useState([]);

  //   //Check Usertype 
  //   useEffect(() => {
  //     let userType = localStorage.getItem("userType");
  //     setUserCheck({
  //       usertype: userType,
  //     });

  //     console.log(userType,".....................userType");
  //     console.log(userCheck,".....................userCheck");
  //   }, []);

  //   // console.log(userType,".....................userType after useefect");
  //   console.log(userCheck,".....................userCheck after useefect");




  let navigate = useNavigate();

  const handleClick = (type) => {
    if (isAuthenticated() && type == 1) {
      // console.log("login from admin");
      navigate('/console/dashboard');
    }
    else if(isAuthenticated() && type == 3){
      // console.log("login from Sub admin");
      navigate('/console/dashboard');
    }
    else if(isAuthenticated() && type == 6){
      // console.log("login from Sub admin");
      navigate('/console/booking');
    }
  }

  const loginAction = async (details) => {
    loginAPI(details)
      .then((res) => {
        let userType = localStorage.getItem("userType");
        handleClick(userType)
      })
      .catch((e) => {
        setError(e.message)
      });
  };

  return (

    <Box className="login">
      <Box sx={{ display: "flex" }}>
        <Grid sx={loginLeft}>
          <Box>
            <Avatar src={loginLogo} alt="logo"
              sx={loginLogoStyles} />
          </Box>
        </Grid>
        <Grid sx={loginRight}>
          <Box sx={loginBlock}>
            <h3 style={{ color: '#252525', textAlign: "left" }}>Hello Admin!</h3>
            <p style={{ color: '#424242', textAlign: "left", fontSize: "20px" }}>Sign in to your account below.</p>
            <LoginForm Login={loginAction} error={error} />
          </Box>
        </Grid>
      </Box>
    </Box>

  );
};

export default Login;
