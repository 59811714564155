import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import Cards from "./Cards";
import BarGraph from "./graphs/BarGraph";
import Donut from "./graphs/Donut";
import Graph from "./graphs/Graph";
import SplineArea from "./graphs/SplineArea";
import Table from "./Table";

const DashboardMainBlock = () => {
  return (
    <Box className="mainBlock " 
    sx={{ padding: "20px 20px" }}
    >
      <Grid container spacing={2}>
        <Cards />
        {/* <SplineArea /> */}
        <BarGraph />
        <Table />
        {/* <Donut /> */}
      </Grid>
    </Box>
  );
};

export default DashboardMainBlock;
