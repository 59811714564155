import { consoleAPI } from "../axios";

const consoleInst = consoleAPI();

export const loginAPI = async (data) => {
        const res = await consoleInst({
            method: 'POST',
            url: 'console/login',
            data
        });try {
    
        if (res.success) {
            localStorage.setItem('authToken', res.data.token);
            localStorage.setItem('userType', res.data.user.user_type);
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('selectedIndex',0);
            localStorage.setItem('username',res.data.user.user_name);
            localStorage.setItem('userid',res.data.user.user_id_pk);
        }
        if(res.success) return res;
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
