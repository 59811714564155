import { Box, Chip } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useContext } from "react";
import { BookingContext } from "./bookingContext/BookingContext";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import BookingWidowRight from "./BookingWidowRight";
import { getMappedBooking } from "../../../api/console/Booking/booking-management";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import dateFormat from "dateformat";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useEffect } from "react";
import Loader from "../layout/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

const BookingMainBlock = () => {
  const {
    bookingAllData,
    addBooking,
    SetAddBooking,
    viewBooking,
    editBooking,
    setViewBooking,
    SetMapppedBooking,
    setViewReadOnly,
    setAllData,
    initialState,
    filterValue,
    setFilterValue,
    bookDate,
    setBookDate,
    filterBookingshandler,
    SetEditBooking,
    setVechileNumber,
    loading,
    setSubLoading,
    viewReadOnly,
    serviceStartTime,
    setServiceStartTime,
    serviceEndTime,
    setServiceEndTime,
    userCheck, setUserCheck
  } = useContext(BookingContext);
  let lettersA_E = ["A", "B", "C", "D", "E"];
  let lettersF_J = ["F", "G", "H", "I", "J"];
  let lettersK_O = ["K", "L", "M", "N", "O"];
  let lettersP_T = ["P", "Q", "R", "S", "T"];
  let lettersU_Z = ["U", "V", "W", "X", "Y", "Z"];
  const columns = [
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        let alphabetClass;
        const CustomerName = params.row.customer.customer_name;

        const fullWord = CustomerName.split(" ");
        // console.log(fullWord);

        const firstWord = fullWord;
        const secondWord = fullWord[1];

        // const lastWord = CustomerName.split(" ").pop();
        // const secondWord = fullWord[1];
        let firstLetter = CustomerName.charAt(0).toUpperCase();
        if (lettersA_E.includes(firstLetter)) {
          alphabetClass = "lettersA_E";
        } else if (lettersF_J.includes(firstLetter)) {
          alphabetClass = "lettersF_J";
        } else if (lettersK_O.includes(firstLetter)) {
          alphabetClass = "lettersK_O";
        } else if (lettersP_T.includes(firstLetter)) {
          alphabetClass = "lettersP_T";
        } else if (lettersU_Z.includes(firstLetter)) {
          alphabetClass = "lettersU_Z";
        }
        return (
          <>
            <Box className={`fstLetter ${alphabetClass}`}>
              <p>{firstLetter}</p>
            </Box>
            {firstWord.shift()}
            <Box className="word-space"> {secondWord}</Box>

            {/* {CustomerName} */}
          </>
        );
      },
    },
    {
      field: "Phone",
      headerName: "Mobile Number",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.customer.phone_extension}
            <span className="word-space ">
              {params.row.customer.phone_number}
            </span>{" "}
          </>
        );
      },
    },
    {
      field: "Vehicle Number",
      headerName: "Vehicle Number",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.customerVehicle.vehicle_registration_number}</>;
      },
    },
    {
      field: "Service",
      headerName: "Service",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.orderServices[0].service_name}</>;
      },
    },
    {
      field: "Amount",
      headerName: "Amount ",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.payable_amount}</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === 1 && <Chip label="Open" color="primary" />}
            {params.row.status === 2 && (
              <Chip label="Assigned" color="primary" />
            )}
            {params.row.status === 3 && (
              <Chip label="In Progress" color="secondary" />
            )}
            {params.row.status === 4 && (
              <Chip label="Completed" color="success" />
            )}
            {params.row.status === 5 && (
              <Chip label="Rescheduled" color="primary" />
            )}
            {params.row.status === 6 &&
              params.row.cancelInfo.cancel_type === "customer" && (
                <Chip label="Cancelled" color="warning" />
              )}
            {params.row.status === 6 &&
              params.row.cancelInfo.cancel_type === "admin" && (
                <Chip label="Rejected" color="error" />
              )}
            {/* {params.row.status === 2 && (
              <Chip label="Engaged" color="warning" />
            )} */}
          </>
        );
      },
    },
  ];

  const [calendar, setCalendar] = useState(false);
  const [selectBookDate, setSelectBookDate] = useState([
    dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
    dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
  ]);

  const nextDay = dateFormat(
    new Date(
      new Date(bookDate[0]).setDate(new Date(bookDate[0]).getDate() + 1)
    ),
    "UTC:yyyy-mm-dd HH:MM"
  );

  const prevDay = dateFormat(
    new Date(
      new Date(bookDate[0]).setDate(new Date(bookDate[0]).getDate() - 1)
    ),
    "UTC:yyyy-mm-dd HH:MM"
  );

  let startDate = dateFormat(new Date(bookDate[0]), "fullDate");
  let endDate = dateFormat(new Date(bookDate[1]), "fullDate");

  const chooseDate = (dateValue) => {
    if (dateValue === nextDay) {
      setBookDate([dateValue, dateValue]);
      filterBookingshandler(dateValue);
    }
    if (dateValue === prevDay) {
      setBookDate([dateValue, dateValue]);
      filterBookingshandler(dateValue);
    }
  };

  const dateRangeFilterhandler = (e) => {
    setSelectBookDate(e);
  };

  const calendarOk = () => {
    if (
      dateFormat(selectBookDate[0], "UTC:yyyy-mm-dd") ===
      dateFormat(selectBookDate[1], "UTC:yyyy-mm-dd")
    ) {
      setFilterValue(
        bookingAllData.filter((item) =>
          dateFormat(item.service_date, "UTC:yyyy-mm-dd").includes(
            dateFormat(selectBookDate[0], "UTC:yyyy-mm-dd")
          )
        )
      );
    } else {
      setFilterValue(
        bookingAllData.filter(
          (item) =>
            dateFormat(item.service_date, "UTC:yyyy-mm-dd") >=
              dateFormat(selectBookDate[0], "UTC:yyyy-mm-dd") &&
            dateFormat(item.service_date, "UTC:yyyy-mm-dd") <=
              dateFormat(selectBookDate[1], "UTC:yyyy-mm-dd")
        )
      );
    }
    setBookDate(selectBookDate);

    setCalendar(false);
    setSelectBookDate([
      dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
      dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
    ]);
  };

  const currentDateHandler = () => {
    setBookDate([
      dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
      dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
    ]);
    filterBookingshandler(dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"));
  };

  const viewCalendar = () => {
    setCalendar(true);
  };

  const handleViewClick = (e) => {
    setSubLoading(true);
    setViewBooking(true);
    setViewReadOnly(true);
    SetEditBooking(false); //added later
    const formData = {
      order_id: e.order_id,
    };
    const response = getMappedBooking(formData);
    response.then((res) => {
      SetMapppedBooking(res);
    });
    setSubLoading(false);
  };

  const handleAddBooking = () => {
    setViewReadOnly(false);
    setVechileNumber([]);
    // setSubLoading(true);
    SetAddBooking(true);
    SetEditBooking(false);
    setViewBooking(false);
    SetMapppedBooking({});
    setAllData(initialState);
    setServiceStartTime(dayjs(new Date()));
    setServiceEndTime(dayjs(new Date()));

    // setSubLoading(false);
  };

  useEffect(() => {
    setFilterValue(
      bookingAllData &&
        bookingAllData.filter((item) =>
          dateFormat(
            new Date(item.service_date),
            "UTC:yyyy-mm-dd 00:00",
            "yyyy-mm-dd"
          ).includes(
            dateFormat(new Date(), "UTC:yyyy-mm-dd 00:00", "yyyy-mm-dd")
          )
        )
    );
  }, [bookingAllData, setFilterValue]);

  //function for no data available
  const customRowsOverlay = () => {
    return (
      <GridOverlay>
        <p>No Data Available</p>
      </GridOverlay>
    );
  };
  return (
    <Fragment>
      <Box className="mainBlock">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box className="title-search">
              <Box className="box-left">
                <span className="main-header">Bookings</span>
                {userCheck && userCheck.usertype == 1 ? <Button
                  variant="outlined"
                  sx={{ mb: "15px" }}
                  onClick={handleAddBooking}
                >
                  Add New
                </Button> :""}
              </Box>
              {/* <Box
                className="box-right"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button className="btn-icon">
                  Filter <FilterAltOutlinedIcon />
                </Button>
                <Button className="btn-icon">
                  Sort <FilterListOutlinedIcon />
                </Button>
              </Box> */}
            </Box>
            {!viewBooking && !editBooking && !addBooking && (
              <Box className="date-section">
                <div className="date-block-main">
                  <button
                    className="btn btn-none"
                    id=""
                    rel="tooltip"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Add New"
                    onClick={viewCalendar}
                  >
                    <span>
                      <i className="fal fa-calendar"></i>
                    </span>
                  </button>
                  {startDate !== endDate ? (
                    <button onClick={currentDateHandler}>Today</button>
                  ) : (
                    ""
                  )}

                  <div className="date-block">
                    <div className="date-control">
                      <div
                        className={`date-control-sub ${
                          startDate === endDate ? "" : "disabled-block"
                        }`}
                      >
                        <div
                          className="date-control-left"
                          onClick={() => chooseDate(prevDay)}
                        >
                          <i class="fal fa-caret-left"></i>
                        </div>
                        <div
                          className="date-control-right"
                          onClick={() => chooseDate(nextDay)}
                        >
                          <i class="fal fa-caret-right"></i>
                        </div>
                      </div>
                    </div>
                    <div className="date">
                      {startDate === endDate ? (
                        <h6>{startDate}</h6>
                      ) : (
                        <h6>
                          {startDate} - {endDate}
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
                {calendar && (
                  <div className="popup-main">
                    <div className="popup-sub">
                      <div className="popup-content">
                        <div className="popup-header"></div>
                        <div className="popup" id="popupDiv">
                          <Calendar
                            selectRange={true}
                            goToRangeStartOnSelect={true}
                            onChange={(e) => dateRangeFilterhandler(e)}
                            value={selectBookDate}
                          />
                        </div>
                        <div className="popup-footer">
                          <button
                            className="btn btn-light"
                            onClick={(e) => {
                              setCalendar(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={calendarOk}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Box>
            )}
            <Box
              className={`dataTable-block ${addBooking && "windowChange"}
               ${viewBooking && "windowChange"}
                ${viewBooking && !editBooking && "bookingWindowFullWidth"} 
                `}>
              <Box
                className="data-table-sub-block"
                style={{ width: "100%", background: "#fff" }}
              >
                <DataGrid
                  components={{
                    NoRowsOverlay: customRowsOverlay,
                  }}
                  onRowClick={(e) => handleViewClick(e.row)}
                  rows={filterValue}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  getRowId={(row) => row.order_id}
                  // getRowId={(row) => row.order_unique_id}
                />
              </Box>
              <BookingWidowRight />
              
            </Box>
          </>
        )}
      </Box>
      <ToastContainer autoClose={1500} />
    </Fragment>
  );
};

export default BookingMainBlock;
