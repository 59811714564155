import { consoleAPI } from "../../axios";
//Get MU list
const getMuList = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "/console/subadmin/mobile-units",
    });
    if (res.success) {
      // console.log(res, "res getMuList");
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
//Get SubAdminList
const getSubAdminList = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "/console/subadmin/list",
    });
    if (res.success) {
      // console.log(res, "res getSubAdminList");
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
//Create or Update SubAdmin
const createOrUpdateSubAdmin = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/subadmin/create",
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

//View SubAdmin
const getSubAdmin = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/subadmin/data",
      data,
    });
    if (res.success) {
      console.log(res, "res getSubAdmin data");
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

//Delete Sub Admin
const deleteSubAdmin = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/subadmin/delete",
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
//Create or Update SubAdmin Credentials
const updateSubAdminCredential = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/subadmin/update-password",
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

//Get MU List on Edit

const getMUonEdit = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/subadmin/mobile-units",
      data,
    });
    if (res.success) {
      // console.log(res , "res getReqData")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

export {
  getMuList,
  getSubAdminList,
  createOrUpdateSubAdmin,
  getSubAdmin,
  deleteSubAdmin,
  updateSubAdminCredential,
  getMUonEdit,
};
