import { Box, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { getBookingsList } from "../../../api/console/Dashboard/dashboard-management";

const Table = () => {
  const [dashboardTable, setDashboardTable] = useState([]);
  let lettersA_E = ["A", "B", "C", "D", "E"];
  let lettersF_J = ["F", "G", "H", "I", "J"];
  let lettersK_O = ["K", "L", "M", "N", "O"];
  let lettersP_T = ["P", "Q", "R", "S", "T"];
  let lettersU_Z = ["U", "V", "W", "X", "Y", "Z"];
  const columns = [
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        // const createdTime = new Date(params.row.created_at);
        // console.log(createdTime);

        let alphabetClass;
        const CustomerName = params.row.customer.customer_name;

        const fullWord = CustomerName.split(" ");

        const firstWord = fullWord;
        const secondWord = fullWord[1];
        let firstLetter = CustomerName.charAt(0).toUpperCase();
        if (lettersA_E.includes(firstLetter)) {
          alphabetClass = "lettersA_E";
        } else if (lettersF_J.includes(firstLetter)) {
          alphabetClass = "lettersF_J";
        } else if (lettersK_O.includes(firstLetter)) {
          alphabetClass = "lettersK_O";
        } else if (lettersP_T.includes(firstLetter)) {
          alphabetClass = "lettersP_T";
        } else if (lettersU_Z.includes(firstLetter)) {
          alphabetClass = "lettersU_Z";
        }
        return (
          <>
            {/* <Box className={`fstLetter ${alphabetClass}`}>
              <p>{firstLetter}</p>
            </Box> */}
            {CustomerName}
            {/* {firstWord.shift()}
            <Box className="word-space"> {secondWord}</Box> */}
          </>
        );
      },
    },
    {
      field: "Phone",
      headerName: "Mobile Number",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.customer.phone_extension}
            <Box className="word-space">{params.row.customer.phone_number}</Box>
          </>
        );
      },
    },
    // {
    //   field: "actions",
    //   headerName: "Estimated time",
    //   width: 400,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.estimated_hours}h : {params.row.estimated_minutes}m
    //       </>
    //     );
    //   },
    // },
    {
      field: "Vehicle Number",
      headerName: "Vehicle Number",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.customerVehicle.vehicle_registration_number}</>;
      },
    },
    {
      field: "Service",
      headerName: "Service ",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.orderServices[0].service_name}</>;
      },
    },
    {
      field: "Amount",
      headerName: "Amount ",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.payable_amount}</>;
      },
    },
    {
      field: "mobileUnit",
      headerName: "Mobile Unit",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.mobileUnit != null
              ? params.row.mobileUnit.name && params.row.mobileUnit.name
              : "No MU"}
          </>
        );
      },
    },

    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.status == 1 && <Chip label="Open" color="success" />}
    //         {params.row.status == 2 && (
    //           <Chip label="Assigned" color="primary" />
    //         )}
    //         {params.row.status == 3 && (
    //           <Chip label="In Progress" color="secondary" />
    //         )}
    //         {params.row.status == 4 && (
    //           <Chip label="Completed" color="success" />
    //         )}
    //         {params.row.status == 5 && (
    //           <Chip label="Rescheduled" color="primary" />
    //         )}
    //         {params.row.status == 6 && (
    //           <Chip label="Cancelled" color="danger" />
    //         )}
    //         {/* {params.row.status === 2 && (
    //           <Chip label="Engaged" color="warning" />
    //         )} */}
    //       </>
    //     );
    //   },
    // },
  ];
  useEffect(() => {
    const data = {
      from_date: "",
      service_date: "",
      to_date: "",
      status: "incomplete",
    };
    console.log(data, "status completed");
    const res = getBookingsList(data);
    res.then((res) => {
      console.log(res , "getBookingsList");
      setDashboardTable(res);
    });
  }, []);

  return (
    <>
      <Grid item xs={12} md={7} className="card-grid">
        <Box
          className="data-table-sub-block dashboard-datagrid"
          style={{ width: "100%", background: "#fff" }}
        >
          <DataGrid
            // onRowClick={(e) => handleViewClick(e.row)}
            rows={dashboardTable}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.order_id}
          />
        </Box>
      </Grid>
    </>
  );
};

export default Table;
