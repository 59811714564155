import { consoleAPI } from "../axios";

export const logout = async () => {
    const axiosInst = consoleAPI();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'console/logout',
            data: {},
        });
        if (res.success) {
            localStorage.removeItem('authToken');
            localStorage.setItem('isAuthenticated', false);
            localStorage.removeItem('selectedIndex');
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return err;
    }
}