import { consoleAPI } from "../../axios";

const getBookingsList = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/list",
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const getMappedBookingHistory = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/data",
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const getActivityData = async (data) => {
  const consoleInst = consoleAPI();
  const url = "console/booking/get-activity-data";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
export { getBookingsList, getMappedBookingHistory, getActivityData };
