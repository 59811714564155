import { Box } from "@mui/material";
import React, { useContext } from "react";
import BookingHistoryContent from "./BookingHistoryContent";
import { BookingHistoryContext } from "./bookinghistoryContext/BookingHistoryContext";
import BookingHistoryWindowHeader from "./BookingHistoryWindowHeader";
import SubLoader from "../layout/SubLoader";

const BookingHistoryWindowRight = () => {
  const { subLoading } = useContext(BookingHistoryContext);

  const styles = {
    windowWidth: {
      width: "calc(100% - 250px)",
    },
  };

  return (
    <Box className="windowRightBlock" sx={styles.windowWidth}>
      {subLoading ? (
        <SubLoader />
      ) : (
        <>
          <BookingHistoryWindowHeader />
          <BookingHistoryContent />
        </>
      )}
    </Box>
  );
};

export default BookingHistoryWindowRight;
