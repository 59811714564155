import React, { useEffect, useState } from "react";
import "../../../assets/fontawesome/css/fontawesome-all.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { SidebarList } from "./SidebarList";
import { Avatar } from "@mui/material";
// import { useHistory } from 'react-router-dom'

const Sidebar = () => {
  const navigate = useNavigate();
  const drawerWidth = 240;
  const logo = require("../../../assets/img/logo/logo.png");
  const [userSidebarList, setUserSidebarList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("selectedIndex")
      ? localStorage.getItem("selectedIndex")
      : 0
  );
//   const [ locationKeys, setLocationKeys ] = useState([])
// const history = useHistory()

// useEffect(() => {
//   return history.listen(location => {
//     if (history.action === 'PUSH') {
//       setLocationKeys([ location.key ])
//     }

//     if (history.action === 'POP') {
//       if (locationKeys[1] === location.key) {
//         setLocationKeys(([ _, ...keys ]) => keys)

//         // Handle forward event

//       } else {
//         setLocationKeys((keys) => [ location.key, ...keys ])

//         // Handle back event

//       }
//     }
//   })
// }, [ locationKeys, ])
  useEffect(() => {
    
    localStorage.setItem("selectedIndex", selectedIndex);
    setSelectedIndex(JSON.parse(selectedIndex));
  }, [selectedIndex]);
  useEffect(() => {
    let userSidebarList = SidebarList.filter((menu) => {
      if (localStorage.getItem("userType") == 1) {
        // super-admin
        return true;
      } else if (localStorage.getItem("userType") == 2) {
        // manager-admin
        return true;
      } else if (localStorage.getItem("userType") == 3) {
        // manager-app
        return true;
      } else if (localStorage.getItem("userType") == 4) {
        // mobile-unit
        return true;
      } else if (localStorage.getItem("userType") == 5) {
        // customer-app
        return true;
      } else if (localStorage.getItem("userType") == 6) {
        // subadmin
        return [4, 5].indexOf(menu.id) !== -1;
      } else {
        return false; 
      }
    });
    setUserSidebarList(userSidebarList);
  }, [SidebarList]);

  const handleListItemClick = (event) => {
    setSelectedIndex(event);
    console.log(event, "index");
  };

  const styles = {
    list: {
      background: "#ffffff",
      fontSize: "0.9rem !important",
      padding: "20px 15px !important",
      "& 	.MuiListItem-root ": {
        "&:hover, &:focus": {
          backgroundColor: " #ffcc80",
          borderRadius: "0.6rem",
          color: "#fff",
          "& .MuiSvgIcon-root": {
            color: "#ffffff !important",
          },
        },
      },

      "& .Mui-selected": {
        backgroundColor: "#ffb84d !important",
        borderRadius: "6px",
        color: "#fff",
        "& .MuiSvgIcon-root": {
          color: "#ffffff !important",
        },
      },
    },
  };

  const sideBar = {
    padding: "15px",
    background: "#f7f7f7",
    height: "calc(100vh - 65px)",
    borderTop: "solid 1px #ffffff",
  };

  const sideBarList = {
    height: "100%",
    background: "#ffffff",
    borderRadius: "12px",
    overflow: "auto",
  };

  const linkBtn = {
    borderRadius: "10px !important",
    overflow: "hidden",
    marginBottom: "10px",
  };
  const logoStyles = {
    padding: "0",
    "& img": {
      objectFit: "contain !important",
    },
  };

  return (
    <Box sx={{ display: "flex" }} className="menu">
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "none !important",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            height: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#ffffff",
          }}
        >
          <Avatar alt="logo" src={logo} sx={logoStyles} />{" "}
          <span
            style={{
              textTransform: "uppercase",
              fontWeight: "600",
              letterSpacing: "1.5px",
            }}
          >
            Seahorse
          </span>
        </Box>
        {/* <Divider /> */}
        <Box sx={sideBar}>
          <Box sx={sideBarList}>
            <List sx={styles.list}>
              {userSidebarList.map((item, index) => (
                <ListItem
                  sx={linkBtn}
                  disablePadding
                  key={item.id}
                  button
                  onClick={() => navigate(item.route)}
                >
                  <ListItemButton
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(index)}
                  >
                    <ListItemIcon sx={{ minWidth: "32px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText>{item.label}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        {/* <Divider /> */}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
