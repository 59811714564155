import { Button, Chip, FormLabel, Switch } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { ServiceContext } from "./serviceContext/ServiceContext";
import ServiceWindowRight from "./ServiceWindowRight";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {
  changeStatus,
  getServiceList,
} from "../../../api/console/Service/service-management";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Loader from "../layout/Loader";
import { ToastContainer } from "react-toastify";
function ServiceMainBlock() {
  const {
    serviceAllData,
    setServiceAllData,
    addService,
    setAddService,
    viewService,
    setViewService,
    mappedService,
    setMappedService,
    viewReadOnly,
    setViewReadOnly,
    getServiceData,
    setEditService,
    editService,
    getList,
    loading,
    setLoading,
    getVehicleVariantList,
  } = useContext(ServiceContext);
  const handleServiceToggle = (event, status, id) => {
    event.stopPropagation();
    event.preventDefault();
    const data = {
      type: status == 1 ? "inactive" : "active",
      service_id: id,
    };
    const response = changeStatus(data);
    response.then((res) => {
      console.log(res, "getStatusChange data");
      listTable();
      // console.log(response, "getStatusChange response");
    });
  };
  const listTable = () => {
    const response = getServiceList();
    response.then((res) => {
      console.log(res, "getServiceList");
      setServiceAllData(res);
    });
  };
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const columns = [
    // { field: "service_id", headerName: "ID", flex: 1 },
    { field: "service_name", headerName: "Service Name", flex: 1 },
    { field: "category_name", headerName: "Category", flex: 1 },
    // { field: "estimated_hours", headerName: "Time", flex: 1 },
    // {
    //   field: "actions",
    //   headerName: "Estimated time",
    //   width: 400,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.estimated_hours}h : {params.row.estimated_minutes}m
    //       </>
    //     );
    //   },
    // },
    {
      field: "price-sedan",
      headerName: "Price - Sedan",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.servicePrices[0].price}
            <span className="word-space ">AED</span>
          </>
        );
      },
    },
    {
      field: "price-4*4",
      headerName: "Price - 4*4",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.servicePrices[1].price}
            <span className="word-space ">AED</span>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.status == 1 && <Chip label="Active" color="success" />}
            {params.row.status == 0 && (
              <Chip label="Inactive" color="primary" />
            )}
            {/* {params.row.status === 2 && (
              <Chip label="Engaged" color="warning" />
            )} */}
          </>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {/* <Switch
              checked={params.row.status}
              color="warning"
              // disabled={viewReadOnly}
              // onChange={(e) => handleServiceToggle(e, e.target.checked)}
              onClick={handleServiceToggle}
            /> */}
            <BootstrapTooltip title="Change status" placement="right">
              <Switch
                {...label}
                onClick={(e) =>
                  handleServiceToggle(
                    e,
                    params.row.status,
                    params.row.service_id
                  )
                }
                defaultChecked={params.row.status == 1 ? true : false}
                color="warning"
              />
            </BootstrapTooltip>
          </>
        );
      },
    },

    // {
    //     field: 'category',
    //     headerName: 'Category',
    //     width: 150,
    //     renderCell: (row) =>
    //     //  (
    //     //     params.row.category
    //     // )
    //     {
    //         console.log(row , "params")
    //         // console.log(params.row , "params row")
    //         console.log(row.category , "params row category ")
    //     }
    //     //  params.category
    //    ,
    //     // type: 'string',
    //   },
    // { field: "status", headerName: "Status", flex: 1 },
  ];
  const handleAdddService = () => {
    console.log("AddNewService");
    setMappedService({});
    setAddService(true);
    setViewReadOnly(false);
    setViewService(false)
    setEditService(false);
    getVehicleVariantList();
  };
  const handleViewClick = (e) => {
    console.log(e.row.service_id, "e.id");

    getServiceData(e.row.service_id);

    console.log(e, "getServiceData");
    setViewService(true);
    setEditService(false);
    setViewReadOnly(true);
    setAddService(false);
    // let rowFilter = serviceAllData.filter((item) => {
    //   return item.service_id === e.id;
    // });
    // // getServiceData
    // const objData = Object.assign({}, ...rowFilter);
    // setMappedService(rowFilter[0]);
  };
  //function for no data available
  const customRowsOverlay = () => {
    return (
      <GridOverlay>
        <p>No Data Available</p>
      </GridOverlay>
    );
  };
  return (
    <Fragment>
      <Box className="mainBlock">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box className="title-search">
              <Box className="box-left">
                <span className="main-header">Services</span>
                <Button
                  variant="outlined"
                  sx={{ mb: "15px" }}
                  onClick={handleAdddService}
                >
                  Add New
                </Button>
              </Box>
              {/* <Box
                className="box-right"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button className="btn-icon">
                  Filter <FilterAltOutlinedIcon />
                </Button>
                <Button className="btn-icon">
                  Sort <FilterListOutlinedIcon />
                </Button>
              </Box> */}
            </Box>
            <Box
              className={`dataTable-block ${addService && "windowChange"} 
              ${viewService && "windowChange"}
              ${viewService && !editService && "serviceWindowFullWidth"} `}
            >
              <Box
                className="data-table-sub-block"
                style={{ width: "100%", background: "#fff" }}
              >
                <DataGrid
                  components={{
                    NoRowsOverlay: customRowsOverlay,
                  }}
                  onRowClick={(e) => handleViewClick(e)}
                  rows={serviceAllData}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  getRowId={(row) => row.service_id}
                />
              </Box>
              <ServiceWindowRight />
            </Box>
          </>
        )}
      </Box>
      <ToastContainer />
    </Fragment>
  );
}

export default ServiceMainBlock;
