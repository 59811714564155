import { consoleAPI } from "../../axios";

const createOrUpdateStaff = async (data) => {
  const consoleInst = consoleAPI();
  const url = "/console/staff/create";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getStaffList = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "GET",
      url: "/console/get-staff-list",
    });
    if (res.success) {
      console.log(res, "res get-staff-list");
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const getStaff = async (id = undefined) => {
  const url = "/console/get-staff/" + id;
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "GET",
      url,
    });
    if (res.success) {
      console.log(res, "res get-staff-list");
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const deleteStaff = async (id = undefined) => {
  const consoleInst = consoleAPI();
  const url = "/console/staff/delete/" + id;
  try {
    const res = await consoleInst({
      method: "GET",
      url,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};



// const getStaff = async (data) => {
//   const consoleInst = consoleAPI();
//   try {
//     const res = await consoleInst({
//       method: "GET",
//       url: "/get-staff/:staffId",
//       data
//     });
//     if (res.success) {
//       console.log(res, "res get-staff-list");
//       return res.data;
//     }
//     return Promise.reject(res);
//   } catch (err) {}
// };

export { getStaffList, createOrUpdateStaff, getStaff, deleteStaff };
