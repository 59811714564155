import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import {
  changeStatus,
  createService,
  deleteService,
  getCategories,
  getService,
  getServiceList,
  getVehicleVariants,
  // changeStatus,
} from "../../../../api/console/Service/service-management";

export const ServiceContext = createContext();

export const ServiceProvider = (props) => {
  const [serviceAllData, setServiceAllData] = useState([
    // {
    //   service_id: uuidv4(),
    //   service_category_id: 5,
    //   service_name: "Bike Polishing",
    //   description: "Full body polishing.",
    //   status: 0,
    //   created_at: "2022-11-02T06:34:56.000Z",
    //   updated_at: "2022-11-03T05:01:18.000Z",
    //   serviceImages: [
    //     {
    //       service_image_id: 7,
    //       image_url: "/uploads/services/1667403425625-763.jpg",
    //     },
    //   ],
    //   servicePrices: [
    //     {
    //       service_price_id: 17,
    //       vehicle_variant_id: 1,
    //       price: 54,
    //       vehicleVariant: {
    //         variant: "Sedan / Coupe",
    //       },
    //     },
    //     {
    //       service_price_id: 18,
    //       vehicle_variant_id: 2,
    //       price: 120,
    //       vehicleVariant: {
    //         variant: "4*4",
    //       },
    //     },
    //   ],
    //   category: {
    //     category: "Dry Package",
    //   },
    //   estimated_hours: "02",
    //   estimated_minutes: "00",
    // },
  ]);
  const [addService, setAddService] = useState(false);
  const [editService, setEditService] = useState(false);
  const [viewService, setViewService] = useState(false);
  const [mappedService, setMappedService] = useState({});
  const [viewReadOnly, setViewReadOnly] = useState(false);
  const [categoryData, setCategoryData] = useState([{}]);
  const [addImageSubmit, setAddImageSubmit] = useState(true);
  const [vehicleVariantMain, setVehicleVariantMain] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);

  useEffect(() => {
    // const response = getServiceList();
    // response.then((res) => {
    //   console.log(res);
    //   setServiceAllData(res);
    // });
    getList();
    getCategoryList();
    getVehicleVariantList();
    // getStatusChange();
    // getServiceDelete();
  }, []);
  const getCategoryList = () => {
    const response = getCategories();
    response.then((res) => {
      console.log(res, "getCategoryList");
      setCategoryData(res);
    });
  };
  const getVehicleVariantList = () => {
    const response = getVehicleVariants();
    response.then((res) => {
      console.log(res, "getVehicleVariantList");
      let variantList = updateVehicleVariantData(res);
      setVehicleVariantMain(variantList);
    });
  };

  const updateVehicleVariantData = (data) => {
    let newList = data.map((vehicle) => ({
      price: "",
      vehicleVariant: {
        variant: vehicle.variant,
        vehicle_variant_id: vehicle.vehicle_variant_id,
      },
      vehicle_variant_id: vehicle.vehicle_variant_id,
      service_price_id: "",
      errors: "",
    }));
    console.log(newList, "newlist variant");
    return newList;
  };

  const getList = () => {
    setLoading(true);
    const response = getServiceList();
    response.then((res) => {
      console.log(res, "getServiceList");
      setServiceAllData(res);
      setLoading(false);
    });
  };

  const handleServiceEdit = (newData, id) => {
    let editData = serviceAllData.map((item) => {
      if (item.service_id === id) {
        return newData;
      }
      return item;
    });
    setServiceAllData(editData);
    console.log(editData, "editdata");
  };
  const updateToast = () => {
    return editService ? "Updated Successfully" : "Added Successfully";
  };
  const onSuccess = () => {
    toast.success(updateToast, {
      position: "bottom-right",
      autoClose: 1000,
      toastId: "update",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const onFailure = () => {
    toast.success("Failed", {
      position: "bottom-right",
      autoClose: 1000,
      toastId: "update",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleCreate = (data) => {
    console.log(data, "create data");
    const response = createService(data);
    response
      .then((res) => {
        console.log(res, "createService");
        getList();
        onSuccess();
      })
      .catch((err) => {
        console.log(err);
        onFailure();
      });
  };

  // View Service

  const getServiceData = (id) => {
    setSubLoading(true);
    const data = {
      service_id: id,
    };
    const response = getService(data);
    response.then((res) => {
      console.log(res, "getServiceData");
      setMappedService(res);
      console.log(mappedService, "mappedService");
      setSubLoading(false);
    });
  };

  //Change Status

  // const getStatusChange = (id, statusChange) => {
  //   const data = {
  //     service_id: id,
  //   };
  //   const response = changeStatus(data);
  //   response.then((res) => {
  //     console.log(res, "getStatusChange data");
  //     // console.log(response, "getStatusChange response");
  //   });
  // };
  //delete modal
  const [deleteServiceModal, setDeleteServiceModal] = useState(false);
  //Delete Service

  const getServiceDelete = (id) => {
    const data = {
      service_id: id,
    };

    const response = deleteService(data);
    response.then((res) => {
      getList();
      console.log(res, "deleteServicedeleteServicedeleteService");
    });
  };
  const [pic, setPic] = useState([]);

  //modal trigger state
  const [picModal, setPicModal] = useState(false);
  const handleShow = () => setPicModal(true);
  const handleClose = () => setPicModal(false);
  //Remove img
  const handleImgRemove = (e, i) => {
    let newImg = pic.filter((e, newIndex) => {
      return newIndex !== i;
    });
    console.log(newImg, "newImg");
    setPic(newImg);
    // setAddImageSubmit(false);
  };

  //new category state

  const [newCategory, setNewCategory] = useState("");
  let initialValues = {
    serviceAllData,
    setServiceAllData,
    addService,
    setAddService,
    editService,
    setEditService,
    viewService,
    setViewService,
    mappedService,
    setMappedService,
    viewReadOnly,
    setViewReadOnly,
    handleServiceEdit,
    handleCreate,
    getList,
    getCategoryList,
    categoryData,
    setCategoryData,
    getServiceData,
    picModal,
    setPicModal,
    pic,
    setPic,
    handleShow,
    handleClose,
    handleImgRemove,
    addImageSubmit,
    setAddImageSubmit,
    getVehicleVariantList,
    vehicleVariantMain,
    setVehicleVariantMain,
    getServiceDelete,
    newCategory,
    setNewCategory,
    loading,
    setLoading,
    subLoading,
    setSubLoading,
    updateVehicleVariantData,
  };
  return (
    <ServiceContext.Provider value={initialValues}>
      {props.children}
    </ServiceContext.Provider>
  );
};
