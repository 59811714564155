import React, { Fragment, useContext } from "react";
import {
  Avatar,
  Box,
  Button,
} from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../layout/Loader";
import { CustomerContext } from "./customerContext/CustomerContext";
import styled from "styled-components";
import ReactTooltip from "react-tooltip-rc";
const CustomerMainBlock = () => {
  const { loading,customerData,getList,page,setPage,itemCount } = useContext(CustomerContext);
  const columns = [
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar src={params.row.profile_image} />
          <p className="ml-2 mb-0">{params.row.customer_name}</p>
        </Box>
      ),
      sortable: false,
      filterable: false,
    },
    { field: "email", headerName: "E-mail", flex: 1 },
    {
      field: "phone",
      headerName: "Mobile Number",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className="mb-0">{params.row.phone_extension}</p>
          <p className="mb-0 word-space">{params.row.phone_number}</p>
        </Box>
      ),
    },
    {
      field: "customer_address",
      headerName: "Address",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
        <ReactTooltip id="customer_address" place="top" type="info" effect="float" />
        <p className="ml-2 mb-0"
          style={{
            width: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          data-for="customer_address"
          title={params.row.customer_address}
        >{params.row.customer_address}</p></>
      ),
    },
  ];

  const handleViewClick = (e) => {
    // Handle view click
  };

  const handleAddCustomer = () => {
    // Handle add customer
  };

  const customRowsOverlay = () => (
    <GridOverlay>
      <p>No Data Available</p>
    </GridOverlay>
  );
const handlePageChange = (e)=>{
  getList(e+1,10);
  setPage(e + 1);
}
 
  return (
    <MainWrapper>
    <Fragment>
      <Box className="mainBlock">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box className="title-search">
              <Box className="box-left">
                <span className="main-header">Customer</span>
                <Button
                  variant="outlined"
                  sx={{ mb: "15px" }}
                  onClick={handleAddCustomer}
                >
                  Add New
                </Button>
              </Box>
            </Box>
            
              <Box
                className="data-table-sub-block"
                style={{ width: "100%", background: "#fff" }}
              >
                <DataGrid
                  components={{
                    NoRowsOverlay: customRowsOverlay,
                  }}
                  onRowClick={(e) => handleViewClick(e)}
                  rows={customerData}
                  columns={columns}
                  pageSize={10}
                  page={page - 1}
                  paginationMode="server"
                  rowCount={itemCount}
                  getRowId={(row) => row.customer_id}
                  onPageChange={handlePageChange}
                />
              </Box>
           
          </>
        )}
      </Box>
    </Fragment>
    </MainWrapper>
  );
};

export default CustomerMainBlock;

const MainWrapper = styled.div`

`;
