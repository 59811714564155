import {
  Avatar,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ServiceContext } from "./serviceContext/ServiceContext";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import serviceImageUpload from "../../../assets/img/photoupload/photos.svg";
import { v4 as uuidv4 } from "uuid";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import SatelliteOutlinedIcon from "@mui/icons-material/SatelliteOutlined";
import TimeToLeaveOutlinedIcon from "@mui/icons-material/TimeToLeaveOutlined";
import Stack from "@mui/material/Stack";
import ServiceImageModal from "../Ui/ServiceImageModal";
import { validate } from "./ui/validation";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import ServiceView from "./ServiceView";
// import validate from "./ui/validation";
function ServiceForm() {
  const {
    serviceAllData,
    setServiceAllData,
    addService,
    setAddService,
    editService,
    setEditService,
    viewService,
    setViewService,
    mappedService,
    setMappedService,
    handleServiceEdit,
    viewReadOnly,
    getList,
    handleCreate,
    getCategoryList,
    categoryData,
    setCategoryData,
    getServiceData,
    addImageSubmit,
    setAddImageSubmit,
    handleShow,
    handleImgRemove,
    pic,
    setPic,
    vehicleVariantMain,
    setVehicleVariantMain,
    getVehicleVariantList,
    newCategory,
    setNewCategory,
  } = useContext(ServiceContext);

  const styles = {
    formStyle: {
      padding: "15px",
      maxHeight: "calc(100vh - 215px)",
      overflow: "auto",
    },
    formRowStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: "15px",
    },
    sedanformRowStyle: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    iconLabelStyles: {
      display: "flex",
      gap: "20px",
    },
    // textField: {
    //   width: "75%",
    // },
    textField: {
      width: "100%",
    },
    VarianttextField: {
      width: "75%",
      display: "flex",
      alignItems: "center",
    },
    btnStyle: {
      color: "#FF8F00",
      fontSize: "12px",
      fontWeight: "500",
      outline: "none",
      backgroundColor: "#fff",
      border: "none",
      marginTop: "10px",
      width: "100%",
      maxWidth: "60%",
      margin: "0 auto",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    buttonsStyles: {
      display: "flex",
      // alignItems:'center',
      justifyContent: "center",
      gap: "20px",
    },
    addBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    cardStyles: {
      width: "150px",
      margin: 0,
      background: "#fafafa",
      border: "1px dotted #e0e0e0",
      // borderRadius: "3px!important",
      color: "#01579b",
      fontSize: "14px!important",
      height: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    typographyStyles: {
      backgroundColor: "#b3e5fc",
      mb: "10px",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: "0",
      borderRadius: "4px 4px 0 0",
      display: "inline-block",
      padding: "5px 15px",
      textTransform: "uppercase",
      fontWeight: 400,
    },
    spanStyles: {
      background: "#b3e5fc",
    },
    timeSetting: {
      width: "75%",
      display: "flex",
      gap: "20px",
      justifyContent: "space-between",
    },
    fieldError: {
      width: "75%",
    },
    newCategoryField: {
      width: "100%",
      maxWidth: "75%",
      margin: "0 auto",
      marginBottom: "12px",
    },
    errorHandling: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  };
  const [editServiceId, setEditServiceId] = useState();
  let initialState = {
    service_id: "",
    service_category_id: "",
    name: "",
    description: "",
    status: "",
    created_at: "",
    updated_at: "",
    // estimated_hours: "",
    // estimated_minutes: "",
  };
  // let initialVariant = [
  //   {
  //     price: "",
  //     vehicleVariant: {
  //       variant: "Sedan / Coupe",
  //       variant_id: 1,
  //     },
  //     vehicle_variant_id: 1,
  //     service_price_id: "",
  //     error: "",
  //   },
  //   {
  //     price: "",
  //     vehicleVariant: {
  //       variant: "4*4",
  //       variant_id: 2,
  //     },
  //     vehicle_variant_id: 2,
  //     service_price_id: "",
  //     error: "",
  //   },
  // ];
  let initialFormErrors = {
    service_name: "",
    description: "",
    category: "",
    variant: "",
  };

  const [formErrors, setFormErrors] = useState(initialFormErrors);

  // const [variant, setVariant] = useState(initialVariant);
  const [allData, setAllData] = useState(initialState);
  const [price, setPrice] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [category, setCategory] = useState([]);

  const [error, setError] = useState(false);

  let filterName;
  const handlerCategory = (value, attr) => {
    filterName = categoryData.filter((item) => {
      if (item.category_id == value) {
        return item;
      }
    });

    setAllData({ ...allData, service_category_id: value });

    setCategory({
      name: filterName[0].category,
      id: value,
    });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    console.log("create new button Clicked");
  };
  const [errorSpan, setErrorSpan] = useState(false);
  const handleAddCategory = (e) => {
    if (newCategory !== "") {
      let newCat = {
        category: newCategory,
        category_id: categoryData.length + 1,
      };
      setCategoryData([...categoryData, newCat]);
      setAllData({ ...allData, service_category_id: newCat.category_id });
      console.log(newCategory);
      setOpen(false);
    } else {
      setErrorSpan(true);
      e.stopPropagation();
    }
  };
  const handleClose = () => {
    setOpen(false);
    console.log("cancel");
  };
  const [hour, setHour] = useState(dayjs());
  const [minute, setMinute] = useState(dayjs());

  const handlePriceChange = (e, index) => {
    let newlist = vehicleVariantMain.map((item, newIndex) => {
      if (newIndex == index) {
        item.price = e;
      }
      return item;
    });
    setVehicleVariantMain(newlist);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  //toasters
  const updateToast = () => {
    return editService ? "Updated Successfully" : "Added Successfully";
  };
  // const onSuccess = () => {
  //   toast.success(updateToast, {
  //     position: "bottom-right",
  //     autoClose: 1000,
  //     toastId: "update",
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored",
  //   });
  // };

  //Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let newHour = hour.hour();
    let newMin = minute.minute();

    const errorData = {
      ...allData,
      estimated_hours: newHour,
      estimated_minutes: newMin,
    };

    let formData = new FormData();
    for (const e in allData) {
      if (Object.hasOwnProperty.call(allData, e)) {
        formData.append(e, allData[e]);
      }
    }
    formData.append("category_id", category.id);
    formData.append("new_category", newCategory);
    formData.append("estimated_hours", newHour);
    formData.append("estimated_minutes", newMin);
    formData.append("prices", JSON.stringify(vehicleVariantMain));

    // for (const picKey of pic.keys()) {
    //   if (pic.length > 0 && !pic[picKey].service_image_id) {
    //     formData.append(`images`, JSON.stringify([{ service_image_id: "" }]));
    //     formData.append(`images[${picKey}][image]`, pic[picKey].file);
    //   } else {
    //     if (editService) {
    //
    //       let imgArray = [];
    //       ;
    //       for (const index of mappedService.serviceImages.keys()) {
    //         let image_id =
    //           mappedService.serviceImages.length > 0
    //             ? mappedService.serviceImages[index].service_image_id
    //             : "";
    //         imgArray.push({ service_image_id: image_id });
    //       }
    //       formData.append(`images`, JSON.stringify(imgArray));
    //     }
    //   }
    // }
    let imgArray = [];
    for (const picKey of pic.keys()) {
      if (pic.length > 0 && !pic[picKey].service_image_id) {
        // formData.append(`images`, JSON.stringify([{ service_image_id: "" }]));
        formData.append(`images[${picKey}][image]`, pic[picKey].file);
      } else if (pic.length > 0 && pic[picKey].service_image_id) {
        if (editService) {
          let image_id = pic.length > 0 ? pic[picKey].service_image_id : "";
          imgArray.push({ service_image_id: image_id });
        }
      }
    }
    formData.append(`images`, JSON.stringify(imgArray));
    if (editService) {
      formData.append("service_id", mappedService.service_id);
    }

    var object = {};
    formData.forEach((value, key) => (object[key] = value));

    let validateResponse = validate(object, vehicleVariantMain, false);
    if (Object.keys(validateResponse.errors).length) {
      setError(true);
      setFormErrors(validateResponse.errors);
      setVehicleVariantMain(validateResponse.variants);
    } else {
      setError(false);

      //Create Service
      handleCreate(formData);
      // onSuccess()

      //Clear Form
      setViewService(false);
      setAddService(false);
      setCategory({});
      setAllData(initialState);
      setNewCategory("");
      setHour(dayjs());
      setMinute(dayjs());
      setPic([]);
    }
  };

  useEffect(() => {
    // var element = document.getElementById("service-form-box");
    // element.scrollIntoView({ behavior: "smooth", block: "start" });

    if (Object.keys(mappedService).length != 0) {
      let {
        servicePrices,
        category_name,
        estimated_hours,
        estimated_minutes,
        service_id,
        service_name,
        serviceImages,
        ...rest
      } = mappedService;
      rest.name = service_name;
      // setCategory(category);
      setCategory({
        name: category_name,
        id: mappedService.service_category_id,
      });
      setEditServiceId(service_id);
      setAllData(rest);
      setHour(dayjs().set("hour", parseInt(estimated_hours)));
      setMinute(dayjs().set("minute", parseInt(estimated_minutes)));
      setVehicleVariantMain(servicePrices);
      const images = serviceImages.map((e) => ({
        service_image_id: e.service_image_id,
        file: e.image_url,
      }));
      setPic(images);
    }

    setError(false);

    return () => {
      setAllData(initialState);
      setCategory({});
      setHour(dayjs());
      setMinute(dayjs());
      setPic([]);
      setFormErrors({});
      // setVehicleVariantMain([]);
      // getVehicleVariantList();
    };
  }, [viewService, mappedService]);

  // useEffect(() => {
  //   setAllData(initialState);
  //   setCategory({});
  //   setHour(dayjs());
  //   setMinute(dayjs());
  //   setPic([]);
  //   setFormErrors({});
  //   getVehicleVariantList();
  // }, [addService]);

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  //ON CHANGE VALIDATE
  const onChangeValidate = (field, value, variantIndex) => {
    console.log(field, "field", value, "value", "inside onchangevalidation ");

    const validateResponse = validate(
      allData,
      vehicleVariantMain,
      true,
      field,
      value,
      variantIndex
    );

    if (
      field == "variant" &&
      Object.keys(validateResponse.errors).includes("variant")
    ) {
      setVehicleVariantMain(validateResponse.variants);
    }

    if (Object.keys(validateResponse.errors).length != 0) {
      setError(true);

      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = {
          ...formErrors,
          ...validateResponse.errors,
        };
        setFormErrors(updatedErrorObject);
      } else {
        setFormErrors(validateResponse.errors);
      }
    } else if (Object.keys(formErrors).length != 0) {
      delete formErrors[field];
      const updatedErrorObject = { ...formErrors };
      setFormErrors(updatedErrorObject);
    } else {
      setError(false);
      // SetFormErrors(errorFields);
    }
  };

  //Service Name text only
  const handleKeyText = (e) => {
    var key = e.key;
    // var regex = /^[A-Za-z\s]*$/;
    var regex = /^[a-zA-Z\s,]+$/g;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  return (
    <Box
      sx={styles.formStyle}
      className={`form-block  ${viewReadOnly && "view-form-block"}`}
    >
      {viewReadOnly ? (
        <ServiceView />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <Grid  container spacing={2}>
              {/* servicename  */}
              <Grid
                item
                xs={6}
                md={6}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    inputProps={{
                      readOnly: Boolean(viewReadOnly),
                    }}
                    sx={styles.textField}
                    placeholder="Service Name"
                    label={
                      <span className="required">
                        Service Name {!viewReadOnly && <sup> *</sup>}
                      </span>
                    }
                    value={allData.name}
                    onKeyPress={handleKeyText}
                    onBlur={(e) =>
                      onChangeValidate("service_name", e.target.value)
                    }
                    onChange={(e) => {
                      setAllData({ ...allData, name: e.target.value });
                      onChangeValidate("service_name", e.target.value);
                    }}
                  />
                  {error && formErrors.service_name ? (
                    <span className="form-error text-danger">
                      {formErrors.service_name}
                    </span>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              {/* service category  */}
              <Grid
                item
                xs={6}
                md={6}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    <span className="required">
                      Service Category {!viewReadOnly && <sup> *</sup>}
                    </span>
                  </InputLabel>
                  <Select
                    // checkData={categoryData.find((x) => x.category_id === category.id)}
                    // inputProps={{
                    //   readOnly: Boolean(viewReadOnly),
                    //   disabled: Boolean(viewReadOnly),
                    // }}
                    className="formField"
                    value={allData.service_category_id}
                    // name="test"
                    onBlur={(e) => onChangeValidate("category", e.target.value)}
                    onChange={(e) => {
                      handlerCategory(e.target.value, e);
                      onChangeValidate("category", e.target.value);
                    }}
                  >
                    {categoryData &&
                      categoryData.map((e, i) => {
                        return (
                          <MenuItem value={e.category_id}>
                            {e.category}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {!viewReadOnly && (
                    <Button
                      style={styles.btnStyle}
                      onClick={(e) => handleOpen(e)}
                    >
                      + Create new Category
                    </Button>
                  )}
                  <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styles.modalStyles}>
                      <Typography
                        sx={styles.fontStyle}
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Add new Category
                      </Typography>
                      <form>
                        <Box style={styles.newCategoryField}>
                          <TextField
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                            sx={{ width: "100%" }}
                            id="outlined-basic"
                            // label="Outlined"
                            variant="outlined"
                          />
                          {errorSpan && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px !important" }}
                            >
                              Category Should'nt be empty
                            </span>
                          )}
                        </Box>
                        <Box sx={styles.buttonsStyles}>
                          <Button
                            variant="text"
                            sx={styles.cancelBtn}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={styles.addBtn}
                            variant="contained"
                            onClick={(e) => handleAddCategory(e)}
                            type="button"
                          >
                            Submit
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Modal>
                  {error && formErrors.category ? (
                    <span className="form-error text-danger">
                      {formErrors.category}
                    </span>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              {/* Service Description  */}
              <Grid
                item
                xs={6}
                md={6}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    inputProps={{
                      height: "50px",
                      readOnly: Boolean(viewReadOnly),

                    }}
                    sx={styles.textField}
                    placeholder="Service Description"
                    label={
                      <span className="required">
                        Service Description {!viewReadOnly && <sup> *</sup>}
                      </span>
                    }
                    
                    multiline
                    rows={4}
                    value={allData.description}
                    onBlur={(e) =>
                      onChangeValidate("description", e.target.value)
                    }
                    onChange={(e) => {
                      setAllData({ ...allData, description: e.target.value });
                      onChangeValidate("description", e.target.value);
                    }}
                  />
                  {error && formErrors.description ? (
                    <span className="form-error text-danger">
                      {formErrors.description}
                    </span>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>

              {/* Time Estimate*  */}
              <Grid
                className="mb-3"
                item
                xs={12}
                md={12}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Typography className="block-heading">
                  <span className="heading">Time Estimate</span>
                </Typography>
              </Grid>

              {/* time picker  */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <Stack spacing={3}> */}
                <Grid
                  item
                  xs={6}
                  md={6}
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <FormControl fullWidth sx={{ m: 1 }}>
                    {" "}
                    <TimePicker
                      // disabled={viewReadOnly}
                      className="formField"
                      ampmInClock
                      ampm={false}
                      openTo="hours"
                      views={["hours"]}
                      inputFormat="HH"
                      mask="__"
                      // label="Hours"
                      label={
                        <span className="required">
                          Hours {!viewReadOnly && <sup> *</sup>}
                        </span>
                      }
                      value={hour}
                      onChange={(newValue) => {
                        setHour(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <TimePicker
                      // disabled={viewReadOnly}
                      className="formField"
                      ampm={false}
                      ampmInClock
                      views={["minutes"]}
                      inputFormat="mm"
                      mask="__"
                      // label="Minute"
                      label={
                        <span className="required">
                          Minute {!viewReadOnly && <sup> *</sup>}
                        </span>
                      }
                      value={minute}
                      onChange={(newValue) => {
                        setMinute(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
                {/* </Stack> */}
              </LocalizationProvider>

              {/* timepicker close  */}
              {/* prices typography  */}
              <Grid
                className="mb-3"
                item
                xs={12}
                md={12}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Typography className="block-heading">
                  <span className="heading">Upload Images </span>
                </Typography>
              </Grid>

              {/* upload image  */}
              {viewReadOnly && pic.length == 0 ? (
                ""
              ) : (
                <>
                  {/* <Box sx={styles.iconLabelStyles}>
                <PhotoLibraryOutlinedIcon />
                <label>
                  {!viewReadOnly ? "Upload Service Images" : "Service Images"}
                </label>
              </Box> */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <div className=" row small-block ml-0 mr-0  ">
                        {addImageSubmit && pic
                          ? pic.map((photo, index) => {
                              return (
                                <div className="col-img-list">
                                  <div className="img-list" key={photo}>
                                    {!viewReadOnly && (
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={(e) =>
                                          handleImgRemove(e, index)
                                        }
                                      >
                                        <i class="fal fa-times"></i>
                                      </button>
                                    )}

                                    <img
                                      src={
                                        typeof photo.file === "object"
                                          ? URL.createObjectURL(photo.file)
                                          : // `http://localhost:8081/${photo.file}`
                                            `http://seahorseapi.codingo.co:8081/${photo.file}`
                                      }
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                        {!viewReadOnly && (
                          <>
                            <Card
                              sx={styles.cardStyles}
                              onClick={handleShow}
                              className="image-sizing position-relative"
                            >
                              <Box>
                                <Avatar
                                  sx={{ width: "65px" }}
                                  src={serviceImageUpload}
                                  alt="imageUpload"
                                />
                                <div className="text-center upload-txt">
                                  {" "}
                                  Upload
                                </div>
                              </Box>
                            </Card>
                            {/* <Box className="image-txt-absolute">
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Odit, iusto fugiat? Cupiditate, earum
                            consequatur? Aut, fugiat laboriosam optio modi
                            necessitatibus similique quidem doloremque iste
                            dicta vero minus itaque eligendi commodi.
                          </p>
                        </Box> */}
                          </>
                        )}
                      </div>
                    </FormControl>
                  </Grid>
                </>
              )}
              <ServiceImageModal />

              {/* prices typography  */}
              <Grid
                className="mb-3"
                item
                xs={12}
                md={12}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Typography className="block-heading">
                  <span className="heading">Prices (AED) </span>
                </Typography>
              </Grid>

              {/* sedan/ coupe  */}
              {}
              {Object.keys(vehicleVariantMain).length &&
                vehicleVariantMain.map((variant, index) => {
                  return (
                    <Grid
                      className="mb-3"
                      item
                      xs={6}
                      md={6}
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <TextField
                          // onKeyPress={handleKeyPress}
                          // inputProps={{
                          //   readOnly: Boolean(viewReadOnly),
                          // }}
                          autoComplete="off"
                          inputProps={{
                            readOnly: Boolean(viewReadOnly),
                          }}
                          placeholder={
                            variant.vehicleVariant &&
                            variant.vehicleVariant.variant
                          }
                          onKeyPress={handleKeyPress}
                          // label={
                          //   variant.vehicleVariant && variant.vehicleVariant.variant
                          // }
                          label={
                            <span className="required">
                              {variant.vehicleVariant &&
                                variant.vehicleVariant.variant}{" "}
                              {!viewReadOnly && <sup> *</sup>}
                            </span>
                          }
                          value={variant.price}
                          onBlur={(e) =>
                            onChangeValidate("variant", e.target.value, index)
                          }
                          onChange={(e) => {
                            handlePriceChange(e.target.value, index);
                            onChangeValidate("variant", e.target.value, index);
                          }}
                        />
                        {error && variant.errors ? (
                          <span className="form-error text-danger">
                            {variant.errors}
                          </span>
                        ) : (
                          ""
                        )}
                        {/* <Typography sx={{ ml: "30px" }}>AED</Typography> */}
                      </FormControl>
                    </Grid>
                  );
                })}
            </Grid>

            {!viewReadOnly && (
              <Box sx={{ textAlign: "right", mt: "5px" }}>
                <BootstrapTooltip title="Save services">
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                </BootstrapTooltip>
              </Box>
            )}
          </form>
        </>
      )}
    </Box>
  );
}

export default ServiceForm;
