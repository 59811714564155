import React, { useState, useContext } from "react";
import { Button, Modal, Nav, Container, Row, Col } from "react-bootstrap";
import uploadFileId from "../Ui/image/upload/add-file.svg";
import { StaffContext } from "../staff/staffContext/StaffContext";

const StaffIdModal = () => {
  const {
    idModal,
    setIdModal,
    idproof,
    setIdProof,
    setProofSubmit,
    handleCloseIdModal,
    handleIdproofRemove,
    setMappedIdProof,
  } = useContext(StaffContext);

  // //closemodal
  const closeIdUpload = () => {
    setIdModal(false);
  };
  const getExtension = (file) => {
    return file?.split(".").pop();
  };
  //Onchange function
  const uploadId = (e) => {
    console.log(e.target.files, "what is e");
    let file = e.target.files;
    let fileObj = Object.values(file);
    let newList =
      fileObj &&
      fileObj.map((img) => ({
        file: img,
        staff_proof_id: "",
      }));
    // setPic(newList);
    setIdProof((prev) => [...prev, ...newList]);
    // setIdProof([
    //   {
    //     file,
    //   },
    // ]);
    setProofSubmit(false);
  };
  // console.log(idproof, "...........choose .......idproofidproofidproof");
  // console.log(idproof, "idproofidproofidproof");
  //Close modal
  const closeFileUpload = () => {
    setIdModal(false);
    setIdProof([]);
  };
  //image confirm
  const ImageSubmit = (e) => {
    e.preventDefault();
    setIdProof(idproof);
    setIdModal(false);
    setProofSubmit(true);
    setMappedIdProof();
  };
  console.log(idproof);
  return (
    <Modal
      className="file-modal"
      show={idModal}
      onHide={handleCloseIdModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={3} className="p-0">
              <Row className="guidelines-info w-100">
                <Col md={12}>
                  <Col md={12}>
                    <h5>Dimensions: </h5>
                    <p>1024 x 768px</p>
                  </Col>
                  <Col md={12}>
                    <h5>Format:</h5>
                    <p>PDF or JPG</p>
                  </Col>
                  <Col md={12}>
                    <h5>File Size:</h5>
                    <p>Below 10 MB</p>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className="upload-block">
              <Modal.Header></Modal.Header>
              <h5 className="title">Upload Id</h5>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={uploadId}
                    type="file"
                    accept="image/jpg, image/jpeg, image/png, image/webp, .pdf, .doc, .docx"
                    className="custom-file-input form-control-sm"
                    id="imageUpload"
                    multiple
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    for="imageUpload"
                  >
                    <div className="text-center">
                      <div className="upload-section">
                        <div className="upload-img">
                          <img
                            src={uploadFileId}
                            className="pdf-file"
                            alt="loading..."
                          />
                        </div>
                        <div className="upload-text">
                          <h5>
                            <span>Click to upload</span> or drag and drop
                          </h5>
                          <h6>Maximum file size 10 MB.</h6>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="mb-2 text-center">
                {idproof && idproof.length > 4 ? (
                  <p className="text-danger">
                    Maximum 4 Images can be uploaded
                  </p>
                ) : (
                  ""
                )}
              </div>
              <Row className="row-img">
                {idproof &&
                  idproof.map((proof, index) => {
                    // console.log(idproof);
                    return (
                      <div className="col-md-4 col-img-list">
                        <div className="img-list" key={proof}>
                          <button
                            className="btn "
                            onClick={(e) => handleIdproofRemove(e, index)}
                          >
                            <i class="fal fa-times text-danger"></i>
                          </button>
                          <div>
                            {getExtension(proof?.file?.name).toLowerCase() ===
                            "pdf" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-pdf"></i>
                                </span>
                                <h5>{proof.file.name}</h5>
                              </div>
                            ) : getExtension(
                                proof?.file?.name
                              ).toLowerCase() === "docx" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-word word"></i>
                                </span>
                                <h5>{proof.file.name}</h5>
                              </div>
                            ) : getExtension(
                                proof?.file?.name
                              ).toLowerCase() === "doc" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-word word"></i>
                                </span>
                                <h5>{proof.file.name}</h5>
                              </div>
                            ) : (
                              <img
                                src={URL.createObjectURL(proof.file)}
                                className="img-fluid"
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Row>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeIdUpload}>
                  Close
                </Button>

                <Button
                  variant="primary"
                  disabled={idproof && idproof.length ? false : true}
                  onClick={ImageSubmit}
                >
                  Confirm
                  <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                </Button>
                {/* ) : (
                      <Button variant="primary" disabled onClick={ImageSubmit}>
                        Confirm
                        <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                      </Button>
                    )} */}
              </Modal.Footer>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default StaffIdModal;
