import React, { useState, useContext } from "react";
import { Button, Modal, Nav, Container, Row, Col } from "react-bootstrap";
import uploadFileId from "../Ui/image/upload/add-file.svg";
import { StaffContext } from "../staff/staffContext/StaffContext";

const StaffImageModal = () => {
  const {
    pic,
    setPic,
    setPicModal,
    addonImages,
    setAddonImages,
    addImageSubmit,
    setAddImageSubmit,
    picModal,
    handleClose,
    handleShow,
    handleImgRemove,
    mappedPic,
    setMappedPic,
  } = useContext(StaffContext);
  //Check extension
  const getExtension = (file) => {
    return file.split(".").pop();
  };
  //Onchange function
  const uploadImage = (e) => {
    console.log(e.target.files, "what is e");
    let file = e.target.files[0];
    let fileName = file.name;
    let fileType = file.type;
    let src = URL.createObjectURL(file);

    // let ext = getExtension(fileName);
    console.log(file,"file ",fileName,"fileName",fileType,"fileType");
    setPic([file]);
  };
  // console.log(pic, "imgimgimgimg");
  //Close modal
  const closeFileUpload = () => {
    setPicModal(false);
    setPic([]);
  };
  //image confirm
  const ImageSubmit = (e) => {
    e.preventDefault();
    setPic(pic);
    setPicModal(false);
    setAddImageSubmit(true);
    setMappedPic();
    
  };
  // console.log(pic, "img picccccccccccccccccccccccccc");

  return (
    <Modal
      className="file-modal"
      show={picModal}
      onHide={handleClose}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={3} className="p-0">
              <Row className="guidelines-info w-100">
                <Col md={12}>
                  <Col md={12}>
                    <h5>Dimensions: </h5>
                    <p>1024 x 768px</p>
                  </Col>
                  <Col md={12}>
                    <h5>Format:</h5>
                    <p>JPG or PNG</p>
                  </Col>
                  <Col md={12}>
                    <h5>File Size:</h5>
                    <p>Below 10 MB</p>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className="upload-block">
              <Modal.Header></Modal.Header>
              <h5 className="title">Upload Images</h5>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={uploadImage}
                    type="file"
                    accept="image/jpeg, image/png, image/webp"
                    className="custom-file-input form-control-sm"
                    id="imageUpload"
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    for="imageUpload"
                  >
                    <div className="text-center">
                      <div className="upload-section">
                        <div className="upload-img">
                          <img src={uploadFileId} alt="loading..." />
                        </div>
                        <div className="upload-text">
                          <h5>
                            <span>Click to upload</span> or drag and drop
                          </h5>
                          <h6>Maximum file size 10 MB.</h6>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <Row className="row-img">
                <div className="mb-2 text-center">
                  {pic && Object.keys(pic).length && Object.keys(pic).length > 1 ? (
                    <p className="text-danger">
                      Only one image Can be Uploaded
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                {pic
                  ? pic.map((photo, index) => {
                      // console.log(pic);
                      return (
                        <div className="col-md-4 col-img-list">
                          {/* <p>{photo.file_name}</p> */}
                          <div className="img-list" key={photo}>
                            <button
                              className="btn"
                              onClick={(e) => handleImgRemove(e, index)}
                            >
                              <i class="fal fa-times"></i>
                            </button>
                            <img
                              src={URL.createObjectURL(photo)}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}

                {/* {img?.sourceFile?.length &&
                    <div className="col-md-4 col-img-list">
                      <div className="img-list" key={img.length}>
                        <button
                          className="btn"
                          onClick={() =>
                            setImg(img.Filter((e) => e.sourceFile !== img.sourceFile))
                          }
                        >
                          <i class="fal fa-times"></i>
                        </button>
                        <img
                          src={img.sourceFile ? img.sourceFile :""}
                          className="img-fluid"
                          alt="logo"
                        />
                      </div>
                    </div>} */}
              </Row>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeFileUpload}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  disabled={pic && Object.keys(pic).length ? false : true}
                  onClick={ImageSubmit}
                >
                  Confirm
                  <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                </Button>
              </Modal.Footer>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default StaffImageModal;
