import { Grid } from "@mui/material";
import React from "react";
import { BookingHistoryProvider } from "./bookinghistoryContext/BookingHistoryContext";
import BookingHistoryMainBlock from "./BookingHistoryMainBlock";

function Subadmin() {
  const drawerWidth = 240;
  const topWidth = 70;
  // const {
  //   seriviceAllData, setServiceAllData
  //  } = useContext(ServiceContext);

  return (
    <Grid
      // sx={{
      //   width: `calc(100% - ${drawerWidth}px)`,
      //   ml: `${drawerWidth}px`,
      //   mt: `${topWidth}px`,
      //   textAlign: "left",
      //   padding: "0 25px 25px 25px",
      //   background: "#D9D9D994",
      //   height: "calc(100vh - 70px)",
      //   overflow: "auto",
      // }}
      className="grid"
    >
      <BookingHistoryProvider>
        <BookingHistoryMainBlock />
      </BookingHistoryProvider>
    </Grid>
  );
}

export default Subadmin;
