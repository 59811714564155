import React, { useContext, useState } from "react";
import { SubAdminContext } from "../subadminContext/SubAdminContext";
// import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
import credimg from "../../../../assets/img/upload/2.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import {
  Avatar, Box, Button, Modal, Typography,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import { CredentialValidation } from "./CredentialValidation";
import {
  updateSubAdminCredential
} from "../../../../api/console/SubAdmin/sub-admin-management";

const CredentialModal = () => {
  const { credOpen, handleCloseCredModal,newSubCredential, setNewSubCredential,crederror, SetCredError,
    credFormErrors, setCredFormErrors,mappedSubAdmin, initialCredErrors, newCredential } =
    useContext(SubAdminContext);
    const [showPassword, setShowPassword] = useState(false);
    const [showNPassword, setShowNPassword] = useState(false);
    const [showCNPassword, setShowCNPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleClickShowNPassword = () => setShowNPassword(!showNPassword);
    const handleMouseDownNPassword = () => setShowNPassword(!showNPassword);
    const handleClickShowCNPassword = () => setShowCNPassword(!showCNPassword);
    const handleMouseDownCNPassword = () => setShowCNPassword(!showCNPassword);

    //Cancel cred fn
    const handleCloseCred = () =>{
      handleCloseCredModal();
      setNewSubCredential(newCredential);
      setCredFormErrors(initialCredErrors)
      SetCredError(false);

    }
    
      //On Change Validate fn
  const onChangeValidate = (field, value) => {
    // console.log(field, "field", value, "value", "inside onchangevalidation ");
    const errors = CredentialValidation(newSubCredential, field, value, true,);
    if (Object.keys(errors).length !== 0) {
      SetCredError(true);
      if (Object.keys(credFormErrors).length !== 0) {
        const updatedErrorObject = { ...credFormErrors, ...errors };
        setCredFormErrors(updatedErrorObject);
      } else {
        setCredFormErrors(errors);
      }
    } else {
      if (Object.keys(credFormErrors).length !== 0) {
        delete credFormErrors[field];
        const updatedErrorObject = { ...credFormErrors };
        setCredFormErrors(updatedErrorObject);
      } else {
        SetCredError(false);
        // SetFormErrors(errorFields);
      }
    }
  };

    const handlenewCredSubmit = (e) =>{
      e.preventDefault()
      let subId = mappedSubAdmin?.subadmin_id
      const data = {
        subadmin_id:subId,
        ...newSubCredential
      };
      // console.log(subId,"............................subId ");
      // console.log(data,"............................form data");
      // console.log(mappedSubAdmin?.subadmin_id,"............................mappedSubAdmin?.subadmin_id");
      let errors = CredentialValidation(data, null, null, false);
      if (Object.keys(errors).length) {
        SetCredError(true);
        setCredFormErrors(errors);
        return;
      } else {
        SetCredError(false);
        const response = updateSubAdminCredential(data);
        response
          .then((res) => {
            // console.log(res, "..........cred res");
            handleCloseCredModal()
            toast.success("Credentials updated successfully", {
              position: "bottom-right",
              autoClose: 1000,
              toastId: "error",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setNewSubCredential(newCredential);
            setCredFormErrors(initialCredErrors)
            SetCredError(false);
            // SetAddSubAdmin(false);
            // setViewSubAdmin(false);
            // setEditSubAdmin(false);
            // const listData = getSubAdminList();
            // listData.then((res) => {
            //   console.log(res, " getSubAdminList ....................res");
            //   setSubAdminList(res);
            //   if (res) {
            //     onSuccess();
            //   }
            // });
          })
          .catch((err) => {
            // console.log(err,".......................err");
            // if (err) {
              // console.log("err.message", err.message);
              SetCredError(true);
              setCredFormErrors({
                  current_password:"Current password is incorrect"
                })
              toast.error(err.message, {
                position: "bottom-right",
                autoClose: 1000,
                toastId: "error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            // }
          });
      }




    }

  const style = {
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    iconStyle: {
      margin: " 0 auto",
      height: "100px",
      width: "100px",
    },
    buttonsStyles: {
      display: "flex",
      alignItems:'center',
      justifyContent: "center",
      gap: "20px",
    },
    deleteBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
  };
  return (
    <Modal open={credOpen}>
      <Box sx={style.modalStyles}>
        <Avatar src={credimg} sx={style.iconStyle} />
        <Typography
          sx={style.fontStyle}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Update Credentials
        </Typography>
        <form onSubmit={handlenewCredSubmit}>
        <Grid  container spacing={2}>
          <Grid item xs={6} md={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                placeholder="Current Password"
                label={<span className="required">Current Password<sup> *</sup></span>}
                type={showPassword ? "text" : "password"}
                inputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputProps={{
                  // readOnly: Boolean(viewReadOnly) ? true : false,
                  // disabled: Boolean(viewReadOnly) ? true : false,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={newSubCredential?.current_password}
                onChange={(e) => {
                  setNewSubCredential({
                    ...newSubCredential,
                    current_password: e.target.value,
                  });
                  onChangeValidate("current_password", e.target.value ? e.target.value : "");
                }}
                onBlur={(e) => {
                  onChangeValidate("current_password", e.target.value ? e.target.value : "");
                }}
              />
              {crederror && credFormErrors.current_password ? (
                <span className="form-error text-danger">
                  {credFormErrors.current_password}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} md={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                placeholder="New Password"
                label={<span className="required">New password<sup> *</sup></span>}
                type={showNPassword ? "text" : "password"}
                inputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputProps={{
                  // readOnly: Boolean(viewReadOnly) ? true : false,
                  // disabled: Boolean(viewReadOnly) ? true : false,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNPassword}
                        onMouseDown={handleMouseDownNPassword} 
                      >
                        {showNPassword ? <Visibility /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={newSubCredential?.new_password}
                onChange={(e) => {
                  setNewSubCredential({
                    ...newSubCredential,
                    new_password: e.target.value,
                  });
                  onChangeValidate("new_password", e.target.value ? e.target.value : "");
                }}
                onBlur={(e) => {
                  onChangeValidate("new_password", e.target.value ? e.target.value : "");
                }}
              />
              {crederror && credFormErrors.new_password ? (
                <span className="form-error text-danger">
                  {credFormErrors.new_password}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} md={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                placeholder="Confirm new Password"
                label={<span className="required">Confirm new  Password<sup> *</sup></span>}
                type={showCNPassword ? "text" : "password"}
                inputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputProps={{
                  // readOnly: Boolean(viewReadOnly) ? true : false,
                  // disabled: Boolean(viewReadOnly) ? true : false,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCNPassword}
                        onMouseDown={handleMouseDownCNPassword} 
                        
                      >
                        {showCNPassword ? <Visibility /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={newSubCredential?.cnew_password}
                onChange={(e) => {
                  setNewSubCredential({
                    ...newSubCredential,
                    cnew_password: e.target.value,
                  });
                  onChangeValidate("cnew_password", e.target.value ? e.target.value : "");
                }}
                onBlur={(e) => {
                  onChangeValidate("cnew_password", e.target.value ? e.target.value : "");
                }}
              />
              {crederror && credFormErrors.cnew_password ? (
                <span className="form-error text-danger">
                  {credFormErrors.cnew_password}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} md={12} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Box sx={style.buttonsStyles}>
              <Button variant="outlined" type="nutton" sx={style.cancelBtn} onClick={handleCloseCred}>Close</Button>
              <Button type="submit" variant="contained">Save
               {/* <SaveOutlinedIcon fontSize="small" /> */}
               </Button>
            </Box>
          </Grid>
          
        </Grid>
        </form>

       
      </Box>
    </Modal>
  );
};

export default CredentialModal;
