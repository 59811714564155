import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "./ui/css/common.css";
import "../../../assets/css/bootstrap.min.css";
import { Grid } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
const Layout = (props) => {
  const [title, setTitle] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const parsedTitle = location.pathname.split("/")[2];
    console.log(location.pathname);
    setTitle(parsedTitle);
  }, [location]);
  return (
    <section id="main" className="main">
      <div className="container-fluid p-0">
        <div className="row m-0">
          <Grid container>
            <Header />
            <Sidebar />
            <Outlet />
          </Grid>
        </div>
      </div>
    </section>
  );
};
export default Layout;
