import { useEffect, useState } from "react";
import { createContext } from "react";
import { getBookingsList } from "../../../../api/console/Booking/booking-management";
import dateFormat from "dateformat";
import dayjs from "dayjs";

export const BookingContext = createContext();
export const BookingProvider = (props) => {
  let initialState = {
    vehicle_id: "",
    comments: "",
    customer_name: "",
    phone_number: "",
    vehicle_registration_number: "",
    phone_extension: "971",
    services: [],
    service_id: "",
    vehicle_manufacturer: "",
    Manufactured_Year: "",
    Vehicle_Variants: "",
    color: "",
    actual_amount: "",
    Additional_Discounts: "",
    Additional_Charges: "",
    mobile_unit_id: "",
    service_time: "",
  };

  const [userCheck, setUserCheck] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [allData, setAllData] = useState(initialState);
  const [bookingAllData, setBookingAllData] = useState([]);
  const [addBooking, SetAddBooking] = useState(false);
  const [viewBooking, setViewBooking] = useState(false);
  const [editBooking, SetEditBooking] = useState(false);
  const [mappedBooking, SetMapppedBooking] = useState({});
  const [viewReadOnly, setViewReadOnly] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [bookDate, setBookDate] = useState([
    dateFormat(new Date(), "yyyy-mm-dd HH:MM"),
    dateFormat(new Date(), "yyyy-mm-dd HH:MM"),
  ]);

  const [vechileNumber, setVechileNumber] = useState({
    vehicle_registration_number: "",
  });

  // const handleBookingEdit = (newData, id) => {
  //   let editData = bookingAllData.map((item) => {
  //     if (item.service_id === id) {
  //       return newData
  //     }
  //     return item;
  //   });
  //   setBookingAllData(editData)
  //   console.log(editData, 'editdata')
  // }

    
  //Check Usertype 
  useEffect(() => {
    let userName = localStorage.getItem("username");
    let userId = localStorage.getItem("userid");
    let userType = localStorage.getItem("userType");
    setUserCheck({
      username: userName,
      userid: userId,
      usertype: userType,
    });
  }, []);



  useEffect(() => {
    getBooking();
  }, []);

  const filterBookingshandler = (filterData) => {
    setFilterValue(
      bookingAllData &&
        bookingAllData.filter((item) =>
          dateFormat(item.service_date, "yyyy-mmd-dd").includes(
            dateFormat(filterData, "yyyy-mmd-dd")
          )
        )
    );
  };









  const getBooking = () => {
    const response = getBookingsList();
    setLoading(true);
    response.then((res) => {
      setBookingAllData(res);
      setLoading(false);
    });
  };
  const [serviceStartTime, setServiceStartTime] = useState(dayjs(new Date()));
  const [serviceEndTime, setServiceEndTime] = useState(dayjs(new Date()));
  let intialValues = {
    bookingAllData,
    setBookingAllData,
    addBooking,
    SetAddBooking,
    viewBooking,
    setViewBooking,
    editBooking,
    SetEditBooking,
    mappedBooking,
    SetMapppedBooking,
    viewReadOnly,
    setViewReadOnly,
    // handleBookingEdit,
    allData,
    setAllData,
    initialState,
    getBooking,
    filterValue,
    setFilterValue,
    bookDate,
    setBookDate,
    filterBookingshandler,
    vechileNumber,
    setVechileNumber,
    loading,
    setLoading,
    subLoading,
    setSubLoading,
    serviceStartTime,
    setServiceStartTime,
    serviceEndTime,
    setServiceEndTime,
    userCheck, setUserCheck
  };
  return (
    <BookingContext.Provider value={intialValues}>
      {props.children}
    </BookingContext.Provider>
  );
};
