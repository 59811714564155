import {
  Button,
  Checkbox,
  Grid,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import React, { useContext } from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useState } from "react";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MobileUnitContext } from "./context/MobileUnitContext";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import SyncAltSharpIcon from "@mui/icons-material/SyncAltSharp";
import { toast } from "react-toastify";
import { validate } from "./ui/validation";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ElectricCarOutlinedIcon from "@mui/icons-material/ElectricCarOutlined";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const MobileUnitForm = (props) => {
  //Models

  let staffModel = {
    staff_id: "",
    staff_name: "",
  };

  let initialFormErrors = {
    emirates: "",
    muName: "",
    contact_number: "",
    vehicle_number: "",
    phone: "",
    services: "",
    staff: "",
    workingDays: "",
  };

  let initialState = {
    mobile_unit_id: "",
    name: "",
    phone_extension: "971",
    vehicle_number: "",
    contact_number: "",
    status: "",
    created_at: "",
    updated_at: "",
    emirates_id: "",
    emirate_name: "",
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const WorkingDays = [
    {
      mu_working_day_id: null,
      day: "Monday",
      is_working: true,
      muWorkingHours: [
        {
          mu_working_hour_id: null,
          start_time: "09:00:00",
          end_time: "17:00:00",
          errors: "",
        },
      ],
    },
    {
      mu_working_day_id: null,
      day: "Tuesday",
      is_working: true,
      muWorkingHours: [
        {
          mu_working_hour_id: null,
          start_time: "09:00:00",
          end_time: "17:00:00",
          errors: "",
        },
      ],
    },
    {
      mu_working_day_id: null,
      day: "Wednesday",
      is_working: true,
      muWorkingHours: [
        {
          mu_working_hour_id: null,
          start_time: "09:00:00",
          end_time: "17:00:00",
          errors: "",
        },
      ],
    },
    {
      mu_working_day_id: null,
      day: "Thursday",
      is_working: true,
      muWorkingHours: [
        {
          mu_working_hour_id: null,
          start_time: "09:00:00",
          end_time: "17:00:00",
          errors: "",
        },
      ],
    },
    {
      mu_working_day_id: null,
      day: "Friday",
      is_working: true,
      muWorkingHours: [
        {
          mu_working_hour_id: null,
          start_time: "09:00:00",
          end_time: "17:00:00",
          errors: "",
        },
      ],
    },
    {
      mu_working_day_id: null,
      day: "Saturday",
      is_working: true,
      muWorkingHours: [
        {
          mu_working_hour_id: null,
          start_time: "09:00:00",
          end_time: "17:00:00",
          errors: "",
        },
      ],
    },
    {
      mu_working_day_id: null,
      day: "Sunday",
      is_working: true,
      muWorkingHours: [
        {
          mu_working_hour_id: null,
          start_time: "09:00:00",
          end_time: "17:00:00",
          errors: "",
        },
      ],
    },
  ];

  //Context
  const {
    muDatas,
    setMUDatas,
    mappedMobileUnitData,
    setMappedMobileUnitData,
    setAddMobileUnit,
    addMobileUnit,
    viewMobileUnit,
    setViewMobileUnit,
    viewReadOnly,
    setViewReadOnly,
    editMobileUnit,
    setEditMobileUnit,
    handleMuEdit,
    handleMobileUnitCreate,
    emirateList,
    serviceOptions,
    staffOptions,
  } = useContext(MobileUnitContext);

  //Styles
  const styles = {
    formStyle: {
      padding: "15px",
    },
    formRowStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: "15px",
    },
    phoneExtension: {
      width: "100%",
    },
    iconLabelStyles: {
      display: "flex",
      gap: "20px",
    },
    textField: {
      width: "100%",
    },

    fieldError: {
      width: "75%",
    },
    headingStyle: {
      color: "#b3e5fc",
    },
    checkedBlock: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
      alignItems: "center",
      mt: 2,
    },
  };

  //UseStates
  const [editMobileUnitId, setEditMobileUnitId] = useState();
  const [muWorkingDays, setMuWorkingDays] = useState(WorkingDays);
  const [allData, setAllData] = useState(initialState);
  const [salary, setSalary] = useState("");
  const [gender, setGender] = useState();
  const [resultServices, setResultServices] = useState([]);
  const [is_workingtest, setIsWorkingTest] = useState(false);
  const [startTimeValue, setStartTimeValue] = useState(false);
  const [endTimeValue, setEndTimeValue] = useState(false);
  const [day, setDay] = useState(false);
  const [muServices, setMuServices] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [staff, setStaff] = useState([staffModel]);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  useEffect(() => {
    if (addMobileUnit) {
      setAllData(initialState);
      setMuServices([]);
      setStaff([]);
    }
    if (Object.keys(mappedMobileUnitData).length != 0) {
      let {
        salary,
        muStaffs,
        muServices,
        muWorkingDays,
        emirate_name,
        emirate_id,
        ...rest
      } = mappedMobileUnitData;
      //  debugger
      // let updateDaysWithISOTime = timeHandler(muWorkingDays);
      setEditMobileUnitId();
      setAllData({
        ...rest,
        emirate: {
          emirate_id: emirate_id,
          emirate_name: emirate_name,
        },
      });
      setSalary(salary);
      setMuServices(muServices);
      setStaff(muStaffs);
      setMuWorkingDays(muWorkingDays);
    } else {
      setMuWorkingDays(WorkingDays);
    }
  }, [mappedMobileUnitData]);

  const updateToast = () => {
    return editMobileUnit ? "Updated Successfully" : "Added Successfully";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const onSuccess = () => {
      toast.success(updateToast, {
        position: "bottom-right",
      });
    };
    const onFailure = () => {
      toast.error("Request Failed", {
        position: "bottom-right",
      });
    };

    let service_ids = [];
    muServices.forEach((service) => {
      service_ids.push(service.service_id);
    });

    let staff_ids = [];
    staff.forEach((staff) => {
      staff_ids.push(staff.staff_id);
    });

    let work_days_list = updateTime();

    let phone = allData.contact_number;
    if (phone.length > 10 && phone.slice(0, 4) == "+971") {
      phone = phone.slice(4, phone.length);
    }
    let formData = {
      ...allData,
      services: service_ids,
      work_days: work_days_list,
      staffs: staff_ids,
      contact_number: phone,
    };

    console.log(formData);

    if (!editMobileUnit) {
      formData = {
        ...formData,
        password: "password",
        username: allData.vehicle_number,
      };
    }

    let errors = validate(formData, muWorkingDays, false);
    if (Object.keys(errors.errors).length) {
      setError(true);
      setFormErrors(errors.errors);
      if (errors.errors.workingDays) {
        setMuWorkingDays(errors.workingDays);
      }
      return;
    } else {
      setError(false);
      handleMobileUnitCreate(formData, { onSuccess, onFailure });
    }

    setAllData(initialState);
    setSalary("");
    setMuServices([]);
    setAddMobileUnit(false);
    setViewMobileUnit(false);
    setMappedMobileUnitData({});
  };

  const updateTime = () => {
    console.log(muWorkingDays);
    //to be optimised
    let newWorkDays = muWorkingDays.map((day) => ({
      mu_working_day_id: day.mu_working_day_id,
      day: day.day,
      is_working: day.is_working,
      work_hours: day.muWorkingHours.map((hour) => ({
        mu_working_hour_id: hour.mu_working_hour_id,
        start_time: hour.start_time,
        end_time: hour.end_time,
      })),
    }));
    return newWorkDays;
  };

  // const timeHandler = (data) => {
  //   let muWorkingDays = data;
  //   muWorkingDays.forEach((days) => {
  //     days.muWorkingHours.forEach((element) => {
  //       // let start = new Date();
  //       // start.setHours(parseInt(element.start_time.slice(0, 2)));
  //       // start.setMinutes(parseInt(element.start_time.slice(3, 5)));
  //       // start.setSeconds(parseInt(element.start_time.slice(6, 8)));

  //       // console.log(new Date(element.start_time), 'start_timeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
  //       // console.log(start, 'startttttttttttttttttttttttttttttttttttttttttttttttttt');
  //       console.log(new Date().toLocaleDateString("fr-CA"));
  //       console.log(new Date(new Date().toLocaleDateString("fr-CA") + ' ' + element.start_time).toISOString(), 'start_timeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');

  //       // let end = new Date();

  //       // if (element.end_time) {
  //       //   end.setHours(parseInt(element.end_time.slice(0, 2)));
  //       //   end.setMinutes(parseInt(element.end_time.slice(3, 5)));
  //       //   end.setSeconds(parseInt(element.end_time.slice(6, 8)));
  //       // }

  //       element.start_time = new Date(new Date().toLocaleDateString("fr-CA") + ' ' + element.start_time).toISOString();
  //       // console.log(element.start_time, 'start_time----');
  //       if (element.end_time) element.end_time = new Date(new Date().toLocaleDateString("fr-CA") + ' ' + element.end_time).toISOString();
  //     });
  //   });
  //   return muWorkingDays;
  // };
  const handleStartTime = (starttime, dayIndex, hourIndex) => {
    muWorkingDays[dayIndex].muWorkingHours.map((e, index) => {
      if (index == hourIndex) {
        console.log(new Date(starttime).toLocaleTimeString("it-IT"));

        e.start_time = new Date(starttime).toLocaleTimeString("it-IT");
      }
    });
    if (startTimeValue) {
      setStartTimeValue(false);
    } else {
      setStartTimeValue(true);
    }
  };

  const handleEndTime = (endtime, dayIndex, hourIndex) => {
    muWorkingDays[dayIndex].muWorkingHours.map((e, index) => {
      if (index == hourIndex)
        e.end_time = new Date(endtime).toLocaleTimeString("it-IT");
    });
    if (endTimeValue) {
      setEndTimeValue(false);
    } else {
      setEndTimeValue(true);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const isAllSelected =
    serviceOptions.length > 0 && muServices.length === serviceOptions.length;

  const isAllStaffSelected =
    staffOptions.length > 0 && staff.length === staffOptions.length;

  const handleChange = (event) => {
    let value = event.target.value;
    let existingIds = [];
    let resultent = [];
    if (value.length > 0) {
      value.forEach((opt) => {
        if (existingIds.indexOf(opt.service_id) !== -1) {
          existingIds = existingIds.filter(
            (optval) => optval != opt.service_id
          );
          resultent = resultent.filter(
            (option) => option.service_id != opt.service_id
          );
        } else {
          existingIds.push(opt.service_id);
          resultent.push(opt);
        }
      });
    }
    value = resultent;

    if (value[value.length - 1] === "all") {
      setMuServices(
        muServices.length === serviceOptions.length ? [] : serviceOptions
      );
      return;
    }
    onChangeValidation("services", value);

    setMuServices(value);
  };

  const handleStaffChange = (event) => {
    let value = event.target.value;
    let existingIds = [];
    let resultent = [];
    if (value.length > 0) {
      value.forEach((opt) => {
        if (existingIds.indexOf(opt.staff_id) !== -1) {
          existingIds = existingIds.filter((optval) => optval != opt.staff_id);
          resultent = resultent.filter(
            (option) => option.staff_id != opt.staff_id
          );
        } else {
          existingIds.push(opt.staff_id);
          resultent.push(opt);
        }
      });
    }
    value = resultent;

    if (value[value.length - 1] === "all") {
      setStaff(staff.length === staffOptions.length ? [] : staffOptions);
      return;
    }
    onChangeValidation("staff", value);
    setStaff(value);
  };

  //Checked Toggle
  const handleToggle = (isActive, index) => {
    muWorkingDays[index].is_working = isActive;
    if (is_workingtest) {
      setIsWorkingTest(false);
    } else {
      setIsWorkingTest(true);
    }
  };

  let len = parseInt(allData?.contact_number?.length);

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
    if (len > 8) {
      e.preventDefault();
    }
  };

  const [handleAdd, setHandleAdd] = useState(false);
  const handleAddMore = (e, index) => {
    setHandleAdd(true);
    let newHour = {
      mu_working_hour_id: null,
      start_time: "09:00:00",
      end_time: "17:00:00",
    };
    let some = muWorkingDays.map((item, i) => {
      if (i == index) {
        item.muWorkingHours.push(newHour);
      }
      return item;
    });

    setMuWorkingDays(some);
  };

  useEffect(() => {
    var element = document.getElementById("mobile-unit-form-box");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    if (viewMobileUnit) {
      setError(false);
      setFormErrors({});
    } else if (addMobileUnit) {
      setFormErrors(initialFormErrors);
    }

    return () => {
      setFormErrors(initialFormErrors);
      setError(false);
    };
  }, [mappedMobileUnitData]);

  const onChangeValidation = (field, value, dayIndex, hourIndex) => {
    const validateResponse = validate(
      allData,
      muWorkingDays,
      true,
      field,
      value,
      { dayIndex, hourIndex }
    );

    if (
      field == "workingDays" &&
      Object.keys(validateResponse.errors).includes("workingDays")
    ) {
      setMuWorkingDays(validateResponse.workingDays);
    }

    if (Object.keys(validateResponse.errors).length != 0) {
      setError(true);

      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = {
          ...formErrors,
          ...validateResponse.errors,
        };
        setFormErrors(updatedErrorObject);
      } else {
        setFormErrors(validateResponse.errors);
      }
    } else if (Object.keys(formErrors).length != 0) {
      delete formErrors[field];
      const updatedErrorObject = { ...formErrors };
      setFormErrors(updatedErrorObject);
    } else {
      setError(false);
      // SetFormErrors(errorFields);
    }
  };

  const handleHourRemove = (event, dayIndex) => {
    setMuWorkingDays(
      muWorkingDays.map((day, ind) => {
        if (ind == dayIndex) {
          day.muWorkingHours.pop();
        }
        return day;
      })
    );
  };

  return (
    <Box
      sx={styles.formStyle}
      className={`form-block ${viewReadOnly && "view-form-block"}`}
    >
      <form onSubmit={handleSubmit}>
        <Grid id="mobile-unit-form-box" container spacing={2}>
          {/* MU Name */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                inputProps={{
                  readOnly: Boolean(viewReadOnly),
                }}
                value={allData.name}
                onChange={(e) => {
                  setAllData({ ...allData, name: e.target.value });
                  onChangeValidation("muName", e.target.value);
                }}
                sx={styles.textField}
                onBlur={(e) => onChangeValidation("muName", e.target.value)}
                placeholder="MU Name"
                // label="MU Name"
                label={<span className="required">MU Name {!viewReadOnly && <sup> *</sup>}</span>}

              />
              {error && formErrors.muName ? (
                <span className="form-error text-danger">
                  {formErrors.muName}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Emirate Name */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="demo-simple-select-label">
              <span className="required">Choose Emirate {!viewReadOnly && <sup> *</sup>}</span>
              </InputLabel> 
              <Select
                className="formField"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={allData.emirates_id}
                // label={"Choose Emirate"}
                onChange={(e) => {
                  setAllData({ ...allData, emirates_id: e.target.value });
                  onChangeValidation("emirates", e.target.value);
                }}
                onBlur={(e) => onChangeValidation("emirates", e.target.value)}
              >
                {emirateList.map((emirates) => {
                  return (
                    <MenuItem value={emirates.emirate_id}>
                      {emirates.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {error && formErrors.emirates ? (
              <span className="form-error text-danger">
                {formErrors.emirates}
              </span>
            ) : (
              ""
            )}
          </Grid>
          {/* Phone Extension */}
          <Grid
            item
            xs={2}
            md={3}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth  sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="phone-extension">Phone Extension</InputLabel>
              <Select
                labelId="phone-extension"
                id="extension-select"
                label="Phone Extension"
                defaultValue={allData.phone_extension}
                value={allData.phone_extension}
                InputProps={{
                  autoWidth: true,
                  disabled: Boolean(viewReadOnly) ? true : false,
                }}
                className="formField"
              >
                <MenuItem value={+971}>+971</MenuItem>
              </Select>
              {error && formErrors.phone_extension ? (
                <span className="form-error text-danger">
                  {formErrors.phone_extension}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Phone Number */}
          <Grid
            item
            xs={9}
            md={9}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                inputProps={{
                  readOnly: Boolean(viewReadOnly),
                }}
                value={allData.contact_number}
                onChange={(e) => {
                  setAllData({ ...allData, contact_number: e.target.value });
                  onChangeValidation("contact_number", e.target.value);
                }}
                onBlur={(e) =>
                  onChangeValidation("contact_number", e.target.value)
                }
                sx={styles.textField}
                onKeyPress={handleKeyPress}
                placeholder="Phone Number"
                label={<span className="required">Phone Number {!viewReadOnly && <sup> *</sup>}</span>}
              />
              {error && formErrors.contact_number ? (
                <span className="form-error text-danger">
                  {formErrors.contact_number}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Vehicle Number */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                inputProps={{
                  readOnly: Boolean(viewReadOnly, editMobileUnit),
                }}
                value={allData.vehicle_number}
                onChange={(e) => {
                  setAllData({ ...allData, vehicle_number: e.target.value });
                  onChangeValidation("vehicle_number", e.target.value);
                }}
                onBlur={(e) =>
                  onChangeValidation("vehicle_number", e.target.value)
                }
                sx={styles.textField}
                placeholder="Vehicle Number"
                label={<span className="required">Vehicle Number {!viewReadOnly && <sup> *</sup>}</span>}
              />
              {error && formErrors.vehicle_number ? (
                <span className="form-error text-danger">
                  {formErrors.vehicle_number}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Service */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="mutiple-select-label"><span className="required">Choose Service {!viewReadOnly && <sup> *</sup>}</span></InputLabel>

              <Select
                labelId="mutiple-select-label"
                multiple
                className="formField"
                value={muServices}
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={(e) => onChangeValidation("services", e.target.value)}
                renderValue={(v) => {
                  let displayArray = [];
                  v.forEach((element) => {
                    displayArray.push(element.service_name);
                  });
                  let string = displayArray.join(", ");
                  return string;
                }}
                MenuProps={MenuProps}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={
                        muServices.length > 0 &&
                        muServices.length < serviceOptions.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </MenuItem>
                {serviceOptions.map((option) => (
                  <MenuItem key={option.service_id} value={option}>
                    <ListItemIcon>
                      <Checkbox
                        checked={
                          muServices.find(
                            (element) =>
                              element.service_id === option.service_id
                          )
                            ? true
                            : false
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.service_name} />
                  </MenuItem>
                ))}
              </Select>
              {error && formErrors.services ? (
                <span className="form-error text-danger">
                  {formErrors.services}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ mb: "40px", mt: "30px" }}>
          <Typography className="block-heading">
            <span className="heading">OPERATION HOURS</span>
          </Typography>
        </Box>
        {/* MU Name */}
        <Box>
          {/* <div class="col-12 block-header main-title mt-4">
            <h6>
              <span class="header-title" sx={styles.headingStyle}>
                Operational Hours
              </span>
            </h6>
          </div> */}
        </Box>
        {/* Working Hours Block */}
        {muWorkingDays &&
          muWorkingDays.map((workingData, dayIndex) => {
            return (
              <Box
                sx={styles.checkedBlock}
                className={`${
                  workingData.muWorkingHours.length == 2 && "handledays"
                }`}
              >
                <Typography
                  variant="p"
                  sx={{ width: "150px", textAlign: "left", fontSize: "16px" }}
                >
                  {workingData.day}
                </Typography>
                <Box>
                  <div style={{ marginTop: 10, marginLeft: 10 }}>
                    <Switch
                      checked={workingData.is_working}
                      color="warning"
                      className="formField"
                      onChange={(e) => handleToggle(e.target.checked, dayIndex)}
                    />
                    {workingData.is_working ? (
                      <p className="text-center" style={{ fontSize: "12px" }}>
                        Open
                      </p>
                    ) : (
                      <p className="text-center" style={{ fontSize: "12px" }}>
                        Close
                      </p>
                    )}
                  </div>
                </Box>
                <Box style={{ display: "flex" }}>
                  <Box className="positionMUBlock timing-block">
                    {workingData.muWorkingHours &&
                      workingData.muWorkingHours.map((hours, timeIndex) => {
                        return (
                          <>
                            <Box
                              className={`${
                                timeIndex == 1 ? "handleHours" : "normalHours"
                              }`}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Stack spacing={3}>
                                      <TimePicker
                                        className="formField"
                                        disabled={
                                          !workingData.is_working && true
                                        }
                                        label="Start Time"
                                        value={dayjs(
                                          new Date(
                                            "2022-10-12 " + hours.start_time
                                          )
                                        )}
                                        onChange={(e) => {
                                          handleStartTime(
                                            e,
                                            dayIndex,
                                            timeIndex
                                          );
                                          onChangeValidation(
                                            "workingDays",
                                            e,
                                            dayIndex,
                                            timeIndex
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </Box>
                                <SyncAltSharpIcon sx={{ ml: 2, mr: 2 }} />
                                <Box sx={{ ml: 0 }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Stack spacing={3}>
                                      <TimePicker
                                        className="formField"
                                        disabled={
                                          !workingData.is_working && true
                                        }
                                        label="End Time"
                                        // value={dayjs(hours.end_time)}
                                        value={dayjs(
                                          new Date(
                                            "2022-10-12 " + hours.end_time
                                          )
                                        )}
                                        onChange={(e) => {
                                          handleEndTime(e, dayIndex, timeIndex);
                                          onChangeValidation(
                                            "workingDays",
                                            e,
                                            dayIndex,
                                            timeIndex
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </Box>

                                {timeIndex == 1 && !viewReadOnly && (
                                  <Box className="remove-block">
                                    <button
                                      className="btn"
                                      type="button"
                                      onClick={(e) =>
                                        handleHourRemove(e, dayIndex)
                                      }
                                    >
                                      <i class="fal fa-times"></i>
                                    </button>
                                  </Box>
                                )}
                              </Box>
                              <Box>
                                {error && hours.errors ? (
                                  <span className="form-error text-danger">
                                    {hours.errors}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                  </Box>
                  <Box>
                    {!viewReadOnly && (
                      <Button
                        disabled={
                          workingData.muWorkingHours.length === 2 ||
                          (viewReadOnly && true)
                        }
                        onClick={(e) => handleAddMore(e, dayIndex)}
                        type="button"
                        variant="outlined"
                        sx={{ textAlign: "right", ml: 5 }}
                      >
                        Add Hours
                      </Button>
                    )}

                    {/* {workingData.muWorkingHours.length === 2 &&
                      !viewReadOnly && (
                        <Box style={{ margin: "0px 0px 0px 10px" }}>
                          <button
                            className="btn"
                            type="button"
                            onClick={(e) => handleHourRemove(e, dayIndex)}
                          >
                            <i class="fal fa-times"></i>
                          </button>
                        </Box>
                      )} */}
                  </Box>
                </Box>
              </Box>
            );
          })}
        {/* {Assign Staff} */}
        <Box sx={{ mb: "40px", mt: "30px" }}>
          <Typography className="block-heading">
            <span className="heading">ASSIGN STAFF</span>
          </Typography>
        </Box>
        <Box sx={styles.formRowStyle} className="form-box">
          <Box sx={styles.iconLabelStyles}>
            <EngineeringIcon />
            <label>
              Choose Staff{" "}
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </label>
          </Box>
          <FormControl sx={{ width: "75%" }}>
            <InputLabel id="mutiple-select-label">Choose Staff</InputLabel>

            <Select
              labelId="mutiple-select-label"
              label={"Choose Staff"}
              multiple
              className="formField"
              value={staff}
              onChange={(e) => {
                handleStaffChange(e);
              }}
              onBlur={(e) => onChangeValidation("staff", e.target.value)}
              renderValue={(v) => {
                let displayArray = [];
                v.forEach((staff) => {
                  displayArray.push(staff.name);
                });
                let string = displayArray.join(", ");
                return string;
              }}
              MenuProps={MenuProps}
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    checked={isAllStaffSelected}
                    indeterminate={
                      staff.length > 0 && staff.length < staffOptions.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
              {staffOptions.map((option) => (
                <MenuItem key={option.staff_id} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        staff.find(
                          (staff) => staff.staff_id === option.staff_id
                        )
                          ? true
                          : false
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
            {error && formErrors.staff ? (
              <span className="form-error text-danger">{formErrors.staff}</span>
            ) : (
              ""
            )}
          </FormControl>
        </Box>
        {/* Submit Button */}
        {viewReadOnly ? (
          ""
        ) : (
          <Box sx={{ textAlign: "right", mt: "5px" }}>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default MobileUnitForm;

//Referenc codes
// const validationSchema = Yup.object().shape({
// name: Yup.string()
//   .required("MU Name is required")
//   .min(6, "MU Name must be atleast 6 characters"),
// emirate_name: Yup.string()
//   .required("MU Name is required")
//   .min(6, "MU Name must be atleast 6 characters"),
// salary: Yup.string()
//   .required("salary is required")
//   .min(6, "salary must be atleast 6 characters"),
// age: Yup.string()
// });

// const {
//   register,
//   handleSubmit,
//   reset,
//   control,
//   formState: { errors },
// } = useForm({
//   resolver: yupResolver(validationSchema),
// });
// const { field } = useController({ name: "status", control });
