import React, { useEffect } from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Box, Grid } from "@mui/material";
import { getBookingCount } from "../../../api/console/Dashboard/dashboard-management";
const Cards = () => {
  // const dashboardApiBlock = () => {
  //   const getBookingCounts = getBookingCount();
  //   getBookingCounts.then((res) => {
  //     console.log(res, "getBookingCount");
  //     console.log(res.unassignedBookingList, "unassignedBookingList");
  //   });
  // };
  // useEffect(() => {
  //   dashboardApiBlock();
  // }, []);

  return (
    <>
      <Grid item xs={6} md={3} className="card-grid">
        <Box className="card-block-dashboard ">
          <Box>
            <h6>Total Sales</h6>
            <h3>34516</h3>
            <p> 3% last month</p>
          </Box>
          <Box className="card-icon-dashboard gradient1">
            <TrendingUpIcon />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} md={3} className="card-grid">
        <Box className="card-block-dashboard ">
          <Box>
            <h6>Total Leads</h6>
            <h3>34516</h3>
            <p> 3% last month</p>
          </Box>
          <Box className="card-icon-dashboard gradient2">
            <RocketLaunchIcon />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} md={3} className="card-grid">
        <Box className="card-block-dashboard ">
          <Box>
            <h6>Total Leads</h6>
            <h3>34516</h3>
            <p> 3% last month</p>
          </Box>
          <Box className="card-icon-dashboard gradient3">
            <AttachMoneyIcon />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} md={3} className="card-grid">
        <Box className="card-block-dashboard ">
          <Box>
            <h6>Total Leads</h6>
            <h3>34516</h3>
            <p> 3% last month</p>
          </Box>
          <Box className="card-icon-dashboard gradient4">
            <TrendingUpIcon />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Cards;
