import React, { useEffect } from "react";
import { useContext } from "react";
import { SubAdminContext } from "./subadminContext/SubAdminContext";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { Box, Button } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer } from "react-toastify";
import SubAdminWindowRight from "./SubAdminWindowRight";
import {
  getSubAdminList,
  getSubAdmin,
} from "../../../api/console/SubAdmin/sub-admin-management";
import SubDeleteModal from "./Ui/SubDeleteModal";
import CredentialModal from "./Ui/CredentialModal";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ConfirmModal from "./Ui/ConfirmModal";
import Loader from "../layout/Loader";
import VerifiedIcon from "@mui/icons-material/Verified";

const SubAdminMainBlock = () => {
  const {
    setSubAdminData,
    addSubAdmin,
    SetAddSubAdmin,
    setViewReadOnly,
    SetMapppedSubAdmin,
    viewSubAdmin,
    setViewSubAdmin,
    setEditSubAdmin,
    subAdminList,
    setSubAdminList,
    initialState,
    handleOpen,
    SetdeleteId,
    loading,
    setLoading,
    setSubLoading,
  } = useContext(SubAdminContext);

  useEffect(() => {
    setLoading(true);
    const data = getSubAdminList();
    data.then((res) => {
      setSubAdminList(res);
      setLoading(false);
    });
  }, [setSubAdminList]);

  const handleAddSubAdmin = () => {
    setSubLoading(true);
    SetAddSubAdmin(true);
    setViewSubAdmin(false);
    setEditSubAdmin(false);
    setViewReadOnly(false);
    SetMapppedSubAdmin();
    setSubAdminData(initialState);
    setSubLoading(false);
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  //Columns
  const columns = [
    {
      field: "Sub Admin Name",
      headerName: "Sub Admin Name",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Box className="verified-icon-block">
              <Box className="verified-icon">
                <VerifiedIcon />
              </Box>
              <p style={{ marginBottom: "0px", lineHeight: "24px" }}>
                {params.row.name}
              </p>
            </Box>
          </>
        );
      },
    },
    {
      field: "Email",
      headerName: "E-mail",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.email}</>;
      },
    },
    {
      field: "Phone Number",
      headerName: "Mobile Number",
      // width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.phone_number_extension != null
              ? params.row.phone_number_extension
              : ""}
            <span className="word-space">{params.row.phone_number}</span>{" "}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <BootstrapTooltip title="Delete sub admin">
              <DeleteIcon
                onClick={(e) => handleOpen(e, params.row.subadmin_id)}
              />
            </BootstrapTooltip>
          </>
        );
      },
    },
  ];

  //table click
  const handleViewClick = (row) => {
    setSubLoading(true);
    SetAddSubAdmin(false);
    setViewSubAdmin(true);
    setEditSubAdmin(false);
    setViewReadOnly(true);
    SetdeleteId(row.subadmin_id);
    const data = {
      subadmin_id: row?.subadmin_id,
    };
    const response = getSubAdmin(data);
    response.then((res) => {
      SetMapppedSubAdmin(res);
      setSubLoading(false);
    });
  };
  const customRowsOverlay = () => {
    return (
      <GridOverlay>
        <p>No Data Available</p>
      </GridOverlay>
    );
  };
  return (
    <Box className="mainBlock">
      <Box className="title-search">
        <Box className="box-left">
          <span className="main-header">Sub Admin</span>
          <Button
            variant="outlined"
            sx={{ mb: "15px" }}
            onClick={handleAddSubAdmin}
          >
            Add New
          </Button>
        </Box>
        {/* <Box
          className="box-right"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Button className="btn-icon">
            Filter <FilterAltOutlinedIcon />
          </Button>
          <Button className="btn-icon">
            Sort <FilterListOutlinedIcon />
          </Button>
        </Box> */}
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box
            className={`dataTable-block ${addSubAdmin && "windowChange"} ${
              viewSubAdmin && "windowChange"
            }`}
          >
            <Box
              className="data-table-sub-block"
              style={{ width: "100%", background: "#fff" }}
            >
              <DataGrid
                components={{
                  NoRowsOverlay: customRowsOverlay,
                }}
                onRowClick={(e) => handleViewClick(e.row)}
                rows={subAdminList}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.subadmin_id}
              />
            </Box>
            <SubAdminWindowRight />
          </Box>
        </>
      )}
      <CredentialModal />
      <SubDeleteModal />
      <ToastContainer />
      <ConfirmModal />
    </Box>
  );
};

export default SubAdminMainBlock;
