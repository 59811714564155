import { createContext, useState } from "react";
import {
  createOrUpdateSubAdmin,
  getSubAdminList,
  deleteSubAdmin,
} from "../../../../api/console/SubAdmin/sub-admin-management";
import { Validation } from "../Ui/Validation";
import { toast } from "react-toastify";

export const SubAdminContext = createContext();

export const SubAdminProvider = (props) => {
  let initialState = {
    name: "",
    email: "",
    phone_number: "",
    phone_number_extension: "+971",
  };
  let initialCredential = {
    username: "",
    password: "",
    cpassword: "",
  };
  let newCredential = {
    current_password: "",
    new_password: "",
    cnew_password: ""
}
  let initialFormErrors = {
    name: "",
    email: "",
    phone_number: "",
  };
  let initialCredErrors = {
    current_password: "",
    new_password: "",
    cnew_password: ""
  };
  const [subAdminData, setSubAdminData] = useState(initialState);
  const [subCredential, setSubCredential] = useState(initialCredential);
  const [newSubCredential, setNewSubCredential] = useState(newCredential);

  const [addSubAdmin, SetAddSubAdmin] = useState(false);
  const [viewReadOnly, setViewReadOnly] = useState(false);
  const [viewSubAdmin, setViewSubAdmin] = useState(false);
  const [editSubAdmin, setEditSubAdmin] = useState(false);
  const [mappedSubAdmin, SetMapppedSubAdmin] = useState({});
  const [deleteId, SetdeleteId] = useState();

  //Loader states
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [error, SetError] = useState(false);
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const [crederror, SetCredError] = useState(false);
  const [credFormErrors, setCredFormErrors] = useState(initialCredErrors);
  const [muList, setMulist] = useState([]);
  const [selectedMuList, setSelectedMuList] = useState(muList && 
    muList[0]?.mobile_unit_id
  );
  const [subAdminList, setSubAdminList] = useState([]);
  //Form Change State
  const [formChange, setFormChange] = useState(false);
  //Confirm Modal State
  const [confirmModal, setConfirmModal] = useState(false);
  const handleConfirmShow = () => {
    setConfirmModal(true);
  };
  const handleConfirmClose = () => {
    setConfirmModal(false);
  };

  //delete modal state
  const [open, setOpen] = useState(false);
  //Credential modal
  const [credOpen, setCredOpen] = useState(false);
  const handleCloseCredModal = () => {
    setCredOpen(false);
  };
  const handleOpenCredModal = () => {
    setCredOpen(true);
  };
  

  //delete modal close
  const handleCloseDeleteMOdal = () => setOpen(false);

  const updateToast = () => {
    return editSubAdmin ? "Updated Successfully" : "Added Successfully";
  };
  const onSuccess = () => {
    toast.success(updateToast, {
      position: "bottom-right",
      autoClose: 1000,
      toastId: "update",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  //trigger delete modal
  const handleOpen = (e, data) => {
    e.stopPropagation();
    // console.log(data, "delete data eeeeeeeeeeeeeeeeeeee");
    // deleteId, SetdeleteId
    SetdeleteId(data ? data : "");
    setOpen(true);
  };

  //Delete confirm function
  const deleteSubAdminData = (id) => {
    // console.log(id, "staff map id");
    // setMappedStaff([]);++
    const data = {
      subadmin_id: id,
    };

    const resp = deleteSubAdmin(data);
    resp
      .then((res) => {
        getSubAdminList().then((res) => {
          // console.log(res, " getSubAdminList ....................res");
          setSubAdminList(res);
          setViewSubAdmin(false);
          SetAddSubAdmin(false);
          setViewReadOnly(false);
          setEditSubAdmin(false);
          handleCloseDeleteMOdal();
          if (!viewSubAdmin) {
            toast.success("Sub admin deleted successfully", {
              position: "bottom-right",
              autoClose: 1000,
              toastId: "error",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
      })
      .catch((err) => {
        // console.log(err, "sdffgfsdgdfgdfg");
      });
  };

  //Selected MU list  Array
  let listSubmu =
    selectedMuList &&
    selectedMuList.map((data) => {
      let li = data?.mobile_unit_id;
      return li;
    });
  // useEffect(() =>{
  //   console.log(credOpen,"......................credOpen")
  // })

  // console.log(selectedMuList, "..............selectedMuList on change");
  // console.log(listSubmu, ".............. listSubmu from selectedMuList ");

  //HANDLE SUBMIT
  const handleSubmit = (e) => {
    e.preventDefault()
    //Edit Mode
    if (editSubAdmin) {
      const data = {
        subadmin_id: mappedSubAdmin.subadmin_id,
        ...subAdminData,
        mobileUnits: listSubmu.length ? listSubmu : selectedMuList,
        // ...subCredential,
      };
      // console.log(data, "..................edit form data");
      let errors = Validation(data, null, null, false, editSubAdmin);
      if (Object.keys(errors).length) {
        SetError(true);
        setFormErrors(errors);
        return;
      } else {
        SetError(false);
        const response = createOrUpdateSubAdmin(data);
        response
          .then((res) => {
            const listData = getSubAdminList();
            listData.then((res) => {
              setSubAdminList(res);
              setViewSubAdmin(false);
              SetAddSubAdmin(false);
              setEditSubAdmin(false);
            });
            if (res) {
              onSuccess();
            }
          })
          .catch((err) => {
            // console.log(err.message);
            if (err) {
              // console.log("err.message", err.message);
              toast.error("Something went wrong", {
                position: "bottom-right",
                autoClose: 2000,
                toastId: "error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      }
    } else {
      //
      const data = {
        ...subAdminData,
        mobileUnits: selectedMuList?.length && listSubmu,
        ...subCredential,
      };
      console.log(data, "all data..............on.submit");
      let errors = Validation(data, null, null, false, false);
      if (Object.keys(errors).length) {
        SetError(true);
        setFormErrors(errors);
        return;
      } else {
        SetError(false);
        const response = createOrUpdateSubAdmin(data);
        response
          .then((res) => {
            console.log(res, "..........created res");
            SetAddSubAdmin(false);
            setViewSubAdmin(false);
            setEditSubAdmin(false);
            const listData = getSubAdminList();
            listData.then((res) => {
              console.log(res, " getSubAdminList ....................res");
              setSubAdminList(res);
              if (res) {
                onSuccess();
              }
            });
          })
          .catch((err) => {
            console.log(err.message);
            if (err) {
              // console.log("err.message", err.message);
              toast.success("Something went wrong", {
                position: "bottom-right",
                autoClose: 1000,
                toastId: "error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      }
    }
  };

  let initialValues = {
    subAdminData,
    setSubAdminData,
    addSubAdmin,
    SetAddSubAdmin,
    mappedSubAdmin,
    SetMapppedSubAdmin,
    viewReadOnly,
    setViewReadOnly,
    viewSubAdmin,
    setViewSubAdmin,
    editSubAdmin,
    setEditSubAdmin,
    deleteId,
    SetdeleteId,
    handleSubmit,
    initialState,
    initialFormErrors,
    error,
    SetError,
    formErrors,
    setFormErrors,
    muList,
    setMulist,
    subAdminList,
    setSubAdminList,
    subCredential,
    setSubCredential,
    open,
    setOpen,
    handleOpen,
    handleCloseDeleteMOdal,
    selectedMuList,
    setSelectedMuList,
    deleteSubAdminData,
    credOpen,
    setCredOpen,
    handleCloseCredModal,
    handleOpenCredModal,
    confirmModal,
    setConfirmModal,
    formChange,
    setFormChange,
    handleConfirmShow,
    handleConfirmClose,
    loading,
    setLoading,
    subLoading,
    setSubLoading,
    initialCredential,newCredential,
    newSubCredential, setNewSubCredential,
    crederror, SetCredError,
    credFormErrors, setCredFormErrors
  };

  return (
    <SubAdminContext.Provider value={initialValues}>
      {props.children}
    </SubAdminContext.Provider>
  );
};
