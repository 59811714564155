import React, { useState, createContext, useEffect } from "react";
import { getRequiredData } from "../../../../api/console/Common/common";
import {
  createMobileUnit,
  deleteMobileUnit,
  getMobileUnit,
  getMobileUnitList,
} from "../../../../api/console/MobileUnit/mobileunit-management";

export const MobileUnitContext = createContext();

export const MobileUnitProvider = (props) => {
  const [muDatas, setMUDatas] = useState([]);
  const [addMobileUnit, setAddMobileUnit] = useState(false);
  const [viewMobileUnit, setViewMobileUnit] = useState(false);
  const [editMobileUnit, setEditMobileUnit] = useState(false);
  const [mappedMobileUnitData, setMappedMobileUnitData] = useState({});
  const [emirateList, setEmirateList] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [viewReadOnly, setViewReadOnly] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);

  const handleMuEdit = (newData, id) => {
    let editData = muDatas.map((item) => {
      if (item.name === id) {
        return newData;
      }
      return item;
    });
    setMUDatas(editData);
    console.log("first");
  };

  const fetchMobileUnit = (id) => {
    setSubLoading(true);
    let data = {
      mobile_unit_id: id,
    };
    const response = getMobileUnit(data);
    response
      .then((res) => {
        setMappedMobileUnitData(res);
        setSubLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getReqData = () => {
    const response = getRequiredData();
    response
      .then((res) => {
        setServiceOptions(res.services);
        setEmirateList(res.emirates);
        setStaffOptions(res.staffs);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMobileUnitCreate = (data, { onSuccess, onFailure }) => {
    const response = createMobileUnit(data);
    response
      .then((res) => {
        console.log(res);
        onSuccess();
        getMuListAndRequiredDatas();
      })
      .catch((error) => {
        console.log(error);
        onFailure();
      });
  };

  const deleteMobileUnitHandle = (id, { onSuccess, onFailure }) => {
    const data = {
      mobile_unit_id: id,
    };
    const response = deleteMobileUnit(data);
    response
      .then((res) => {
        onSuccess();
        getMuListAndRequiredDatas();
        setAddMobileUnit(false);
        setViewMobileUnit(false);
        setEditMobileUnit(false);
        setViewReadOnly(false);
      })
      .catch((err) => {
        onFailure();
      });
  };

  const getMuListAndRequiredDatas = () => {
    setLoading(true);

    const response = getMobileUnitList();
    response.then((res) => {
      console.log(res);
      setMUDatas(res);
      setLoading(false);
    });
    getReqData();
  };

  useEffect(() => {
    getMuListAndRequiredDatas();
  }, []);
  let initialValues = {
    muDatas,
    setMUDatas,
    addMobileUnit,
    setAddMobileUnit,
    viewMobileUnit,
    setViewMobileUnit,
    mappedMobileUnitData,
    setMappedMobileUnitData,
    editMobileUnit,
    setEditMobileUnit,
    viewReadOnly,
    setViewReadOnly,
    handleMuEdit,
    fetchMobileUnit,
    handleMobileUnitCreate,
    emirateList,
    serviceOptions,
    staffOptions,
    getMuListAndRequiredDatas,
    deleteMobileUnitHandle,
    loading,
    setLoading,
    subLoading,
    setSubLoading,
  };
  return (
    <MobileUnitContext.Provider value={initialValues}>
      {props.children}
    </MobileUnitContext.Provider>
  );
};

//------*REFERENCE DATA*--------//
// {
//   mobile_unit_id: 1,
//   name: "Salman MU 3",
//   emirates_id: 1,
//   vehicle_number: "A12345",
//   contact_number: "01197155",
//   status: 1,
//   created_at: "2022-11-17T06:34:24.000Z",
//   updated_at: "2022-11-17T06:34:24.000Z",
//   emirate_name: "Abu Dhabi",
// },

// {
//   mobile_unit_id: 1,
//   name: "MU 3",
//   emirates_id: 1,
//   vehicle_number: "A12345",
//   contact_number: "01197155",
//   status: 1,
//   created_at: "2022-11-23T06:24:09.000Z",
//   updated_at: "2022-11-23T06:24:09.000Z",
//   emirate_name: "Abu Dhabi",
//   muServices: [
//     {
//       service_id: 1,
//     },
//     {
//       service_id: 2,
//     },
//     {
//       service_id: 3,
//     },
//   ],
//   muStaffs: [],
//   muWorkingDays: [
//     {
//       mu_working_day_id: 1,
//       day: "Monday",
//       is_working: 1,
//       muWorkingHours: [
//         {
//           mu_working_hour_id: 1,
//           start_time: "10:00:00",
//           end_time: "12:00:00",
//         },
//         {
//           mu_working_hour_id: 2,
//           start_time: "01:00:00",
//           end_time: "06:00:00",
//         },
//       ],
//     },
//     {
//       mu_working_day_id: 2,
//       day: "Tuesday",
//       is_working: 1,
//       muWorkingHours: [
//         {
//           mu_working_hour_id: 3,
//           start_time: "10:00:00",
//           end_time: "12:00:00",
//         },
//         {
//           mu_working_hour_id: 4,
//           start_time: "01:00:00",
//           end_time: "06:00:00",
//         },
//       ],
//     },
//     {
//       mu_working_day_id: 3,
//       day: "Wednesday",
//       is_working: 1,
//       muWorkingHours: [
//         {
//           mu_working_hour_id: 5,
//           start_time: "10:00:00",
//           end_time: "12:00:00",
//         },
//         {
//           mu_working_hour_id: 6,
//           start_time: "01:00:00",
//           end_time: "06:00:00",
//         },
//       ],
//     },
//     {
//       mu_working_day_id: 4,
//       day: "Thursday",
//       is_working: 1,
//       muWorkingHours: [
//         {
//           mu_working_hour_id: 7,
//           start_time: "10:00:00",
//           end_time: "12:00:00",
//         },
//         {
//           mu_working_hour_id: 8,
//           start_time: "01:00:00",
//           end_time: "06:00:00",
//         },
//       ],
//     },
//     {
//       mu_working_day_id: 5,
//       day: "Friday",
//       is_working: 0,
//       muWorkingHours: [
//         {
//           mu_working_hour_id: 9,
//           start_time: "10:00:00",
//           end_time: "12:00:00",
//         },
//         {
//           mu_working_hour_id: 10,
//           start_time: "01:00:00",
//           end_time: "06:00:00",
//         },
//       ],
//     },
//     {
//       mu_working_day_id: 6,
//       day: "Saturday",
//       is_working: 1,
//       muWorkingHours: [
//         {
//           mu_working_hour_id: 11,
//           start_time: "10:00:00",
//           end_time: "12:00:00",
//         },
//         {
//           mu_working_hour_id: 12,
//           start_time: "01:00:00",
//           end_time: "06:00:00",
//         },
//       ],
//     },
//     {
//       mu_working_day_id: 7,
//       day: "Sunday",
//       is_working: 0,
//       muWorkingHours: [
//         {
//           mu_working_hour_id: 13,
//           start_time: "00:00:00",
//           end_time: "00:00:00",
//         },
//         {
//           mu_working_hour_id: 14,
//           start_time: "00:00:00",
//           end_time: "00:00:00",
//         },
//       ],
//     },
//   ],
// },
