import { consoleAPI } from "../../axios";

const getBookingsList = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/list",
    });
    if (res.success) {
      // console.log(res , "res getBookingList")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getMappedBooking = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/data",
      data,
    });
    if (res.success) {
      // console.log(res , "res getReqData")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getPreRequisiteData = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "GET",
      url: "console/booking/get-prerequisite-data",
    });
    if (res.success) {
      // console.log(res , "res getReqData")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getModelData = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/get-vehicle-list",
      data,
    });
    if (res.success) {
      // console.log(res , "res getModelData")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getServiceList = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/get-service-list",
      data,
    });
    if (res.success) {
      // console.log(res , "res service List")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const createBooking = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/create",
      data,
    });
    if (res.success) {
      // console.log(res , "res createBooking")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getVehicleNums = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/suggest-vehicle-nums",
      data,
    });
    if (res.success) {
      // console.log(res, "res vehicle-nums")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getCustomer = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/get-customer",
      data,
    });
    if (res.success) {
      // console.log(res, "res getCustomer")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
// const getMuList = async () => {
//   const consoleInst = consoleAPI();
//   try {
//     const res = await consoleInst({
//       method: "POST",
//       url: "/console/subadmin/mobile-units",
//     });
//     if (res.success) {
//       // console.log(res , "res getBookingList")
//       return res.data;
//     }
//     return Promise.reject(res);
//   } catch (err) {}
// };
const getMuList = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/mu/list",
    });
    if (res.success) {
      // console.log(res , "res getBookingList")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getCancelBooking = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/cancel-order",
      data,
    });
    if (res.success) {
      // console.log(res , "res getReqData")
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

export {
  getBookingsList,
  getPreRequisiteData,
  getMappedBooking,
  getModelData,
  getServiceList,
  createBooking,
  getVehicleNums,
  getCustomer,
  getMuList,
  getCancelBooking,
};
