import React, { useContext } from "react";
import { Box, Grid, Button } from "@mui/material";
import { SubAdminContext } from "./subadminContext/SubAdminContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const SubAdminWindowRightHeader = () => {
  const {
    SetAddSubAdmin,
    viewReadOnly,
    setViewReadOnly,
    mappedSubAdmin,
    viewSubAdmin,
    setViewSubAdmin,
    editSubAdmin,
    setEditSubAdmin,
    deleteId,
    handleSubmit,
    handleOpen,
    formChange,
    handleConfirmShow,
    credOpen
  } = useContext(SubAdminContext);
  const styles = {
    addHeaderStyle: {
      display: "flex",
      alignItems: "Center",
      justifyContent: "space-between",
      padding: "9px 15px",
      borderBottom: "1px solid #ff000014",
    },
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const handleCloseWindowRight = () => {
    if (formChange) {
      handleConfirmShow();
    } else {
      SetAddSubAdmin(false);
      setViewSubAdmin(false);
    }
  };

  const editSubAdminhandler = () => {
    setViewReadOnly(false);
    setEditSubAdmin(true);
  };

  return (
    <Box sx={styles.addHeaderStyle}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <BootstrapTooltip title="Back to listing" placement="left">
          <KeyboardBackspaceIcon onClick={(e) => handleCloseWindowRight(e)} />
        </BootstrapTooltip>

        {/* <BootstrapTooltip title="Back to listing" placement="left">
        <KeyboardBackspaceIcon 
        onClick={handleCloseWindowRight} 
        />
        </BootstrapTooltip> */}
        <h6 className="name-header">
          {/* {editSubAdmin ? `Edit ${mappedSubAdmin?.name}` : viewSubAdmin ? `View ${mappedSubAdmin?.name}`: "Add Staff"} */}
          {editSubAdmin
            ? mappedSubAdmin?.name
            : viewSubAdmin
            ? mappedSubAdmin?.name
            : "Add Sub Admin"}
        </h6>
      </Box>
      <Grid>
        <>
          {viewReadOnly && (
            <button
              sx={{ ml: "100px" }}
              onClick={(e) => handleOpen(e, deleteId && deleteId)}
              className="action-btn"
            >
              <BootstrapTooltip title="Delete sub admin">
                <DeleteIcon />
              </BootstrapTooltip>
            </button>
          )}
        </>
        {viewReadOnly && (
          <button
            sx={{ ml: "100px" }}
            onClick={editSubAdminhandler}
            className="action-btn"
          >
            <BootstrapTooltip title="Edit sub admin">
              <EditIcon />
            </BootstrapTooltip>
          </button>
        )}
      </Grid>
    </Box>
  );
};

export default SubAdminWindowRightHeader;
