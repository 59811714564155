import React, { useContext } from "react";
import { SubAdminContext } from "../subadminContext/SubAdminContext";
import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
import recycle from "../../../../assets/img/deleteIcon/recycle.svg";

const SubDeleteModal = () => {
  const {
    open,
    handleCloseDeleteMOdal,
    deleteId,
    deleteSubAdminData
  } = useContext(SubAdminContext);
  const style = {
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    iconStyle: {
      margin: " 0 auto",
      height: "100px",
      width: "100px",
    },
    buttonsStyles: {
      display: "flex",
      // alignItems:'center',
      justifyContent: "center",
      gap: "20px",
    },
    deleteBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
  };

  return (
    <Modal open={open}>
      <Box sx={style.modalStyles}>
        <Avatar src={recycle} sx={style.iconStyle} />
        <Typography
          sx={style.fontStyle}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Are you sure you want to delete ?
        </Typography>

        <Box sx={style.buttonsStyles}>
          <Button
            variant="text"
            sx={style.cancelBtn}
            onClick={handleCloseDeleteMOdal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={style.deleteBtn}
            color="error"
            onClick={(e) => deleteSubAdminData(deleteId && deleteId)}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubDeleteModal;
