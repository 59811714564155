const CredentialValidation = (values, field, field_value, isOnChange) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
    let errors = {};
  
    if (!isOnChange) {
      if (!values.current_password) {
        errors.current_password = "Current password is required *";
      }
      if (!values.new_password) {
        errors.new_password = "New password is required *";
      }
      if (!values.cnew_password) {
        errors.cnew_password = "Confirm new password is required *";
      }
      if (values.new_password !== values.cnew_password) {
        errors.cnew_password = "Passwords do not match *";
      }

    } else {

      //Staff Gender
       if (field === "current_password" && isOnChange) {
        if (!field_value) {
          errors.current_password = "Current password is required  *";
        }
      }
      //Staff Gender
      else if (field === "new_password" && isOnChange) {
        if (!field_value) {
          errors.new_password = "New password is required *";
        }
      }
      else if (field === "cnew_password" && isOnChange) {
        if (!field_value) {
          errors.cnew_password = "Confirm new password is required *";
        }
      }
    }
    return errors;
  };
  
  export { CredentialValidation };
  