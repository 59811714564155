import { Grid } from "@mui/material";
import React from "react";
import {SubAdminProvider } from "./subadminContext/SubAdminContext";
import SubAdminMainBlock from "./SubAdminMainBlock";

function Subadmin() {

  return (
    <Grid
      className="grid"
    >
      <SubAdminProvider>
        <SubAdminMainBlock />
      </SubAdminProvider>
    </Grid>
  );
}

export default Subadmin;
