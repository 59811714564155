import React from "react";

const Validation = (values, field, field_value, isOnChange, doj, dob) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
  let errors = {};

  if (!isOnChange) {
    if (!values.name) {
      errors.name = "Name is required*";
    }
    //phone
    if (!values.phone) {
      errors.phone = "Phone number is required*";
    }
    if (values.phone && values.phone?.length < 9) {
      errors.phone = "Phone number is invalid";
    }
    if (values.phone && values.phone.length > 10) {
      errors.phone =
        "Phone number is invalid";
    }
    //Designation
    if (!values.designation) {
      errors.designation = "Designation is required*";
    }

    // if (!pattern.test(values.phone)) {
    //   errors.phone = "Only Numbers can be entered  as  phone number.";
    // }
    //emergency contact
    if (!values.emergency_details_phone) {
      errors.emergency_details_phone = "Phone Number is required*";
    }
    if (values.emergency_details_phone && values.emergency_details_phone.length < 9) {
      errors.emergency_details_phone =
        "Phone number is invalid";
    }
    if (values.emergency_details_phone && values.emergency_details_phone.length > 10) {
      errors.emergency_details_phone =
        "Phone number is invalid";
    }
    if (!values.emergency_details_phone_extension) {
      errors.emergency_details_phone_extension = "Phone extension is required*";
    }
    if (!values.address) {
      errors.address = "Address is required*";
    }
    if (values.email) {
      if (!emailRegex.test(values.email)) {
        errors.email = "This is not a valid email format!";
      }
    }
    if (!values.gender) {
      errors.gender = "Gender is required*";
    }
    if (!values.emergency_details_relation_type) {
      errors.emergency_details_relation_type = "Relation type is required*";
    }
    if (!values.staff_id_proof_type) {
      errors.staff_id_proof_type = "ID proof type is required*";
    }
    if (!values.emergency_details_name) {
      errors.emergency_details_name = "Emergency contact name is required*";
    }
    if (!values.salary) {
      errors.salary = "Salary is required*";
    }
     //Staff emergency Address
      if (!values.emergency_details_address) {
        errors.emergency_details_address = "Address type is required*";
      }

    let dateofBirth = dob == undefined ? new Date() : new Date(dob);
    let dateOfJoining = doj == undefined ? new Date() : new Date(doj);
    //  doj = new Date(doj).getMilliseconds();
    //  dob = new Date(dob).getMilliseconds();

    console.log(dob, doj, "----------------------------------------");
    if (
      dateOfJoining.toLocaleDateString() == dateofBirth.toLocaleDateString()
    ) {
      errors.dob = " Date of birth cannot be same as date of joining";
    } else if (dateofBirth > dateOfJoining) {
      errors.dob = " Date of Birth cannot be greater than date of joining";
    } 
  }
  //OnBlur && OnChange Validations
  else {
    //Staff Name
    if (field === "name" && isOnChange) {
      if (!field_value) errors.name = "Name is required.";
    }
    // //Staff Email
    // else if (field === "email" && isOnChange) {
    //   if (!field_value) {
    //     errors.email = "Email is required*";
    //   }
    // }
    //Staff phone
    else if (field === "phone" && isOnChange) {
      if (!field_value) {
        errors.phone = "Phone number is required*";
      }
    }
    //Staff pfone extension
    else if (field === "phone_extension" && isOnChange) {
      if (!field_value) {
        errors.phone_extension = "Phone extension is required*";
      }
    }
    //Staff Emergency  pfone extension
    else if (field === "emergency_details_phone_extension" && isOnChange) {
      if (!field_value) {
        errors.emergency_details_phone_extension = "Phone extension is required*";
      }
    }
    //Staff Address
    else if (field === "address" && isOnChange) {
      if (!field_value) {
        errors.address = "Address is required*";
      }
    }
    //Staff Gender
    else if (field === "gender" && isOnChange) {
      if (!field_value) {
        errors.gender = "Gender is required*";
      }
    }
    //Staff Designation
    else if (field === "designation" && isOnChange) {
      if (!field_value) {
        errors.designation = "Designation is required*";
      }
    }
    //Staff Salary
    else if (field === "salary" && isOnChange) {
      if (!field_value) {
        errors.salary = "Salary is required*";
      }
    }
    //Staff Relation type
    else if (field === "staff_id_proof_type" && isOnChange) {
      if (!field_value) {
        errors.staff_id_proof_type = "Id-proof  type is required*";
      }
    }
    //Staff emergency name
    else if (field === "emergency_details_name" && isOnChange) {
      if (!field_value) {
        errors.emergency_details_name = "Emergency contact name is required*";
      }
    }
    //Staff emergency Address
    else if (field === "emergency_details_address" && isOnChange) {
      if (!field_value) {
        errors.emergency_details_address = "Address is required*";
      }
    }
    //Staff Emergency Phone Number
    else if (field === "emergency_details_phone" && isOnChange) {
      if (!field_value) {
        errors.emergency_details_phone = "Phone number is required*";
      }
    }
    //Staff Relation type
    else if (field === "emergency_details_relation_type" && isOnChange) {
      if (!field_value) {
        errors.emergency_details_relation_type = "Relation is required*";
      }
    }
  }
  return errors;
};

export { Validation };
