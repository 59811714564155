const Validation = (values, field, field_value, isOnChange, editSubAdmin) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  // const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
  let errors = {};

  if (!isOnChange) {
    if (!values.name) {
      errors.name = "Name is required*";
    }
    if (!values.email) {
      errors.email = "Email is required*";
    }
    if (values.email) {
      if (!emailRegex.test(values.email)) {
        errors.email = "This is not a valid email format!";
      }
    }
    if (!values.phone_number) {
      errors.phone_number = "Phone number is required*";
    }
    if (!values.phone_number_extension) {
      errors.phone_number_extension = "Phone extension is required*";
    }
    if (values.phone_number) {
      if (values.phone_number?.length < 9) {
        errors.phone_number = "9 to 10 characters required for phone number";
      }
      if (values.phone_number?.length > 10) {
        errors.phone_number =
          "Only 9 to 10 characters required for phone number";
      }
    }

    if (!values.username && !editSubAdmin) {
      errors.username = "Username is required*";
    }
    if (!values.password && !editSubAdmin) {
      errors.password = "Password is required*";
    }
    if (!values.password && !values.cpassword && !editSubAdmin) {
      errors.cpassword = "Please enter password & confirm password *";
    }
    if (values.password && !values?.cpassword && !editSubAdmin) {
      errors.cpassword = "Please confirm Password";
    } else if (values.password !== values.cpassword && !editSubAdmin) {
      errors.cpassword = "Password and confirm password does not match";
    }
  } else {
    //SubAdmin Name
    if (field === "name" && isOnChange) {
      if (!field_value) {
        errors.name = "Name is required*";
      } else if (field_value.length) {
        errors.name = "";
      }
    }
    //SubAdmin Email
    if (field === "email" && isOnChange) {
     if (field_value.length) {
        if (!emailRegex.test(field_value)) {
          errors.email = "Email is required*";
        } else if (field_value.length > 5 && emailRegex.test(field_value)) {
          errors.email = "";
        }
      }
      else if (!field_value) {
        errors.email = "Email is required*";
      }  
    }
    //Staff phone extension
    else if (field === "phone_number_extension" && isOnChange) {
      if (!field_value) {
        errors.phone_number_extension = "Phone extension is required";
      }
    }
    //SubAdmin phone
    else if (field === "phone_number" && isOnChange) {
     if (field_value.length) {
        if (field_value.length < 9 ) {
          errors.phone_number = "Phone number is required*";
        }
        else if (field_value.length > 9){
          errors.phone_number = "";
        }
      }
      else if (!field_value) {
        errors.phone_number = "Phone number is required*";
      }
       
    }
    //Staff Address
    else if (field === "address" && isOnChange) {
      if (!field_value) {
        errors.address = "Address is required*";
      }
    }
    //Staff Gender
    else if (field === "gender" && isOnChange) {
      if (!field_value) {
        errors.gender = "Gender is required*";
      }
    }
    //Staff Gender
    else if (field === "username" && isOnChange) {
      if (!field_value) {
        errors.username = "Username is required*";
      }
    }
    //Staff Gender
    else if (field === "password" && isOnChange) {
      if (!field_value) {
        errors.password = "Password is required  *";
      }
    }
    //Staff Gender
    else if (field === "cpassword" && isOnChange) {
      if (!field_value) {
        errors.cpassword = "Confirm password is required*";
      }
    }
  }

  // if (values.password !== values.cpassword) {
  //   errors.cpassword = "Password and confirm Password are not the same";
  // }

  return errors;
};

export { Validation };
