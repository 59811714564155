import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { StaffContext } from "./staffContext/StaffContext";
import EmailIcon from "@mui/icons-material/Email";
import mailicon from "../../../assets/img/icon/mailicon.svg";
import viewicon from "../../../assets/img/icon/view.svg";

import noImg from "../../../assets/img/user/3.png";
const StaffView = () => {
  const headerStyles = {
    logoutBlock: {
      background: "#ffffff",
      color: "#000",
      borderRadius: "6px",
    },

    ulBlock: {
      // list-style-type: none;
      padding: "18px",
      boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
      width: "100%",
      borderRadius: "12px",
      marginBottom: "20px",
    },
    serviceulBlock: {
      // list-style-type: none;
      padding: "18px",
      // boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
      background: "red",
      width: "100%",
      borderRadius: "12px",
      marginBottom: "20px",
    },
    typoBlock: {
      borderBottom: "1px solid #75757529",
      fontSize: "15px",
      paddingBottom: "5px",
      letterSpacing: "0.8px",
    },
    typotypoBlock: {
      // borderBottom: "1px solid #75757529",
      fontSize: "15px",
      paddingBottom: "5px",
      letterSpacing: "0.8px",
      // display: "flex",
      // alignItems: "center"
    },
    spanBlock: {
      marginTop: "5px",
      color: "#111",
      fontWeight: "600",
      fontSize: "15px",
    },
    spanServiceBlock: {
      marginTop: "5px",
      color: "#111",
      fontWeight: "600",
      fontSize: "16px",
      marginRight: "6px",
      marginLeft: "6px",
    },
    formBlock: {
      padding: "25px 20px !important",
    },
  };
  const { mappedStaff, mappedPic } = useContext(StaffContext);
  const statusData = [1, 2, 3, 4, 5];
  let YyMmDd =
    mappedStaff.date_of_joining != null &&
    new Date(mappedStaff.date_of_joining).toLocaleDateString("fr-CA");
  const [year, month, day] = YyMmDd.split("-");

  let DobYyMmDd =
    mappedStaff.date_of_birth != null &&
    new Date(mappedStaff.date_of_birth).toLocaleDateString("fr-CA");
  const [yearDOB, monthDOB, dayDOB] = DobYyMmDd.split("-");
  //check id extension

  // const getExtension = (filename) => {
  //   return filename.map((e) => e.id_proof).split(".").pop();
  //   // console.log(filename)
  // };
  // getExtension(mappedStaff.staffIdProof);

  const extensionCheck =
    mappedStaff.staffIdProof &&
    mappedStaff.staffIdProof.map((item) => {
      return item.id_proof;
    });
  const extCheck = extensionCheck.toString().split(".").pop();

  console.log(extCheck, "extensionCheck");

  return (
    <Box sx={headerStyles.formBlock} id="formTopBlock">
      <Grid conexttainer>
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid md={8}>
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Box className="profile-img-view">
                    <Box className="profile-view-view">
                      <img
                        src={
                          mappedPic
                            ? `http://seahorseapi.codingo.co:8081/uploads/staffs/profile/${mappedStaff.profile_image}`
                            : noImg
                        }
                        className="img-fluid"
                        alt=""
                      />
                      <Box>
                        <h2 style={{ fontSize: "20px", fontWeight: "900" }}>
                          {mappedStaff.name}
                          {/* {extCheck == ("pdf" || "docx" || "docx") ? "Download" : "View"} */}
                        </h2>
                        <p>
                          <span className="mail-icon-block">
                            <img src={mailicon} alt="" />
                          </span>
                          {mappedStaff.email}
                        </p>
                      </Box>
                    </Box>
                    {mappedStaff.staffIdProof?.length > 0 ? 
                      <Box style={{ marginRight: "8px" }}>
                        <a className="btn-idproof">
                          <span>
                            <img src={viewicon} alt="" />
                          </span>
                          {`View ${mappedStaff.staffIdProof.map((e) => {
                            return e.idProofType.title;
                          })}`}
                        </a>
                      </Box>
                      : ``
                    }
                  </Box>
                  <Box className="row">
                    {/* Vehicle Details  */}
                    <Box
                      className="col-12 mb-3 mt-5"
                      sx={headerStyles.logoutBlock}
                      item
                      md={6}
                    >
                      <Box sx={headerStyles.logoutBlock}>
                        <Typography variant="h6" sx={headerStyles.typoBlock}>
                          Personal Details
                        </Typography>
                        <p
                          style={{
                            fontSize: "1rem",
                            letterSpacing: "0.8px",
                            margin: "15px 0 5px 0",
                            textTransform: "capitalize",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                        </p>
                        <Box className="row">
                          <Box className="col-md-6 mb-3">
                            {/* <p>Total Amount :</p> */}
                            <p>Gender :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedStaff.gender === 1 && "Male"}
                              {mappedStaff.gender === 2 && "Female"}
                              {mappedStaff.gender === 3 && "Ohter"}
                            </span>
                          </Box>
                          <Box className="col-md-6 mb-3">
                            {/* <p>Total Amount :</p> */}
                            <p>Phone Number :</p>
                            <span style={headerStyles.spanBlock}>
                              <span className="word-space-right">
                                {mappedStaff != null
                                  ? mappedStaff.phone && mappedStaff.phone_extension
                                  : ""}
                              </span>
                              {mappedStaff != null
                                ? mappedStaff.phone && mappedStaff.phone
                                : ""}
                            </span>
                          </Box>
                          <Box className="col-md-6 mb-3">
                            {/* <p>Total Amount :</p> */}
                            <p>Date of Birth :</p>
                            <span style={headerStyles.spanBlock}>
                              {/* {mappedStaff != null
                                ? mappedStaff.date_of_birth &&
                                  new Date(
                                    mappedStaff.date_of_birth
                                  ).toLocaleDateString("fr-CA")
                                : ""} */}
                              {`${dayDOB}-${monthDOB}-${yearDOB}`}
                            </span>
                          </Box>
                          <Box className="col-md-6 mb-3">
                            {/* <p>Total Amount :</p> */}
                            <p>Address :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedStaff != null
                                ? mappedStaff.address && mappedStaff.address
                                : ""}
                            </span>
                          </Box>
                          <Box className="col-md-6 mb-3">
                            {/* <p>Total Amount :</p> */}
                            <p>Blood Group :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedStaff.bloodGroupId != null
                                ? mappedStaff.bloodGroupId &&
                                  mappedStaff.bloodGroupId.title
                                : ""}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* Service Details  */}
                  </Box>
                </Box>
              </Box>
              {/* <Box>
                {new Date(mappedStaff.date_of_birth).toLocaleDateString(
                  "fr-CA"
                )}
              </Box> */}
              {/* {mappedStaff.date_of_birth} */}
              {/* {mappedStaff.bloodGroupId.title} */}
            </Grid>
            {/* <Grid item sx={{ padding: "10px 10px" }} md={8}></Grid> */}
            <Grid
              className="grid-adjust"
              sx={{ padding: "0px 10px 10px 10px" }}
              item
              md={4}
            >
              {/* Personal Details*/}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Typography variant="h6" sx={headerStyles.typoBlock}>
                    Office Details
                  </Typography>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  ></p>
                  <Box className="row">
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Designation :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedStaff.staffType != null
                          ? mappedStaff.staffType &&
                            mappedStaff.staffType.designation
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Salary :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedStaff != null
                          ? mappedStaff.salary && mappedStaff.salary
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Date of Joining :</p>
                      <span style={headerStyles.spanBlock}>
                        {/* {mappedStaff != null
                          ? mappedStaff.date_of_joining &&
                            new Date(
                              mappedStaff.date_of_joining
                            ).toLocaleDateString("fr-CA")
                          : ""} */}
                        {`${day}-${month}-${year}`}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* Emergency Details*/}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock} className="emergency-block">
                  <Typography variant="h6" sx={headerStyles.typoBlock}>
                    Emergency Contact
                  </Typography>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  ></p>
                  <Box className="row">
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Name :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedStaff.staffEmergencyDetail != null
                          ? mappedStaff.staffEmergencyDetail.name &&
                            mappedStaff.staffEmergencyDetail.name
                              .charAt(0)
                              .toUpperCase() +
                              mappedStaff.staffEmergencyDetail.name.slice(1)
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Phone Number :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedStaff.staffEmergencyDetail != null
                          ? mappedStaff.staffEmergencyDetail.phone &&
                            mappedStaff.staffEmergencyDetail.phone
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Relation :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedStaff.staffEmergencyDetail != null
                          ? mappedStaff.staffEmergencyDetail.relationType &&
                            mappedStaff.staffEmergencyDetail.relationType.title
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Address :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedStaff.staffEmergencyDetail != null
                          ? mappedStaff.staffEmergencyDetail.address &&
                            mappedStaff.staffEmergencyDetail.address
                          : ""}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="box-block" container></Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StaffView;
