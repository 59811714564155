import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import isAuthenticated from "../../utils/isAuthenticated";

const RedirectIfAuthenticatedGuard = () => {
  localStorage.setItem("selectedIndex", 0);
  return isAuthenticated() ? <Navigate to="/console/dashboard" /> : <Outlet />;
};

export default RedirectIfAuthenticatedGuard;
