import React from "react";
import "../../console/mobileunit/ui/mu.css";
import { CustomerProvider } from "./customerContext/CustomerContext";
import CustomerMainBlock from "../customer/CustomerMainBlock";
import { Grid } from "@mui/material";

const Customer = (props) => {

  return (
    <>
      <Grid
        className="grid"
      >
        <CustomerProvider>
          <CustomerMainBlock />
        </CustomerProvider>
      </Grid>
    </>
  );
};

export default Customer;
