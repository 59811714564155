import { Box } from "@mui/system";
import React, { useContext } from "react";
import { ServiceContext } from "./serviceContext/ServiceContext";
import ServiceForm from "./ServiceForm";
import ServiceWindowHeader from "./ServiceWindowHeader";
import SubLoader from "../layout/SubLoader";
function ServiceWindowRight() {
  const { addService, setAddService, subLoading } = useContext(ServiceContext);
  const styles = {
    windowWidth: {
      width: "calc(100% - 250px)",
    },
  };
 

  return (
    <Box className="windowRightBlock" sx={styles.windowWidth}>
      {subLoading ? (
        <SubLoader />
      ) : (
        <>
          <ServiceWindowHeader setAddService={setAddService} />
          <ServiceForm setAddService={setAddService} />
        </>
      )}
    </Box>
  );
}

export default ServiceWindowRight;
