import { Box, Grid, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { StaffContext } from "./staffContext/StaffContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function StaffWindowRightHeader() {
  const {
    setAddStaff,
    viewStaff,
    setViewStaff,
    editStaff,
    mappedStaff,
    setEditStaff,
    setViewReadOnly,
    viewReadOnly,
    setPic,
    setIdProof,
    pic,
    handleOpen,
    staffDeleteId,
    handleSubmit,
    formChange,
    handleConfirmShow,
  } = useContext(StaffContext);

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const styles = {
    addHeaderStyle: {
      display: "flex",
      alignItems: "Center",
      justifyContent: "space-between",
      padding: "9px 15px",
      borderBottom: "1px solid #ff000014",
    },
  };
  const handleMuExit = (e) => {
    e.preventDefault();
    if (formChange) {
      handleConfirmShow();
    } else {
      setAddStaff(false);
      setViewStaff(false);
      console.log("exit view mu");
    }
  };
  const editMU = () => {
    setEditStaff(true);
    setViewReadOnly(false);
    setPic();
    setIdProof([]);
    console.log(pic, " pic hai");
  };
  // const BootstrapTooltip = styled(({ className, ...props }) => (
  //   <Tooltip {...props} arrow classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.arrow}`]: {
  //     color: theme.palette.common.black,
  //   },
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.black,
  //   },
  // }));
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  return (
    <Box sx={styles.addHeaderStyle} className="after-div">
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {/* <button className="btn btn-light" onClick={(e) => handleMuExit(e)}> */}
        <BootstrapTooltip
          title="Back to listing"
          placement="left"
          onClick={(e) => handleMuExit(e)}
        >
          <KeyboardBackspaceIcon />
        </BootstrapTooltip>
        {/* </button>{" "} */}
        {/* <Box className="btn" onClick={(e) => handleMuExit(e)} > 
        <BootstrapTooltip title="Back to listing" placement="left">
        <KeyboardBackspaceIcon />
        </BootstrapTooltip>
        </Box> */}
        {/* {editStaff ? `Edit ${mappedStaff?.name}` : viewStaff ? `View ${mappedStaff?.name}`: "Add Staff"} */}
        <h6 className="name-header">
          {editStaff
            ? mappedStaff?.name
            : viewStaff
            ? mappedStaff?.name
            : "Add Staff"}
        </h6>
      </Box>

      <Grid>
        <>
          {viewReadOnly && (
            <button
              sx={{ ml: "100px" }}
              onClick={(e) => handleOpen(e, staffDeleteId && staffDeleteId)}
              className="action-btn"
            >
              <Tooltip title="Delete staff">
                <DeleteIcon />
              </Tooltip>
            </button>
          )}
        </>
        {viewReadOnly && (
          <button sx={{ ml: "100px" }} onClick={editMU} className="action-btn">
            <Tooltip title="Edit staff">
              <EditIcon />
            </Tooltip>
          </button>
        )}
      </Grid>
    </Box>
  );
}

export default StaffWindowRightHeader;
