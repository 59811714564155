import React, { useState, useContext, Fragment, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { StaffContext } from "./staffContext/StaffContext";
import DeleteIcon from "@mui/icons-material/Delete";
import StaffWindowRight from "./StaffWindowRight";
import recycle from "../../../assets/img/deleteIcon/recycle.svg";
import MuiAlert from "@mui/material/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getStaffList } from "../../../api/console/staff/staff-management";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ConfirmModal from "./Ui/ConfirmModal";
import Loader from "../layout/Loader";
import StaffImageModal from "../Ui/StaffImageModal";
import StaffIdModal from "../Ui/StaffIdModal";
const StaffMainBlock = () => {
  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  const {
    staffAllData,
    addStaff,
    setAddStaff,
    viewStaff,
    setViewStaff,
    setMappedStaff,
    setViewReadOnly,
    getStaffRowData,
    setAllData,
    initialState,
    setDeleteStaffId,
    setEditStaff,
    setPic,
    setIdProof,
    handleOpen,
    handleCloseDeleteMOdal,
    open,
    loading,
    setSubLoading,
    handleDelete,
    setError,
    setFormErrors,
    initialFormErrors,
    editStaff,
    viewReadOnly,
  } = useContext(StaffContext);

  // console.log(staffAllData, "staffAllData ")
  // const [staffData, setStaffData] = useState([]);
  // console.log(staffData, "staffData 1st staff");

  const toastId = React.useRef(null);

  // useEffect(() => {
  //   const data = getStaffList();
  //   data.then((res) => {
  //     setStaffData(res);
  //   });
  // }, []);
  // console.log(staffData, "staffData staff");

  // const notify = () =>{
  //   alert("Delete Successful")
  // }
  //end delete
  const style = {
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    iconStyle: {
      margin: " 0 auto",
      height: "100px",
      width: "100px",
    },
    buttonsStyles: {
      display: "flex",
      // alignItems:'center',
      justifyContent: "center",
      gap: "20px",
    },
    deleteBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const columns = [
    {
      field: "profile_image",
      headerName: "Staff Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              src={`http://seahorseapi.codingo.co:8081/uploads/staffs/profile/${params.row.profile_image}`}
            />
            <p className="ml-2 mb-0">{params.row.name}</p>
          </Box>
        );
      },
      sortable: false,
      filterable: false,
    },
    // { field: "name", headerName: "Staff Name", flex: 1 },
    { field: "email", headerName: " E-mail", flex: 1 },
    {
      field: "phone",
      headerName: "Mobile Number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="mb-0 ">{params.row.phone_extension}</p>
            <p className="mb-0  word-space">{params.row.phone}</p>
          </Box>
        );
      },
    },
    {
      field: "designation",
      headerName: "Designation ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <p className="ml-2 mb-0">{params.row.staffType?.designation}</p>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <BootstrapTooltip title="Delete staff">
              <DeleteIcon onClick={(e) => handleOpen(e, params.row.staff_id)} />
            </BootstrapTooltip>
          </>
        );
      },
    },
  ];
  const handleViewClick = (e) => {
    console.log(e, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    setSubLoading(true);
    setViewStaff(true);
    setAddStaff(false);
    setViewReadOnly(true);
    setEditStaff(false);
    getStaffRowData(e.row.staff_id);
    setDeleteStaffId(e.row.staff_id);
    setPic([]);
    setIdProof([]);
    setError(false);
  };

  const handleAddStaff = () => {
    setAddStaff(true);
    setViewStaff(false);
    setEditStaff(false);
    setMappedStaff({});
    setAllData(initialState);
    setViewReadOnly(false);
    setError(false);
    setFormErrors(initialFormErrors);
  };
  //function for no data available
  const customRowsOverlay = () => {
    return (
      <GridOverlay>
        <p>No Data Available</p>
      </GridOverlay>
    );
  };
  return (
    <Fragment>
      <Box className="mainBlock">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box className="title-search">
              <Box className="box-left">
                <span className="main-header">Staff</span>
                <Button
                  variant="outlined"
                  sx={{ mb: "15px" }}
                  onClick={handleAddStaff}
                >
                  Add New
                </Button>
              </Box>
              {/* <Box
                className="box-right"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button className="btn-icon">
                  Filter <FilterAltOutlinedIcon />
                </Button>
                <Button className="btn-icon">
                  Sort <FilterListOutlinedIcon />
                </Button>
              </Box> */}
            </Box>
            <Box
              className={`dataTable-block ${addStaff && "windowChange"}
               ${viewStaff && "windowChange"}    ${
                viewStaff && !editStaff && "staffWindowFullWidth"
              }  `}
            >
              <Box
                className="data-table-sub-block"
                style={{ width: "100%", background: "#fff" }}
              >
                <DataGrid
                  components={{
                    NoRowsOverlay: customRowsOverlay,
                  }}
                  onRowClick={(e) => handleViewClick(e)}
                  rows={staffAllData}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  getRowId={(row) => row.staff_id}
                />
              </Box>
              <StaffWindowRight />
            </Box>
          </>
        )}
      </Box>
      <Modal open={open}>
        <Box sx={style.modalStyles}>
          <Avatar src={recycle} sx={style.iconStyle} />
          <Typography
            sx={style.fontStyle}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to delete ?
          </Typography>

          <Box sx={style.buttonsStyles}>
            <Button
              variant="text"
              sx={style.cancelBtn}
              onClick={handleCloseDeleteMOdal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={style.deleteBtn}
              color="error"
              onClick={(e) => handleDelete(e)}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer />
      <StaffIdModal />
      <StaffImageModal />
      <ConfirmModal />
    </Fragment>
  );
};

export default StaffMainBlock;
