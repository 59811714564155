const validate = (values,workingDays,isOnChange,field,value,indexes) => {
    let errors = {};
   
    let currdate = new Date().toLocaleDateString("fr-CA"); 

  if(!isOnChange){
    if (!values.emirates_id) 
      errors.emirates = "Emirates is required";

    if (!values.name) 
      errors.muName = "MU name is required";
    
    if (!values.contact_number) {
        errors.contact_number = "Contact is required";
      }else if(values.contact_number?.length != 9){
        errors.contact_number = "9 characters required for contact number";
      }

    if (!values.vehicle_number)
        errors.vehicle_number = "Vehicle Number is required";
  
    if(!values.services || values.services.length == 0)
      errors.services = "Service is required";
    
    if(!values.staffs ||  values.staffs.length == 0)
    errors.staff = "Staff is required";

   
    workingDays.forEach(days => {     ///CAN BE OPTIMISED
      // if(days.is_working){
        days.muWorkingHours.forEach(hour => {
          if(days.is_working){
          let start = new Date (currdate +" "+hour.start_time).getTime();
          let end = new Date(currdate +" "+ hour.end_time).getTime();
          let isError = start >= end ? "Start time or End time is invalid" : "";    
          hour.errors = isError;   
          if(isError){
           errors.workingDays = "Time Error"; 
          }
         }else{
          if(hour.errors){
            hour.errors = "";
          }
         } 
        });
      // }
    });

  }else{
    if(field == "emirates"){
      if (!value) 
      errors.emirates = "Emirates is required";
    }

   else if(field == "muName"){
      if (!value) 
        errors.muName = "MU name is required";
    }

   else if(field == "contact_number"){
      if (!value) {
          errors.contact_number = "Contact is required";
        }else if(value.length != 9){
          errors.contact_number = "9 characters required for contact number";
        }
    }

   else if(field == "vehicle_number"){
      if (!value)
          errors.vehicle_number = "Vehicle Number is required";
    }

  else if(field == "services"){
     if(value.length == 0)
     errors.services = "Service is required";
   }

  else if(field == "staff"){
     if(value.length == 0)
     errors.staff = "Staff is required";
   }

  else if(field == "workingDays"){
    let workDaysErrors = [];
     workingDays.forEach((days,dayIndx) => {     ///CAN BE OPTIMISED
        if(dayIndx == indexes.dayIndex){
          days.muWorkingHours.forEach((hour,hourIndex) => {
              if(hourIndex == indexes.hourIndex){
                let start = new Date (currdate +" "+hour.start_time).getTime();
                let end = new Date( currdate +" "+ hour.end_time).getTime();
                let isError = start >= end ? "Start time or End time is invalid" : "";    
                hour.errors = isError;   
                if(isError){
                  workDaysErrors.push(isError); 
                }
                return;
              }
          })   
          return
        }
    }); 
    if(workDaysErrors.length > 0 )errors.workingDays = "Start time or End time is invalid";
  }
  }

    return {errors,workingDays};
  };

  export {validate};