import React, { useState, useContext } from "react";
import { StaffContext } from "../staffContext/StaffContext";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import recycle from "../../../../assets/img/upload/1.svg";

const ConfirmModal = () => {
  const {
    confirmModal,
    setConfirmModal,
    formChange,
    setFormChange,
    handleConfirmShow,
    handleConfirmClose,
    setAddStaff,
    setViewStaff,
  } = useContext(StaffContext);

  const handleConfirm = () => {
    setFormChange(false);
    handleConfirmClose();
    // setConfirmModal(false)
    setAddStaff(false);
    setViewStaff(false);
    console.log("exit view mu");
  };

  //Styles
  const style = {
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    iconStyle: {
      margin: " 0 auto",
      height: "100px",
      width: "100px",
    },
    buttonsStyles: {
      display: "flex",
      // alignItems:'center',
      justifyContent: "center",
      gap: "20px",
    },
    deleteBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
  };

  return (
    <Modal open={confirmModal}>
      <Box sx={style.modalStyles}>
        <Avatar src={recycle} sx={style.iconStyle} />
        <Typography
          sx={style.fontStyle}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          <h5>Are you sure you want to exit?</h5>
        </Typography>

        <Box sx={style.buttonsStyles}>
          <Button
            variant="text"
            sx={style.cancelBtn}
            onClick={handleConfirmClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={style.deleteBtn}
            color="error"
            onClick={handleConfirm}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
