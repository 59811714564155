import React, { useState,Fragment,useContext } from"react";
// import uploadFileId from"./image/upload/add-file.svg";
// import StaffIdModal from"./StaffIdModal";
import { StaffContext } from"../staff/staffContext/StaffContext";
import "./style.css"


const ViewFile = () => {
  const {
    idModal,
    setIdModal,
    idproof,
    setIdProof,
    addProofSubmit,
    setProofSubmit,
    handleCloseIdModal,
    handleShowIdModal,
    handleIdproofRemove,
    mappedStaff,
    editStaff,
    mappedIdproof,
    setMappedIdProof,
  } = useContext(StaffContext);

    //check id extension
    const  getExtension = (filename) => {
      return filename.split(".").pop();
    }




  return (
    <div>
        {/* <div className="upload-group">
          <div className="row m-0">
            <div className="col-md-8 upload-group-col">
              <div className="input-block">
                <Select
                  options={IdOptions}
                  onChange={(e) => {
                    SetStaffForm(
                      { ...staffform, staff_id_proof_type: e.value },
                      SetFormChange(true)
                    );
                    onChangeValidate("staff_id_proof_type", e.value);
                  }}
                  onBlur={(e) =>
                    onChangeValidate(
                     "staff_id_proof_type",
                      staffform.staff_id_proof_type
                    )
                  }
                />
                {error && formErrors.staff_id_proof_type ? (
                  <span className="form-error">
                    {formErrors.staff_id_proof_type}
                  </span>
                ) : (
                 ""
                )}
              </div>
            </div>
            <div className="col-md-4 p-0">
                <button
                  type="button"
                  className="btn-image"
                  onClick={handleShowIdModal}
                  disabled={staffform.staff_id_proof_type ? false : true}
                >
                  <img src={uploadFileId} className="pdf-file" alt="" />
                  Upload
                  {staffform.staff_id_proof_type &&
                  staffform.staff_id_proof_type =="Choose..."
                    ?""
                    : staffform.staff_id_proof_type}
                </button>
            </div>
          </div>
        </div> */}
        <div className="file-upload-view">
          <div className="small-block">
            <div class="row-img row">
           {mappedIdproof &&
                  mappedIdproof.map((proof, index) => {
                    // console.log(idproof);
                    return (
           
           <div className="col-img-list" key={index}>
              <div className="img-list">
                {editStaff && <button
                  className="btn"
                  onClick={(e) => setMappedIdProof()}
                >
                  <i class="fal fa-times text-danger"></i>
                </button>}
                <div>
                  {mappedStaff && getExtension(proof?.id_proof).toLowerCase() ===
                 "pdf" ? (
                    <div className="pdf-block">
                      <span className="pdf-icon">
                        <i class="fal fa-file-pdf"></i>
                      </span>
                                <a classname="" href={`http://seahorseapi.codingo.co:8081/uploads/staffs/idproofs/${proof?.id_proof}`} target="_blank">{proof?.id_proof}</a>
                    </div>
                  ) : mappedStaff && getExtension(proof?.id_proof).toLowerCase() ===
                   "docx" ? (
                    <div className="pdf-block">
                      <span className="pdf-icon">
                        <i class="fal fa-file-word word"></i>
                      </span>
                                <a classname="" href={`http://seahorseapi.codingo.co:8081/uploads/staffs/idproofs/${proof?.id_proof}`}  target="_blank">{proof?.id_proof}</a>
                    </div>
                  ) : mappedStaff &&  getExtension(proof?.id_proof).toLowerCase() ===
                   "doc" ? (
                    <div className="pdf-block">
                      <span className="pdf-icon">
                        <i class="fal fa-word word"></i>
                      </span>
                                <a classname="" href={`http://seahorseapi.codingo.co:8081/uploads/staffs/idproofs/${proof?.id_proof}`}  target="_blank">{proof?.id_proof}</a>
                    </div>
                  ) : mappedStaff && (
                    <a className="" href={`http://seahorseapi.codingo.co:8081/uploads/staffs/idproofs/${proof?.id_proof}`}  target="_blank"><img
                    src={`http://seahorseapi.codingo.co:8081/uploads/staffs/idproofs/${proof?.id_proof}`}
                    className="img-fluid"
                    alt=""
                  /></a>
                  )}
                </div>
              </div>
            </div>
             );
            })
            }

              { idproof &&
                idproof.map((proof, index) => {
                  return (
                    <div className="col-img-list p-0">
                      <div className="img-list" key={proof}>
                        <button
                          className="btn"
                          onClick={(e) => handleIdproofRemove(e, index)}
                        >
                          <i class="fal fa-times text-danger"></i>
                        </button>
                        <div>
                          {getExtension(proof?.file.name).toLowerCase() ===
                         "pdf" ? (
                            <div className="pdf-block">
                              <span className="pdf-icon">
                                <i class="fal fa-file-pdf"></i>
                              </span>
                              <h5>{proof.file.name}</h5>
                            </div>
                          ) : getExtension(proof?.file.name).toLowerCase() ===
                           "docx" ? (
                            <div className="pdf-block">
                              <span className="pdf-icon">
                                <i class="fal fa-file-word word"></i>
                              </span>
                              <h5>{proof.file.name}</h5>
                            </div>
                          ) : getExtension(proof?.file.name).toLowerCase() ===
                           "doc" ? (
                            <div className="pdf-block">
                              <span className="pdf-icon">
                                <i class="fal fa-word word"></i>
                              </span>
                              <h5>{proof.file.name}</h5>
                            </div>
                          ) : (
                            <img
                            src={URL.createObjectURL(proof.file)}
                              className="img-fluid"
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
    </div>
  );
};

export default ViewFile;
