const validate = (values, variants, isOnChange, field, value, variantIndex) => {
  let errors = {};

  //ON SUBMIT VALIDATION
  if (!isOnChange) {
    if (!values.name) {
      errors.service_name = "Service Name is required";
    }
    if (!values.description) {
      errors.description = "Description is required";
    }
    if (!values.service_category_id) {
      errors.category = "Service category is required";
    }

    variants.forEach((variant) => {
      if (!variant.price) {
        variant.errors = "Variant Price is required";
        errors.variant = "Variant price is required";
      } else {
        variant.errors = "";
      }
    });

    //ON CHANGE VALIDATION
  } else {
    if (field == "service_name") {
      if (!value) errors.service_name = "Service Name is required";
    } else if (field == "description") {
      if (!value) {
        errors.description = "Service escription is required";
      }
    } else if (field == "category") {
      if (!value) errors.category = "Service category is required";
    } else if (field == "variant") {
      //needs to be optimised
      let variantError = [];
      variants.forEach((variant, index) => {
        if (index == variantIndex && !variant.price) {
          variant.errors = "Variant Price is required";
          variantError.push("Variant " + index + " Error");
        } else if (variant.price) {
          variant.errors = "";
        } else if (variant.errors) {
          variantError.push("Variant " + index + " Error");
        }
      });
      if (variantError.length != 0)
        errors.variant = "Variant Price is required";
    }
  }

  return { errors, variants };
};

export { validate };
