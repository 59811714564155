import React, { useContext } from "react";
import { ServiceContext } from "./serviceContext/ServiceContext";
import { Box, Grid, Typography } from "@mui/material";
import { color } from "@mui/system";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import serviceIcon from "../../../assets/img/user/technician.png";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import noImg from "../../../assets/img/user/4.png";

const ServiceView = () => {
  const { mappedService } = useContext(ServiceContext);

  const headerStyles = {
    logoutBlock: {
      background: "#ffffff",
      color: "#000",
      borderRadius: "6px",
    },
    ulBlock: {
      // list-style-type: none;
      padding: "18px",
      boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
      width: "100%",
      borderRadius: "12px",
      marginBottom: "20px",
    },
    serviceulBlock: {
      // list-style-type: none;
      padding: "18px",
      // boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
      background: "red",
      width: "100%",
      borderRadius: "12px",
      marginBottom: "20px",
    },
    typoBlock: {
      fontSize: "18px",
      paddingBottom: "0px",
      letterSpacing: "0.8px",
      fontWeight: 900,
    },
    typotypoBlock: {
      // borderBottom: "1px solid #75757529",
      fontSize: "15px",
      paddingBottom: "5px",
      letterSpacing: "0.8px",
      // display: "flex",
      // alignItems: "center"
    },
    spanBlock: {
      marginTop: "5px",
      color: "#111",
      fontWeight: "600",
      fontSize: "15px",
    },
    spanServiceBlock: {
      marginTop: "5px",
      color: "#111",
      fontWeight: "600",
      fontSize: "16px",
      marginRight: "6px",
      marginLeft: "6px",
    },
    formBlock: {
      padding: "25px 20px !important",
    },
  };
  return (
    <Box sx={headerStyles.formBlock} id="formTopBlock">
      <Grid conexttainer>
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid md={4}>
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Carousel
                    className={` ${
                      (mappedService.serviceImages.length == 1 || mappedService.serviceImages.length == 0)
                        ? "singleImageService"
                        : "multipleImageService"
                    }`}
                  >
                    {mappedService.serviceImages &&
                    mappedService.serviceImages.length < 1 ? (
                      <img
                        src={noImg}
                        className={`d-block w-100 carousel-img-blockmain `}
                      />
                
                    ) : (
                      mappedService.serviceImages.map((item, index) => {
                        return (
                          <Carousel.Item
                            interval={1000}
                            className={`carousel-class ${
                              mappedService.serviceImages.length == 1
                                ? "singleImageService"
                                : "multipleImageService"
                            }`}
                          >
                            <img
                              className="d-block w-100 carousel-img-blockmain"
                              src={`http://seahorseapi.codingo.co:8081/${item.image_url}`}
                              alt="Image One"
                            />
                            <Carousel.Caption>
                              {/* <h3>{item.service_image_id}</h3> */}
                              {/* <h3>{mappedService.serviceImages.length}</h3> */}
                            </Carousel.Caption>
                          </Carousel.Item>
                        );
                      })
                    )}
                  </Carousel>
                </Box>
              </Box>
            </Grid>
            <Grid
              className="grid-adjust"
              sx={{ padding: "0px 10px 10px 10px" }}
              item
              md={8}
            >
              <div className="row h-100">
                <Box sx={headerStyles.logoutBlock} item md={6}>
                  <Box sx={headerStyles.ulBlock} className="viewAdjust">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6" sx={headerStyles.typoBlock}>
                        {mappedService != null && mappedService.service_name}
                      </Typography>
                      <Box className="est-time">
                        <HourglassTopIcon className="time-icon" />
                        {mappedService.estimated_hours} H :
                        {mappedService.estimated_minutes} M
                      </Box>
                    </Box>
                    <p
                      style={{
                        margin: "0px 0px 0px 0px",
                        textTransform: "capitalize",
                        fontWeight: 400,
                        color: "#f9ac51",
                      }}
                    >
                      {mappedService != null && mappedService.category_name}
                    </p>
                    <p
                      style={{
                        marginTop: "15px",
                        marginBottom: "15px !important",
                      }}
                    >
                      {mappedService != null && mappedService.description}
                    </p>
                    <Box className="row mt-4">
                      {mappedService.servicePrices != null &&
                        mappedService.servicePrices &&
                        mappedService.servicePrices.map((e) => {
                          return (
                            <Box className="col-md-6 mb-3">
                              <p style={{ fontSize: "14px" }}>
                                Price for{" "}
                                <span
                                  className=""
                                  style={{ fontWeight: 900, color: "black" }}
                                >
                                  {e.vehicleVariant.variant}
                                </span>
                                <span
                                  className={`variant-price-block 
                              ${
                                e.vehicleVariant.variant == "4x4" &&
                                "PricefourFour"
                              }
                              ${
                                e.vehicleVariant.variant == "Sedan / Coupe" &&
                                "PriceSedan"
                              }

                           
                              
                              `}
                                >
                                  {Math.ceil(e.price)} <span>AED</span>
                                </span>
                              </p>
                              {/* <span style={headerStyles.spanBlock}>
                              {mappedService.staffType != null
                                ? mappedService.staffType &&
                                  mappedService.staffType.designation
                                : ""}
                            </span> */}
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
          <Box className="box-block" container></Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceView;
