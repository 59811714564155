import { useEffect, useState } from "react";
import { createContext } from "react";
import {
  getStaffList,
  getStaff,
  deleteStaff,
  createOrUpdateStaff,
} from "../../../../api/console/staff/staff-management";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Validation } from "../Ui/Validation";
export const StaffContext = createContext();

export const StaffProvider = (props) => {
  const [staffAllData, setStaffAllData] = useState([]);
  let initialState = {
    name: "",
    email: "",
    phone: "",
    phone_extension: "971",
    designation: "",
    address: "",
    gender: "",
    blood_group_id: "",
    staff_id_proof_type: "",
    emergency_details_address: "",
    emergency_details_name: "",
    emergency_details_phone: "",
    emergency_details_phone_extension: "971",
    emergency_details_relation_type: "",
    salary: "",
  };
  const [allData, setAllData] = useState(initialState);

  const [addStaff, setAddStaff] = useState(false);
  const [staffDeleteId, setDeleteStaffId] = useState("");
  const [viewStaff, setViewStaff] = useState(false);
  const [editStaff, setEditStaff] = useState(false);
  const [mappedStaff, setMappedStaff] = useState({});
  const [viewReadOnly, setViewReadOnly] = useState(false);

  //Loader states
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);

  //pic
  const [pic, setPic] = useState([]);
  const [mappedPic, setMappedPic] = useState();
  const [addonImages, setAddonImages] = useState([]);
  const [addImageSubmit, setAddImageSubmit] = useState(false);
  //Remove img
  const handleImgRemove = (e, i) => {
    let newImg = pic.filter((e, newIndex) => {
      return newIndex !== i;
    });
    setPic(newImg);
    setAddImageSubmit(false);
  };

  //Id proof
  const [idproof, setIdProof] = useState([]);
  const [mappedIdproof, setMappedIdProof] = useState([]);
  const [addonId, setAddonId] = useState([]);
  const [addProofSubmit, setProofSubmit] = useState(false);

  //Form Change State
  const [formChange, setFormChange] = useState(false);
  //Confirm Modal State
  const [confirmModal, setConfirmModal] = useState(false);
  const handleConfirmShow = () => {
    setConfirmModal(true);
  };
  const handleConfirmClose = () => {
    setConfirmModal(false);
  };

  //Error states
  let initialFormErrors = {
    name: "",
    phone: "",
    address: "",
    email: "",
    gender: "",
    designation: "",
    salary: "",
    staff_id_proof_type: "",
    emergency_details_name: "",
    emergency_details_phone: "",
    emergency_details_relation_type: "",
  };

  const [dateofBirth, setDateofbirth] = useState(dayjs());
  const [doj, setDoj] = useState(dayjs());
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  //Remove Idproof
  const handleIdproofRemove = (e, i) => {
    console.log(e, i, "test");
    let newId = idproof.filter((e, newIndex) => {
      return newIndex !== i;
    });
    setIdProof(newId);
    setProofSubmit(false);
  };

console.log(idproof, "staff_id_proof");

  const deletemappedImg = (e) => {
    setMappedPic();
    setPic();
  };
  useEffect(() => {
    getList();
  }, []);
  const getList = () => {
    setLoading(true);
    const response = getStaffList();
    response.then((res) => {
      console.log(res, "getStaffList");
      setStaffAllData(res);
      setLoading(false);
    });
  };
  const updateToast = () => {
    return editStaff ? "Updated Successfully" : "Added Successfully";
  };
  const onSuccess = () => {
    toast.success(updateToast, {
      position: "bottom-right",
      autoClose: 1000,
      toastId: "update",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  //Create or Update staff
  const handleSubmit = (e) => {
    e.preventDefault();
    //Edit Mode
    if (editStaff) {
      const addData = {
        ...allData,
        staff_id: mappedStaff?.staff_id,
      };
      console.log(doj, dateofBirth, "doj,dateofBirthdoj,dateofBirth");
      let formData = new FormData();
      // delete addData.profile_image;
      for (const e in addData) {
        if (Object.hasOwnProperty.call(addData, e)) {
          formData.append(e, addData[e]);
        }
      }
      //date of birth
      formData.append(
        "date_of_birth",
        new Date(dateofBirth).toLocaleDateString("fr-CA")
      );
      //date of joining
      formData.append(
        "date_of_joining",
        new Date(doj).toLocaleDateString("fr-CA")
      );
      //pic edit
      if (!mappedPic && pic) {
        formData.append("profile_image", pic[0]);
        console.log("pic edited succes ");
      } else if (mappedPic && !pic) {
        formData.append("profile_image", mappedPic);
        console.log("pic edited not changed ");
      } else if (!mappedPic && !pic) {
        formData.append("profile_image", "");
        console.log("empty img and map pic ");
      } else if (mappedPic === "" && pic.length === 0) {
        formData.append("profile_image", "");
      }
      //Idproof
      // let imgArray = [];
      // for (const picKey of idproof.keys()) {
      //   if (idproof.length > 0 && !idproof[picKey].service_image_id) {
      //     // formData.append(`images`, JSON.stringify([{ service_image_id: "" }]));
      //     formData.append(`images[${picKey}][image]`, idproof[picKey].file);
      //   } 
      //   else if (idproof.length > 0 && idproof[picKey].staff_proof_id) {
      //     if (editStaff) {
      //       let image_id = idproof.length > 0 ? idproof[picKey].staff_proof_id : "";
      //       imgArray.push({ staff_proof_id: image_id });
      //     }
      //   }
      // }
      // formData.append(`images`, JSON.stringify(imgArray));

      //id proof edit
      //Id proof multiple      
    //   for (const picKey of idproof.keys()) {
    //     formData.append("staff_id_proof", idproof[picKey].file);
    // }
      if (idproof?.length) {
        for (const idKey of idproof.keys()) {
          formData.append("staff_id_proof", idproof[idKey].file);
        }
      } 
      if (mappedIdproof && !idproof.length) {
          formData.append("staff_id_proof", mappedIdproof[0]?.id_proof && mappedIdproof[0]?.id_proof);
      }
      // if (mappedIdproof) {
      //   for (const idKey of mappedIdproof.keys()) {
      //     formData.append("staff_id_proof", mappedIdproof[idKey].file);
      //   }
      // }
      // for (const iterator of formData.entries()) {
      //   console.log(iterator, "formDataformDataformDataformDataformData");
      // }
      let errors = Validation(allData, null, null, false, doj, dateofBirth);
      if (Object.keys(errors).length) {
        setError(true);
        setFormErrors(errors);
        return;
      } else {
        setError(false);

        console.log(formData, "formDataformDataformDataformData");
        const data = createOrUpdateStaff(formData);
        onSuccess();
        data.then((res) => {
          // console.log(res, "new list");
          setViewStaff(false);
          setAddStaff(false);
          setEditStaff(false);
          // console.log(allData, "alldata");
          //
          // setAllData(initialState);
          setDateofbirth(dayjs(new Date()));
          setDoj(dayjs(new Date()));
          const staffData = getStaffList();
          staffData.then((res) => {
            setStaffAllData(res);
          });
        });
      }
    }
    //Add Mode
    else {
      const addData = {
        ...allData,
      };
      console.log(allData, "allDataallData4 api");
      let formData = new FormData();
      for (const e in allData) {
        if (Object.hasOwnProperty.call(allData, e)) {
          formData.append(e, allData[e]);
        }
      }
      let dob =
        dateofBirth === undefined
          ? new Date().toLocaleDateString("fr-CA")
          : new Date(dateofBirth).toLocaleDateString("fr-CA");
      let dateOfJoining =
        doj === undefined
          ? new Date().toLocaleDateString("fr-CA")
          : new Date(doj).toLocaleDateString("fr-CA");
      formData.append("date_of_birth", dob);
      formData.append("date_of_joining", dateOfJoining);
      formData.append("profile_image", pic[0]);

      //Id proof multiple      
      for (const idKey of idproof.keys()) {
          formData.append("staff_id_proof", idproof[idKey].file);
      }
      // formData.append("staff_id_proof", idproof[0]?.file);
      // for (const iterator of formData.entries()) {
      //   console.log(iterator, "formDataformDataformDataformDataformData");
      // }
      let errors = Validation(allData, null, null, false, doj, dateofBirth);
      if (Object.keys(errors).length) {
        setError(true);
        setFormErrors(errors);
        return;
      } else {
        setError(false);
        const data = createOrUpdateStaff(formData);
        data
          .then((res) => {
            setPic([]);
            setIdProof([]);
            setViewStaff(false);
            setAddStaff(false);
            setEditStaff(false);
            setDateofbirth(dayjs());
            setDoj(dayjs());
            const staffData = getStaffList();
            staffData.then((res) => {
              setStaffAllData(res);
            });
            // console.log(res, "resresresresres");
            if (!viewStaff) {
              onSuccess();
            }
          })
          .catch((err) => {
            console.log(err.message);
            if (err?.message) {
              console.log("err.message", err.message);
              toast.error(err?.message, {
                position: "bottom-right",
                autoClose: 1000,
                toastId: "error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      }
    }
  };

  //Row Click
  const getStaffRowData = (id) => {
    console.log(id, "staff map id");
    // setLoading(true);
    // setMappedStaff([]);
    const data = getStaff(id);
    data.then((res) => {
      setMappedStaff(res);
      setSubLoading(false);
      console.log(
        res,
        "res---------------------------------------------------------------------------------------"
      );
      // SetRowActive(true);
    });
  };
  // console.log(mappedStaff, "mappedStaff with id");

  //Row Delete function
  const deleteStaffData = (id) => {
    console.log(id, "staff map id");
    // setMappedStaff([]);
    const data = deleteStaff(id);
    data
      .then((res) => { })
      .catch((err) => {
        getStaffList().then((res) => {
          toast.success("Staff deleted successfully", {
            position: "bottom-right",
            autoClose: 1000,
            toastId: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setStaffAllData(res);
          setViewStaff(false);
          setAddStaff(false);
          setViewReadOnly(false);
          setEditStaff(false);
          setPic([]);
          setIdProof([]);
        });
        console.log(err, "sdffgfsdgdfgdfg");
      });
  };

  //modal trigger state
  const [picModal, setPicModal] = useState(false);
  const handleShow = () => setPicModal(true);
  const handleClose = () => setPicModal(false);

  const [idModal, setIdModal] = useState();
  const handleShowIdModal = () => setIdModal(true);
  const handleCloseIdModal = () => setIdModal(false);
  //delete modal state
  const [open, setOpen] = useState(false);

  const handleMuEdit = (newData, id) => {
    let editData = staffAllData.map((item) => {
      if (item.name === id) {
        return newData;
      }
      return item;
    });
    setStaffAllData(editData);
    console.log(editData, "editdata");
  };

  //trigger delete modal
  const handleOpen = (e, data) => {
    e.stopPropagation();
    setDeleteStaffId(data ? data : "");
    console.log(data, "delete data eeeeeeeeeeeeeeeeeeee");
    setOpen(true);
  };
  //delete modal close
  const handleCloseDeleteMOdal = () => setOpen(false);

  //Delete Confirm
  const handleDelete = (e) => {
    // e.preventDefault()
    e.stopPropagation();
    deleteStaffData(staffDeleteId);
    console.log(staffDeleteId, "id of  deleted staff");

    // if (!toast.isActive(toastId.current)) {
    //   // toastId.current = toast("I cannot be duplicated!");
    //   toastId.current = toast.success("Deleted Successfully", {
    //     position: "bottom-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // }
    setOpen(false);
  };

  let initialValues = {
    staffAllData,
    setStaffAllData,
    addStaff,
    setAddStaff,
    viewStaff,
    setViewStaff,
    editStaff,
    setEditStaff,
    mappedStaff,
    setMappedStaff,
    viewReadOnly,
    setViewReadOnly,
    handleMuEdit,
    pic,
    setPic,
    addonImages,
    setAddonImages,
    addImageSubmit,
    setAddImageSubmit,
    idproof,
    setIdProof,
    addonId,
    setAddonId,
    addProofSubmit,
    setProofSubmit,
    picModal,
    setPicModal,
    handleShow,
    handleClose,
    idModal,
    setIdModal,
    handleShowIdModal,
    handleCloseIdModal,
    handleImgRemove,
    handleIdproofRemove,
    getStaffRowData,
    deleteStaffData,
    allData,
    setAllData,
    initialState,
    staffDeleteId,
    setDeleteStaffId,
    mappedPic,
    setMappedPic,
    mappedIdproof,
    setMappedIdProof,
    deletemappedImg,
    open,
    setOpen,
    handleOpen,
    handleCloseDeleteMOdal,
    handleDelete,
    dateofBirth,
    setDateofbirth,
    doj,
    setDoj,
    error,
    setError,
    formErrors,
    setFormErrors,
    handleSubmit,
    initialFormErrors,
    loading,
    setLoading,
    subLoading,
    setSubLoading,
    initialFormErrors,
    confirmModal,
    setConfirmModal,
    formChange,
    setFormChange,
    handleConfirmShow,
    handleConfirmClose,
  };
  return (
    <StaffContext.Provider value={initialValues}>
      {props.children}
    </StaffContext.Provider>
  );
};
