import { consoleAPI } from "../../axios";

const getRequiredData = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "GET",
      url: "console/mu/get-req-data",
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

export {getRequiredData}