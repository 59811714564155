import { createContext, useEffect, useState } from "react";
import { getBookingsList } from "../../../../api/console/BookingHistory/bookinghistory-management";

export const BookingHistoryContext = createContext();

export const BookingHistoryProvider = (props) => {
  const [viewBookingHistory, setViewBookingHistory] = useState(false);
  const [bookingHistoryAllData, setBookingHistoryAllData] = useState([]);
  const [mappedBookingHistory, setMappedBookingHistory] = useState({});
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);

  const getBookingHistoryList = () => {
    const data = {
      from_date: "",
      service_date: "",
      to_date: "",
      status: "completed",
    };
    console.log(data, "status completed");
    setLoading(true);
    const response = getBookingsList(data);
    response.then((res) => {
      setBookingHistoryAllData(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    getBookingHistoryList();
  }, []);
  let initialValues = {
    viewBookingHistory,
    setViewBookingHistory,
    bookingHistoryAllData,
    setBookingHistoryAllData,
    getBookingHistoryList,
    mappedBookingHistory,
    setMappedBookingHistory,
    loading,
    setLoading,
    subLoading,
    setSubLoading,
  };
  return (
    <BookingHistoryContext.Provider value={initialValues}>
      {props.children}
    </BookingHistoryContext.Provider>
  );
};
