import { Box } from "@mui/material";
import React from "react";
import { useContext } from "react";
// import Loading from "../Ui/loading";
import { StaffContext } from "./staffContext/StaffContext";
import StaffForm from "./StaffForm";
import StaffWindowRightHeader from "./StaffWindowRightHeader";
import SubLoader from "../layout/SubLoader";

function StaffWindowRight() {
  const { addStaff, setAddStaff, subLoading } = useContext(StaffContext);
  const styles = {
    windowWidth: {
      width: "calc(100% - 250px)",
    },
  };
  return (
    <>
      <Box className="windowRightBlock" sx={styles.windowWidth}>
        {subLoading ? (
          <SubLoader />
        ) : (
          <>
            <StaffWindowRightHeader setAddStaff={setAddStaff} />
            <StaffForm setAddStaff={setAddStaff} />
          </>
        )}
      </Box>
    </>
  );
}

export default StaffWindowRight;
