import {
  Avatar,
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Button,
  FormHelperText
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { StaffContext } from "./staffContext/StaffContext";
import CollectionsTwoToneIcon from "@mui/icons-material/CollectionsTwoTone";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import imageUpload from "../../../assets/img/photoupload/photos.svg";
import ViewFile from "../Ui/ViewFile";
import StaffImageModal from "../Ui/StaffImageModal";
import "../Ui/style.css";
import { Validation } from "./Ui/Validation";

// import configData from "../../../config.json";
import { ToastContainer, toast } from "react-toastify";
import noImg from "../../../assets/img/user/3.png";
import StaffView from "./StaffView";

const StaffForm = () => {
  const {
    addStaff,
    viewStaff,
    editStaff,
    mappedStaff,
    viewReadOnly,
    handleShowIdModal,
    handleShow,
    pic,
    setPic,
    addImageSubmit,
    handleImgRemove,
    allData,
    setAllData,
    initialState,
    mappedPic,
    setMappedPic,
    setMappedIdProof,
    deletemappedImg,
    dateofBirth,
    setDateofbirth,
    doj,
    setDoj,
    error,
    setError,
    formErrors,
    setFormErrors,
    handleSubmit,
    initialFormErrors,
    formChange,
    setFormChange,
    mappedIdproof,
  } = useContext(StaffContext);
  const styles = {
    formStyle: {
      padding: "15px",
      maxHeight: "calc(100vh - 215px)",
      overflow: "auto",
    },
    formRowStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: "15px",
    },
    iconLabelStyles: {
      display: "flex",
      gap: "20px",
    },
    fieldError: {
      width: "75%",
    },
    phoneExtension: {
      width: "80%",
    },
    selectField: {
      width: "80%",
    },
    textField: {
      width: "100%",
    },
    phoneExtension: {
      // width: "fit-content",
      // padding: "8px",
      // margin: "0 auto",
      // paddingRight: '45px !important'
    },
    typographyStyles: {
      backgroundColor: "#b3e5fc",
      mb: "10px",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: "0",
      borderRadius: "4px 4px 0 0",
      display: "inline-block",
      padding: "5px 15px",
      textTransform: "uppercase",
      fontWeight: 410,
    },
    spanStyles: {
      background: "#b3e5fc",
    },
    cardStyles: {
      width: "150px",
      margin: 0,
      background: "#fafafa",
      border: "1px dotted #e0e0e0",
      // borderRadius: "3px!important",
      color: "#01579b",
      fontSize: "14px!important",
      height: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  const [age, setAge] = React.useState('');
  const [openDob, setOpenDob] = useState(false);
  const [openDoj, setOpenDoj] = useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleOpenDob = () =>{
    if(!openDob){
      setOpenDob(true)
    }
    else  setOpenDob(false)
    
  }
  const handleOpenDoj = () =>{
    if(!openDoj){
      setOpenDoj(true)
    }
    else  setOpenDoj(false)
  }

  // console.log(allData, "allData After submit");
  useEffect(() => {
    setError(false);
    setFormErrors(initialFormErrors);
    if (viewStaff && mappedStaff && mappedStaff.length !== 0) {
      let {
        date_of_birth,
        date_of_joining,
        staffEmergencyDetail,
        staffIdProof,
        profile_image,
        ...rest
      } = mappedStaff;
      console.log(rest, "rest After useefffect");

      // console.log(staffIdProof && staffIdProof[0].id_proof_type,"agjsvdgbasnvbdvasdgvjabsjdgbjasgbdjgbj");
      // setEditStaffId(mappedStaff?.staff_id);
      setAllData({
        ...rest,
        // designation:rest.designation,
        staff_id_proof_type:
          staffIdProof && staffIdProof[0]?.idProofType?.value,
        emergency_details_address:
          staffEmergencyDetail?.address == "undefined"
            ? ""
            : staffEmergencyDetail?.address,
        emergency_details_name: staffEmergencyDetail?.name,
        emergency_details_phone: staffEmergencyDetail?.phone,
        emergency_details_phone_extension:
          staffEmergencyDetail?.phone_extension,
        emergency_details_relation_type:
          staffEmergencyDetail?.relationType?.value,
      });
      setDateofbirth(date_of_birth);
      setDoj(date_of_joining);
      setMappedPic(
        profile_image && profile_image == "undefined" ? "" : profile_image
      );
      setMappedIdProof(staffIdProof && staffIdProof);
      setPic([]);
    } else if (addStaff) {
      setAllData(initialState);
      setPic([]);
      setMappedPic();
      setMappedIdProof([]);
      setDateofbirth(dayjs());
      setDoj(dayjs());
    }
  }, [mappedStaff, viewStaff, addStaff]);
  console.log(allData, "..allData...........outside-----");
  console.log(mappedIdproof, "mappedIdproof mappedIdproof--------");

  //On Change Validate fn
  const onChangeValidate = (field, value) => {
    const errors = Validation(allData, field, value, true, null, null);

    if (Object.keys(errors).length != 0) {
      setError(true);
      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        setFormErrors(updatedErrorObject);
      } else {
        setFormErrors(errors);
      }
    } else {
      if (Object.keys(formErrors).length != 0) {
        delete formErrors[field];
        const updatedErrorObject = { ...formErrors };
        setFormErrors(updatedErrorObject);
      } else {
        setError(false);
        // SetFormErrors(errorFields);
      }
    }
  };

  console.log(allData, "allData Staff data test--------");
  //   const imageUploadClick = () => {
  //   };

  let len = parseInt(allData?.phone?.length);
  let pLength = parseInt(allData?.emergency_details_phone?.length);
  const regString = /^([^0-9$%]*)$/;

  const handleKeyText = (e) => {
    var key = e.key;
    // var regex = /^[A-Za-z\s]*$/;
    var regex = /^[a-zA-Z0-9!@,#\$%\^\&*\s)\(+=._-]+$/g;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
    if (len > 9) {
      e.preventDefault();
    }
  };

  const handlePhoneKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
    if (pLength > 9) {
      e.preventDefault();
    }
  };
  const handleSalaryKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  //prevent paste text
  const onPaste = (e, type) => {
    const paste = e.clipboardData.getData("text/plain");
    // var regex = /[0-9]|\./;
    const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
    if (type == "phone") {
      if (!pattern.test(paste)) {
        e.preventDefault();
      } else if (pattern.test(paste) && !allData.phone.length) {
        setAllData({ ...allData, phone: paste });
      }
    } else if (type == "emergencyphone") {
      if (!pattern.test(paste)) {
        e.preventDefault();
      } else if (
        pattern.test(paste) &&
        !allData.emergency_details_phone.length
      ) {
        setAllData({ ...allData, emergency_details_phone: paste });
      }
    }
    // (paste.match(/[-\.]/)) return

    // setValue(paste)
  };

  // useEffect(() => {
  //   var element = document.getElementById("staff-form-box");
  //   element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }, [mappedStaff]);
  return (
    <Box
      sx={styles.formStyle}
      className={`form-block  ${viewReadOnly && "view-form-block"}`}
    >
      {viewReadOnly ? <StaffView /> :
      <>
      <form onSubmit={handleSubmit}>
        <Box id="staff-form-box" sx={styles.formRowStyle} className="form-box">
          <Box sx={styles.iconLabelStyles}>
            <CollectionsTwoToneIcon />
            <label> Photo </label>
          </Box>
          <Box sx={{ width: "75%", display: "inline-flex" }}>
            <div className="small-block">
              {mappedPic ? (
                <div className="col-md-4 col-img-list">
                  <div className="img-list">
                    {editStaff && (
                      <button className="btn" onClick={deletemappedImg}>
                        <i class="fal fa-times"></i>
                      </button>
                    )}
                    <img
                      src={mappedPic ? `http://seahorseapi.codingo.co:8081/uploads/staffs/profile/${mappedPic}` :noImg}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                !mappedPic &&
                !editStaff &&
                !addStaff && (
                  <div className="col-md-4 col-img-list">
                    <div className="img-list">
                      {/* {editStaff && (
                      <button className="btn" onClick={(e) => setMappedPic()}>
                        <i class="fal fa-times"></i>
                      </button>
                    )} */}
                      <img src={noImg} className="img-fluid" alt="" />
                    </div>
                  </div>
                )
              )}
              <Box></Box>
            </div>
            <div className="small-block">
              {addImageSubmit && pic
                ? pic.map((photo, index) => {
                    // console.log(pic);
                    return (
                      <div className="col-md-4 col-img-list">
                        <div className="img-list" key={photo}>
                          <button
                            className="btn"
                            type="button"
                            onClick={(e) => handleImgRemove(e, index, "add")}
                          >
                            <i class="fal fa-times"></i>
                          </button>
                          <img
                            src={URL.createObjectURL(photo)}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        {/* <h2>Vishnu Shankar</h2> */}
                      </div>
                    );
                  })
                : ""}
            </div>
            {(editStaff && !mappedPic && !pic?.length) ||
            (addStaff && !pic?.length) ? (
              <Card
                sx={styles.cardStyles}
                onClick={handleShow}
                className="image-sizing"
              >
                <Box>
                  <Avatar
                    sx={{ width: "65px" }}
                    src={imageUpload}
                    alt="imageUpload"
                  />
                  <div className="text-center upload-txt"> Upload</div>
                </Box>
              </Card>
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Grid id="sub-form-box" container spacing={2}>
          {/* Name start */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                id="outlined-adornment-amount"
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                label={<span className="required">Name {!viewReadOnly && <sup> *</sup>}</span>}
                placeholder="Name"
                autoComplete="off"
                value={allData.name}
                onKeyPress={handleKeyText}
                onChange={(e) => {
                  setAllData({ ...allData, name: e.target.value });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.name !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }

                  onChangeValidate("name", e.target.value);
                }}
                onBlur={(e) => onChangeValidate("name", e.target.value)}
              />
              {error && formErrors.name ? (
                <span className="form-error text-danger">
                  {formErrors.name}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* email start */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                id="outlined-adornment-amount"
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                placeholder="Email"
                label={<span className="required">Email </span>}

                autoComplete="off"
                value={allData.email}
                onChange={(e) => {
                  setAllData({ ...allData, email: e.target.value });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.email !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                  onChangeValidate("email", e.target.value);
                }}
                // onBlur={(e) => onChangeValidate("email", e.target.value)}
              />
              {error && formErrors.email ? (
                <span className="form-error text-danger">
                  {formErrors.email}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* phonenumber extension*/}
          <Grid
            item
            xs={2}
            md={3}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth  sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="phone-extension">Phone Extension</InputLabel>
              <Select
                labelId="phone-extension"
                id="extension-select"
                label="Phone Extension"
                InputProps={{
                  autoWidth: true,
                  disabled: Boolean(viewReadOnly) ? true : false,
                }}
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                defaultValue={allData.phone_extension}
                value={allData.phone_extension}
                onChange={(e) => {
                  setAllData({ ...allData, phone_extension: e.target.value });
                }}
              >
                <MenuItem value={+971}>+971</MenuItem>
              </Select>
              {error && formErrors.phone_extension ? (
                <span className="form-error text-danger">
                  {formErrors.phone_extension}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* phonenumber */}
          <Grid
            item
            xs={10}
            md={9}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                id="outlined-adornment-amount"
                autoComplete="off"
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                placeholder="Phone Number"
                label={<span className="required">Phone Number {!viewReadOnly && <sup> *</sup>}</span>}
                onKeyPress={handleKeyPress}
                onPaste={(e) => onPaste(e, "phone")}
                value={allData.phone}
                onChange={(e) => {
                  setAllData({ ...allData, phone: e.target.value });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.phone !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) => onChangeValidate("phone", e.target.value)}
              />
              {error && formErrors.phone ? (
                <span className="form-error text-danger">
                  {formErrors.phone}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Address */}
          <Grid
            item
            xs={6}
            md={12}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                id="outlined-adornment-amount"
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                placeholder="Address"
                label={<span className="required">Address{!viewReadOnly && <sup> *</sup>}</span>}
                autoComplete="off"
                multiline
                rows={4}
                value={allData?.address}
                onChange={(e) => {
                  setAllData({ ...allData, address: e.target.value });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.address !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) => onChangeValidate("address", e.target.value)}
              />
              {error && formErrors.address ? (
                <span className="form-error text-danger">
                  {formErrors.address}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Gender */}
          <Grid
            item
            xs={6}
            md={3}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel ><span className="required">Gender{!viewReadOnly && <sup> *</sup>}</span></InputLabel>
              <Select
                // inputProps={{
                //   disabled: Boolean(viewReadOnly) ? true : false,
                // }}
                className="formField"
                value={allData.gender}
                label="Gender"
                onChange={(e) => {
                  setAllData({ ...allData, gender: e.target.value });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.gender !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) =>
                  onChangeValidate(
                    "gender",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.gender
                  )
                }
                onClose={(e) =>
                  onChangeValidate(
                    "gender",
                    e.target.innerText ? e.target.innerText : allData.gender
                  )
                }
              >
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
                <MenuItem value={3}>Other</MenuItem>
              </Select>
              {error && formErrors.gender ? (
                <span className="form-error text-danger">
                  {formErrors.gender}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Date of Birth */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputProps={{
                    // readOnly: Boolean(viewReadOnly),
                    disabled: Boolean(viewReadOnly),
                  }}
                  label={<span className="required">Date of Birth{!viewReadOnly && <sup> *</sup>}</span>}
                  inputFormat="DD/MM/YYYY"
                  open={openDob}
                  value={dateofBirth}
                  onChange={(newDate) => {
                    console.log(newDate, ".........newDate");
                    console.log(dateofBirth, "........dateofBirth.newDate");
                    setDateofbirth(newDate);
                    if (addStaff) {
                      if (dateofBirth !== newDate) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    } else {
                      if (editStaff) {
                        if (dateofBirth !== mappedStaff.date_of_birth) {
                          setFormChange(true);
                        } else {
                          setFormChange(formChange);
                        }
                      }
                    }
                  }}
                  onClose={handleOpenDob}
                  renderInput={(params) => (
                    <TextField sx={styles.textField} {...params} onClick={handleOpenDob} className="formField" />
                  )}
                  views={["day", "month", "year"]}
                  showDaysOutsideCurrentMonth
                />
              </LocalizationProvider>
              {error && formErrors.dob ? (
                <span className="form-error text-danger">{formErrors.dob}</span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* BloodGroup */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="select-label" >
              <span className="required">Blood Group</span>
              </InputLabel>
              <Select
              labelId="select-label"
              id="select-label-id"
                className="formField"
                value={allData.blood_group_id}
                onChange={(e) => {
                  setAllData({ ...allData, blood_group_id: e.target.value });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.blood_group_id !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
              >
                <MenuItem value={1}>A+</MenuItem>
                <MenuItem value={2}>A-</MenuItem>
                <MenuItem value={3}>B+</MenuItem>
                <MenuItem value={4}>B-</MenuItem>
                <MenuItem value={5}>O+</MenuItem>
                <MenuItem value={6}>O-</MenuItem>
                <MenuItem value={7}>AB+</MenuItem>
                <MenuItem value={8}>AB-</MenuItem>
              </Select>
              {/* {error && formErrors.gender ? (
                <span className="form-error text-danger">
                  {formErrors.gender}
                </span>
              ) : (
                ""
              )} */}
            </FormControl>
          </Grid>
          {/* Designation */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel >
              <span className="required">Designation{!viewReadOnly && <sup> *</sup>}</span>
              </InputLabel>
              <Select
                className="formField"
                value={allData.designation}
                onChange={(e) => {
                  setAllData({ ...allData, designation: e.target.value });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.designation !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) =>
                  onChangeValidate(
                    "designation",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.designation
                  )
                }
                onClose={(e) =>
                  onChangeValidate(
                    "designation",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.designation
                  )
                }
              >
                <MenuItem value={1}>Staff</MenuItem>
                <MenuItem value={2}>Manager</MenuItem>
                {/* <MenuItem value={3}>Other</MenuItem> */}
              </Select>
              {error && formErrors.designation ? (
                <span className="form-error text-danger">
                  {formErrors.designation}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Salary */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                id="outlined-adornment-amount"
                autoComplete="off"
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                placeholder="Salary"
                label={<span className="required">Salary{!viewReadOnly && <sup> *</sup>}</span>}
                value={allData.salary}
                onKeyPress={handleSalaryKeyPress}
                onChange={(e) => {
                  setAllData({ ...allData, salary: e.target.value });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.salary !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) => onChangeValidate("salary", e.target.value)}
              />
              {error && formErrors.salary ? (
                <span className="form-error text-danger">
                  {formErrors.salary}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Date of Joining */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={<span className="required">Date of Joining{!viewReadOnly && <sup> *</sup>}</span>}
                  inputFormat="DD/MM/YYYY"
                  open={openDoj}
                  onClose={handleOpenDoj}
                  value={doj}
                  onChange={(newDate) => {
                    setDoj(newDate);
                    if (addStaff) {
                      if (doj !== newDate) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    } else {
                      if (editStaff) {
                        if (doj !== mappedStaff.date_of_joining) {
                          setFormChange(true);
                        } else {
                          setFormChange(formChange);
                        }
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={styles.textField}
                      {...params}
                      onClick={handleOpenDoj}
                      className="formField"
                    />
                  )}
                  views={["day", "month", "year"]}
                  showDaysOutsideCurrentMonth
                />
              </LocalizationProvider>
              {error && formErrors.dob ? (
                <span className="form-error text-danger">{formErrors.dob}</span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        {/* idproof  */}
        <Box sx={{ mb: "40px", mt: "30px" }} className="p-8">
          <Typography className="block-heading">
            <span className="heading">ID PROOF</span>
          </Typography>
        </Box>
        <Grid id="sub-form-box" container spacing={2}>
          {/* Name start */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel><span className="required">ID Proof{!viewReadOnly && <sup> *</sup>}</span></InputLabel>
              <Select
                className="formField"
                value={allData.staff_id_proof_type}
                onChange={(e) => {
                  setAllData({
                    ...allData,
                    staff_id_proof_type: e.target.value,
                  });
                  if (addStaff) {
                    if (e.target.value) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.staff_id_proof_type !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) =>
                  onChangeValidate(
                    "staff_id_proof_type",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.staff_id_proof_type
                  )
                }
                onClose={(e) =>
                  onChangeValidate(
                    "staff_id_proof_type",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.staff_id_proof_type
                  )
                }
              >
                <MenuItem value={1}>Passport</MenuItem>
                <MenuItem value={2}>Visa</MenuItem>
                <MenuItem value={3}>Emirates ID</MenuItem>
                <MenuItem value={4}>Labour ID</MenuItem>
                <MenuItem value={5}>Labour Contract</MenuItem>
                <MenuItem value={6}>Other</MenuItem>
              </Select>
              {error && formErrors.staff_id_proof_type ? (
                <span className="form-error text-danger">
                  {formErrors.staff_id_proof_type}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              {editStaff || addStaff ? (
                <Box sx={{ marginLeft: "10px", width: "calc(100% - 10px)" }}>
                  <Card
                    sx={{
                      margin: 0,
                      border: "1px solid #c4c4c4 !important",
                      boxShadow: "none !important",
                      borderRadius: "3px!important",
                      color: "#01579b",
                      fontSize: "14px!important",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      maxHeight: "40px",
                    }}
                    onClick={handleShowIdModal}
                  >
                    <Box>
                      {/* <Avatar
                          sx={{ width: "65px",maxHeight:"40px" }}
                          src={imageUpload}
                          alt="imageUpload"
                        /> */}
                      <div className="text-center">Upload</div>
                    </Box>
                  </Card>
                </Box>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            md={12}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <Box sx={{ marginLeft: "10px" }}>
                <ViewFile />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* EMERGENCY CONTACT dETAILS  */}

        <Box sx={{ mb: "40px", mt: "30px" }} className="p-8">
          <Typography className="block-heading">
            <span className="heading">EMERGENCY CONTACT DETAILS</span>
          </Typography>
        </Box>

        <Grid id="sub-form-box" container spacing={2}>
          {/* Name start */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField 
                id="outlined-adornment-amount"
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                placeholder="Name"
                label={<span className="required">Name{!viewReadOnly && <sup> *</sup>}</span>}
                autoComplete="off"
                value={
                  allData.staffEmergencyDetail?.emergency_details_name
                    ? allData.staffEmergencyDetail.emergency_details_name
                    : allData.emergency_details_name
                }
                onKeyPress={handleKeyText}
                onChange={(e) => {
                  setAllData({
                    ...allData,
                    emergency_details_name: e.target.value,
                  });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.emergency_details_name !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                  onChangeValidate("emergency_details_name", e.target.value);
                }}
                onBlur={(e) =>
                  onChangeValidate("emergency_details_name", e.target.value)
                }
              />
              {error && formErrors.emergency_details_name ? (
                <span className="form-error text-danger">
                  {formErrors.emergency_details_name}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Relation */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel><span className="required">Relation{!viewReadOnly && <sup> *</sup>}</span></InputLabel>
              <Select
                className="formField"
                value={allData.emergency_details_relation_type}
                onChange={(e) => {
                  setAllData({
                    ...allData,
                    emergency_details_relation_type: e.target.value,
                  });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (
                        allData.emergency_details_relation_type !==
                        e.target.value
                      ) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) =>
                  onChangeValidate(
                    "emergency_details_relation_type",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.emergency_details_relation_type
                  )
                }
                onClose={(e) =>
                  onChangeValidate(
                    "emergency_details_relation_type",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.emergency_details_relation_type
                  )
                }
              >
                <MenuItem value={"1"}>Parent</MenuItem>
                <MenuItem value={"2"}>Family</MenuItem>
                <MenuItem value={"3"}>Sibling</MenuItem>
                <MenuItem value={"4"}>Other</MenuItem>
              </Select>
              {error && formErrors.emergency_details_relation_type ? (
                <span className="form-error text-danger">
                  {formErrors.emergency_details_relation_type}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* phonenumber extension*/}
          <Grid
            item
            xs={2}
            md={3}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth  sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="emergency-extension">Phone Extension</InputLabel>
              <Select
                labelId="emergency-extension"
                id="emergency-extension-select"
                label="Phone Extension"
                value={allData.emergency_details_phone_extension}
                InputProps={{
                  autoWidth: true,
                  disabled: Boolean(viewReadOnly) ? true : false,
                }}
                onChange={(e) => {
                  setAllData({
                    ...allData,
                    emergency_details_phone_extension: e.target.value,
                  });
                  if (addStaff) {
                    if (e.target.value) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (
                        allData.emergency_details_phone_extension !==
                        e.target.value
                      ) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) =>
                  onChangeValidate(
                    "emergency_details_phone_extension",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.emergency_details_phone_extension
                  )
                }
                onClose={(e) =>
                  onChangeValidate(
                    "emergency_details_phone_extension",
                    e.target.innerText
                      ? e.target.innerText
                      : allData.emergency_details_phone_extension
                  )
                }
              >
                <MenuItem value={+971}>+971</MenuItem>
              </Select> 
              {error && formErrors.emergency_details_phone_extension ? (
                <span className="form-error text-danger">
                  {formErrors.emergency_details_phone_extension}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* phonenumber */}
          <Grid
            item
            xs={10}
            md={9}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField 
                id="outlined-adornment-amount"
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                placeholder="Phone Number"
                label={<span className="required">Phone Number{!viewReadOnly && <sup> *</sup>}</span>}
                autoComplete="off"
                onKeyPress={handlePhoneKeyPress}
                onPaste={(e) => onPaste(e, "emergencyphone")}
                value={allData.emergency_details_phone}
                onChange={(e) => {
                  setAllData({
                    ...allData,
                    emergency_details_phone: e.target.value,
                  });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (allData.emergency_details_phone !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                }}
                onBlur={(e) =>
                  onChangeValidate("emergency_details_phone", e.target.value)
                }
              />
              {error && formErrors.emergency_details_phone ? (
                <span className="form-error text-danger">
                  {formErrors.emergency_details_phone}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Emergency Address start */}
          <Grid
            item
            xs={6}
            md={12}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField 
                id="outlined-adornment-amount"
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                placeholder="Address"
                label={<span className="required">Address{!viewReadOnly && <sup> *</sup>}</span>}
                autoComplete="off"
                value={
                  allData.staffEmergencyDetail?.emergency_details_address
                    ? allData.staffEmergencyDetail.emergency_details_address
                    : allData.emergency_details_address
                }
                onKeyPress={handleKeyText}
                onChange={(e) => {
                  setAllData({
                    ...allData,
                    emergency_details_address: e.target.value,
                  });
                  if (addStaff) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editStaff) {
                      if (
                        allData.emergency_details_address !== e.target.value
                      ) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                  onChangeValidate("emergency_details_address", e.target.value);
                }}
                onBlur={(e) =>
                  onChangeValidate("emergency_details_address", e.target.value)
                }
              />
              {error && formErrors.emergency_details_address ? (
                <span className="form-error text-danger">
                  {formErrors.emergency_details_address}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "right", mt: "5px" }}>
          <>
            {!viewReadOnly && (
              <>
                <Button
                  type="submit"
                  // className="action-btn"
                  variant="contained"
                >
                  {" "}
                  Save
                </Button>
              </>
            )}
          </>
          </Box>
      </form>
      </>}
   
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default StaffForm;
