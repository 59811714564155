import { Box } from "@mui/material";
import React, { useContext } from "react";
import { BookingContext } from "./bookingContext/BookingContext";
import BookingForm from "./BookingForm";
import BookingWindowHeader from "./BookingWindowHeader";
import SubLoader from "../layout/SubLoader";
const BookingWidowRight = () => {
  const { setBookingAllData, subLoading } = useContext(BookingContext);
  const styles = {
    windowWidth: {
      width: "calc(100% - 250px)",
    },
  };
  return (
    <Box className="windowRightBlock" sx={styles.windowWidth}>
      {subLoading ? (
        <SubLoader />
      ) : (
        <>
          <BookingWindowHeader setBookingAllData={setBookingAllData} />
          <BookingForm setBookingAllData={setBookingAllData} />
        </>
      )}
    </Box>
  );
};

export default BookingWidowRight;
