import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import { BookingContext } from "../bookingContext/BookingContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import serviceIcon from "../../../../assets/img/user/technician.png";
import paymentCancel from "../../../../assets/img/user/1.gif";
import CommentIcon from "@mui/icons-material/Comment";
const BookingsView = () => {
  const headerStyles = {
    logoutBlock: {
      background: "#ffffff",
      color: "#000",
      borderRadius: "6px",
    },

    ulBlock: {
      // list-style-type: none;
      padding: "18px",
      boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
      width: "100%",
      borderRadius: "12px",
      marginBottom: "20px",
    },
    serviceulBlock: {
      // list-style-type: none;
      padding: "18px",
      // boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
      background: "red",
      width: "100%",
      borderRadius: "12px",
      marginBottom: "20px",
    },
    typoBlock: {
      borderBottom: "1px solid #75757529",
      fontSize: "15px",
      paddingBottom: "5px",
      letterSpacing: "0.8px",
    },
    typotypoBlock: {
      // borderBottom: "1px solid #75757529",
      fontSize: "15px",
      paddingBottom: "5px",
      letterSpacing: "0.8px",
      // display: "flex",
      // alignItems: "center"
    },
    spanBlock: {
      marginTop: "5px",
      color: "#111",
      fontWeight: "600",
      fontSize: "15px",
    },
    spanServiceBlock: {
      marginTop: "5px",
      color: "#111",
      fontWeight: "600",
      fontSize: "16px",
      marginRight: "6px",
      marginLeft: "6px",
    },
  };
  const { mappedBooking } = useContext(BookingContext);
  const statusData = [1, 2, 3, 4, 5];
  let YyMmDd = mappedBooking.service_date != null && mappedBooking.service_date;
  const [year, month, day] = YyMmDd.toString().split("-");

  // console.log(new Date().getDate().toLocaleDateString('en-CA'));
  return (
    <Box className="form-block" id="formTopBlock">
      <Grid container>
        <Grid item md={12}>
          <Grid container spacing={2}>
            <Grid item sx={{ padding: "10px 10px" }} md={8}>
              {/* Order Details */}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Box className="muBox">
                    <Typography
                      variant="h6"
                      sx={headerStyles.typotypoBlock}
                      className={`bookingTypeBlock 
                    ${mappedBooking.status === 1 && "BookingsOpen"}
                    ${mappedBooking.status === 2 && "BookingsAssigned"}
                    ${mappedBooking.status === 3 && "BookingsInProgress"}
                    ${mappedBooking.status === 4 && "BookingsCompleted"}
                    ${mappedBooking.status === 5 && "BookingsRescheduled"}
                    ${
                      mappedBooking.status === 6 &&
                      mappedBooking.cancelInfo.cancel_type === "admin" &&
                      "BookingsRejected"
                    }
                    ${
                      mappedBooking.status === 6 &&
                      mappedBooking.cancelInfo.cancel_type === "customer" &&
                      "BookingsCancelled"
                    }
                    `}
                    >
                      {statusData.includes(mappedBooking.status) ? (
                        <CheckCircleIcon />
                      ) : (
                        <CancelIcon />
                      )}

                      <span className="headerConfirm">
                        {mappedBooking.status === 1 && "Booking Open"}
                        {mappedBooking.status === 2 && "Booking Assigned"}
                        {mappedBooking.status === 3 && "Booking InProgress"}
                        {mappedBooking.status === 4 && "Booking Completed"}
                        {mappedBooking.status === 5 && "Booking Rescheduled"}
                        {mappedBooking.status === 6 &&
                          mappedBooking.cancelInfo.cancel_type === "admin" &&
                          "Booking Rejected"}
                        {mappedBooking.status === 6 &&
                          mappedBooking.cancelInfo.cancel_type === "customer" &&
                          "Booking Cancelled"}
                      </span>
                    </Typography>
                    <Grid
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box className="muRoundBlock">MU</Box>
                      <span className="muSpan">
                        {mappedBooking.mobileUnit != null
                          ? mappedBooking.mobileUnit.name &&
                            mappedBooking.mobileUnit.name
                          : "No MU"}
                      </span>
                    </Grid>
                  </Box>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  >
                    {/* {mappedBooking &&
                        mappedBooking.customer.customer_name} */}
                  </p>
                  <Box className="row">
                    <Box className="col-12 mb-3 serviceIconBlock">
                      <img src={serviceIcon} alt="" />
                      <Box className="ml-4">
                        <p className="service-para-block">
                          This Booking is Scheduled for
                          <span style={headerStyles.spanServiceBlock}>
                            {/* {new Date(mappedBooking.service_date).toLocaleDateString('nl-NL')} */}
                            {/* {mappedBooking.service_date && mappedBooking.service_date} */}
                            {`${day}-${month}-${year}`}
                          </span>
                          between
                          <span style={headerStyles.spanServiceBlock}>
                            {/* {mappedBooking.service_time} */}
                            {mappedBooking.timeslot}
                          </span>
                          in the
                          <span style={headerStyles.spanServiceBlock}>
                            {/* {mappedBooking.service_time} */}
                            {mappedBooking.service_time}
                          </span>
                          slot
                        </p>
                        {/* <span style={headerStyles.spanBlock}>
                          <CommentIcon /> 
                          {mappedBooking.comments}
                        </span> */}
                      </Box>
                    </Box>
                    {/* Vehicle Details  */}
                    <Box
                      className="col-12 mb-3 mt-4"
                      sx={headerStyles.logoutBlock}
                      item
                      md={6}
                    >
                      <Box sx={headerStyles.logoutBlock}>
                        <Typography variant="h6" sx={headerStyles.typoBlock}>
                          Vehicle Details
                        </Typography>
                        <p
                          style={{
                            fontSize: "1rem",
                            letterSpacing: "0.8px",
                            margin: "15px 0 5px 0",
                            textTransform: "capitalize",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                        </p>
                        <Box className="row">
                          <Box className="col-md-6 mb-3">
                            <p>Manufacturer :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedBooking.customerVehicle != null
                                ? mappedBooking.customerVehicle.vehicle
                                    .manufacturer &&
                                  mappedBooking.customerVehicle.vehicle
                                    .manufacturer
                                : ""}
                            </span>
                          </Box>
                          <Box className="col-md-6 mb-3">
                            <p>Model :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedBooking.customerVehicle != null
                                ? mappedBooking.customerVehicle.vehicle.model &&
                                  mappedBooking.customerVehicle.vehicle.model
                                : ""}
                            </span>
                          </Box>
                          <Box className="col-md-6 mb-3">
                            <p>Color :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedBooking != null
                                ? mappedBooking.color && mappedBooking.color
                                : ""}
                            </span>
                          </Box>
                          <Box className="col-md-6 mb-3">
                            <p>Variant :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedBooking.customerVehicle != null
                                ? mappedBooking.customerVehicle.vehicle
                                    .variant &&
                                  mappedBooking.customerVehicle.vehicle.variant
                                : ""}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* Service Details  */}
                    <Box
                      className="col-12 mb-3 mt-2"
                      sx={headerStyles.logoutBlock}
                      item
                      md={6}
                    >
                      <Box sx={headerStyles.logoutBlock}>
                        <Typography variant="h6" sx={headerStyles.typoBlock}>
                          Service Details
                        </Typography>
                        <p
                          style={{
                            fontSize: "1rem",
                            letterSpacing: "0.8px",
                            margin: "15px 0 5px 0",
                            textTransform: "capitalize",
                            fontWeight: "500",
                          }}
                        >
                          {" "}
                        </p>
                        <Box className="row">
                          <Box className="col-md-6 mb-3">
                            <p>Service Name :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedBooking.orderServices != null
                                ? mappedBooking.orderServices[0].service_name &&
                                  mappedBooking.orderServices[0].service_name
                                : ""}
                            </span>
                          </Box>
                          <Box className="col-md-6 mb-3">
                            <p>Service Price :</p>
                            <span style={headerStyles.spanBlock}>
                              {mappedBooking.orderServices != null
                                ? mappedBooking.orderServices[0].price &&
                                  mappedBooking.orderServices[0].price
                                : ""}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* <Box className="col-md-6 mb-3">
                      <p>Contact Number :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBooking.customer != null
                          ? mappedBooking.customer.phone_number &&
                            mappedBooking.customer.phone_number
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      <p>Date :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBooking.service_date}
                      </span>
                    </Box> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid sx={{ padding: "10px 10px" }} item md={4}>
              {/* Customer Details*/}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Typography variant="h6" sx={headerStyles.typoBlock}>
                    Customer Details
                  </Typography>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  ></p>
                  <Box className="row">
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Customer Name :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBooking.customer != null
                          ? mappedBooking.customer.customer_name &&
                            mappedBooking.customer.customer_name
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      {/* <p>Total Amount :</p> */}
                      <p>Phone Number :</p>
                      <span style={headerStyles.spanBlock}>
                        <span className="word-space-right">
                          {mappedBooking.customer != null
                            ? mappedBooking.customer.phone_extension &&
                              mappedBooking.customer.phone_extension
                            : ""}
                        </span>
                        {mappedBooking.customer != null
                          ? mappedBooking.customer.phone_number &&
                            mappedBooking.customer.phone_number
                          : ""}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* Cancel Details*/}
              {mappedBooking.cancelInfo == null ? (
                ""
              ) : (
                <Box sx={headerStyles.logoutBlock} item md={6}>
                  <Box sx={headerStyles.ulBlock}>
                    <Typography variant="h6" sx={headerStyles.typoBlock}>
                      Cancel Details
                    </Typography>
                    <p
                      style={{
                        fontSize: "1rem",
                        letterSpacing: "0.8px",
                        margin: "15px 0 5px 0",
                        textTransform: "capitalize",
                        fontWeight: "500",
                      }}
                    ></p>
                    <Box className="row">
                      <Box className="col-md-6 mb-3">
                        {/* <p>Total Amount :</p> */}
                        <p>Cancelled by :</p>
                        <span style={headerStyles.spanBlock}>
                          {mappedBooking.cancelInfo != null
                            ? mappedBooking.cancelInfo.cancel_type &&
                              mappedBooking.cancelInfo.cancel_type
                                .charAt(0)
                                .toUpperCase() +
                                mappedBooking.cancelInfo.cancel_type.slice(1)
                            : ""}
                        </span>
                      </Box>
                      <Box className="col-md-6 mb-3">
                        {/* <p>Total Amount :</p> */}
                        <p>Reason:</p>
                        <span style={headerStyles.spanBlock}>
                          {mappedBooking.cancelInfo != null
                            ? mappedBooking.cancelInfo.reason &&
                              mappedBooking.cancelInfo.reason
                            : ""}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

              {/* Payment Details */}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Typography variant="h6" sx={headerStyles.typoBlock}>
                    Payment Summary
                  </Typography>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  ></p>
                  <Box className="row">
                    <Box className="col-12 mb-3">
                      {mappedBooking.orderPayments &&
                      (mappedBooking.orderPayments.length < 1 ||
                        (mappedBooking.orderPayments.length > 0 &&
                          mappedBooking.orderPayments[0].paid_amount ===
                            null)) ? (
                        <>
                          <Box
                            className="paymentCancelBlock"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              mt: "12px",
                            }}
                          >
                            <img src={paymentCancel} alt="" />

                            <span className="noPayment"> No Payments Made</span>
                          </Box>
                        </>
                      ) : (
                        <>
                          {mappedBooking.orderPayments &&
                            mappedBooking.orderPayments.map((e) => {
                              return (
                                <>
                                  <Box className="col-md-6 mb-3">
                                    <span style={headerStyles.spanBlock}>
                                      {e.paid_amount}
                                    </span>
                                  </Box>
                                  <Box className="col-md-6 mb-3">
                                    <span style={headerStyles.spanBlock}>
                                      {e.payment_type}
                                    </span>
                                  </Box>
                                </>
                              );
                            })}
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="box-block" container></Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingsView;
