import { Box, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { BookingHistoryContext } from "./bookinghistoryContext/BookingHistoryContext";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { DataGrid, GridOverlay, GridToolbar } from "@mui/x-data-grid";
import {
  getActivityData,
  getMappedBookingHistory,
} from "../../../api/console/BookingHistory/bookinghistory-management";
import BookingHistoryWindowRight from "./BookingHistoryWindowRight";
import Loader from "../layout/Loader";
const BookingHistoryMainBlock = () => {
  const {
    viewBookingHistory,
    setViewBookingHistory,
    bookingHistoryAllData,
    setBookingHistoryAllData,
    mappedBookingHistory,
    setMappedBookingHistory,
    loading,
    setLoading,
    setSubLoading,
  } = useContext(BookingHistoryContext);

  let lettersA_E = ["A", "B", "C", "D", "E"];
  let lettersF_J = ["F", "G", "H", "I", "J"];
  let lettersK_O = ["K", "L", "M", "N", "O"];
  let lettersP_T = ["P", "Q", "R", "S", "T"];
  let lettersU_Z = ["U", "V", "W", "X", "Y", "Z"];

  const [activityData, setActivityData] = useState({});
  const columns = [
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        let alphabetClass;
        const CustomerName = params.row.customer.customer_name;

        const fullWord = CustomerName.split(" ");
        console.log(fullWord);

        const firstWord = fullWord;
        const secondWord = fullWord[1];
        let firstLetter = CustomerName.charAt(0).toUpperCase();
        if (lettersA_E.includes(firstLetter)) {
          alphabetClass = "lettersA_E";
        } else if (lettersF_J.includes(firstLetter)) {
          alphabetClass = "lettersF_J";
        } else if (lettersK_O.includes(firstLetter)) {
          alphabetClass = "lettersK_O";
        } else if (lettersP_T.includes(firstLetter)) {
          alphabetClass = "lettersP_T";
        } else if (lettersU_Z.includes(firstLetter)) {
          alphabetClass = "lettersU_Z";
        }
        return (
          <>
            <Box className={`fstLetter ${alphabetClass}`}>
              <p>{firstLetter}</p>
            </Box>
            {/* {CustomerName} */}
            {firstWord.shift()}
            <Box className="word-space"> {secondWord}</Box>
          </>
        );
      },
    },
    {
      field: "Phone",
      headerName: "Mobile Number",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.customer.phone_extension} &nbsp;
            {params.row.customer.phone_number}
          </>
        );
      },
    },
    // {
    //   field: "actions",
    //   headerName: "Estimated time",
    //   width: 400,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.estimated_hours}h : {params.row.estimated_minutes}m
    //       </>
    //     );
    //   },
    // },
    {
      field: "Vehicle Number",
      headerName: "Vehicle Number",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.customerVehicle.vehicle_registration_number}</>;
      },
    },
    {
      field: "Service",
      headerName: "Service ",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.orderServices[0].service_name}</>;
      },
    },
    {
      field: "Amount",
      headerName: "Amount ",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.payable_amount}</>;
      },
    },
    {
      field: "mobileUnit",
      headerName: "Mobile Unit",
      width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.mobileUnit != null
              ? params.row.mobileUnit.name && params.row.mobileUnit.name
              : "MU Not Assigned"}
          </>
        );
      },
    },

    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.status == 1 && <Chip label="Open" color="success" />}
    //         {params.row.status == 2 && (
    //           <Chip label="Assigned" color="primary" />
    //         )}
    //         {params.row.status == 3 && (
    //           <Chip label="In Progress" color="secondary" />
    //         )}
    //         {params.row.status == 4 && (
    //           <Chip label="Completed" color="success" />
    //         )}
    //         {params.row.status == 5 && (
    //           <Chip label="Rescheduled" color="primary" />
    //         )}
    //         {params.row.status == 6 && (
    //           <Chip label="Cancelled" color="danger" />
    //         )}
    //         {/* {params.row.status === 2 && (
    //           <Chip label="Engaged" color="warning" />
    //         )} */}
    //       </>
    //     );
    //   },
    // },
  ];
  const handleViewClick = (e) => {
    const data = {
      order_id: e.order_id,
    };
    setSubLoading(true);

    const response = getMappedBookingHistory(data);
    response.then((res) => {
      setMappedBookingHistory(res);
      setViewBookingHistory(true);
      const responseActivity = getActivityData(data);
      responseActivity.then((resActivity) => {
        setMappedBookingHistory((mappedBookingHistory) => ({
          ...mappedBookingHistory,
          ...resActivity,
        }));
        setSubLoading(false);
      });
    });
  };
  const customRowsOverlay = () => {
    return (
      <GridOverlay>
        <p>No Data Available</p>
      </GridOverlay>
    );
  };

  return (
    <Box className="mainBlock">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box className="title-search">
            <Box className="box-left">
              <span className="main-header">Booking History</span>
              {/* <Button
            variant="outlined"
            sx={{ mb: "15px" }}
            // onClick={handleAdddService}
          >
            Add New
          </Button> */}
            </Box>
            {/* <Box
              className="box-right"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Button className="btn-icon">
                Filter <FilterAltOutlinedIcon />
              </Button>
              <Button className="btn-icon">
                Sort <FilterListOutlinedIcon />
              </Button>
            </Box> */}
          </Box>
          <Box
            className={`dataTable-block ${
              viewBookingHistory && "windowChange"
            } ${viewBookingHistory && "windowFullWidthChange"} `}
          >
            <Box
              className="data-table-sub-block"
              style={{ width: "100%", background: "#fff" }}
            >
              <DataGrid
                components={{
                  NoRowsOverlay: customRowsOverlay,
                }}
                onRowClick={(e) => handleViewClick(e.row)}
                rows={bookingHistoryAllData}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.order_id}
                // components={{ Toolbar : GridToolbar}}
              />
            </Box>
            <BookingHistoryWindowRight />
          </Box>
        </>
      )}
    </Box>
  );
};

export default BookingHistoryMainBlock;
