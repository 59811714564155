import React, { useContext } from "react";
import { BookingHistoryContext } from "./bookinghistoryContext/BookingHistoryContext";
import { Box, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const BookingHistoryWindowHeader = () => {
  const {
    viewBookingHistory,
    setViewBookingHistory,
    bookingHistoryAllData,
    setBookingHistoryAllData,
    getBookingHistoryList,
    mappedBookingHistory,
    setMappedBookingHistory,
  } = useContext(BookingHistoryContext);
  const styles = {
    addHeaderStyle: {
      display: "flex",
      alignItems: "Center",
      justifyContent: "space-between",
      padding: "9px 15px",
      borderBottom: "1px solid #ff000014",
    },
    text: {
      padding: "10px",
      marginLeft: "15px",
      borderRadius: "5px",
      // backgroundColor:'lightblue',
    },
    btn: {
      marginLeft: "20px",
      borderRadius: "5px",
      border: "none",
    },
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const handleBookingExit = () => {
    setViewBookingHistory(false);
  };
  // const editBookings = () => {
  //   SetEditBooking(true);
  //   SetAddBooking(false);
  // }

  return (
    <Box sx={styles.addHeaderStyle} className="headerBookingHistory">
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <BootstrapTooltip title="Back to listing" placement="left">
          <KeyboardBackspaceIcon onClick={handleBookingExit} />
        </BootstrapTooltip>
        <h6 className="name-header" style={styles.text}>
          {viewBookingHistory &&
            `${mappedBookingHistory &&
            mappedBookingHistory.customer.customer_name
            }`}
        </h6>
      </Box>
      <Grid
        sx={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className="muRoundBlock">MU</Box>
        <span className="muSpan">
          {mappedBookingHistory.mobileUnit != null
            ? mappedBookingHistory.mobileUnit.name &&
            mappedBookingHistory.mobileUnit.name
            : ""}
        </span>
      </Grid>
    </Box>
  );
};

export default BookingHistoryWindowHeader;
