import { Box } from "@mui/system";
import React, { useContext } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useForm } from "react-hook-form";
import { MobileUnitContext } from "../context/MobileUnitContext";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Button, Grid, Modal, Typography } from "@mui/material";
import recycle from "../../../../assets/img/deleteIcon/recycle.svg";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const MobileUnitWindowRightHeader = (props) => {
  const {
    muDatas,
    setMUDatas,
    mappedMobileUnitData,
    setMappedMobileUnitData,
    setAddMobileUnit,
    addMobileUnit,
    viewMobileUnit,
    setViewMobileUnit,
    editMobileUnit,
    setEditMobileUnit, setViewReadOnly,
    viewReadOnly,
    deleteMobileUnitHandle

  } = useContext(MobileUnitContext);

  const [open, setOpen] = useState(false);
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const styles = {
    addHeaderStyle: {
      display: "flex",
      alignItems: "Center",
      justifyContent: "space-between",
      padding: "15px",
      borderBottom: "1px solid #ff000014",
    },
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "#ffffff",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    iconStyle: {
      margin: " 0 auto",
      height: "100px",
      width: "100px",
    },
  };
  const handleMuExit = () => {
    setAddMobileUnit(false);
    setViewMobileUnit(false);
    setEditMobileUnit(false);
    setMappedMobileUnitData({});
  };
  const editMU = () => {
    setEditMobileUnit(true);
    setViewReadOnly(false);
    setAddMobileUnit(false);
    console.log("hai");
  };

  const handleServiceDelete = (e, id) => {
    setOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setOpen(false);
  };

  const handleDelete = (e, id) => {
    setOpen(false);
    const onSuccess = () => {
      toast.success("Removed Successfully", {
        position: "bottom-right",
      });
    };
    const onFailure = () => {
      toast.error("Remove Failed", {
        position: "bottom-right",
      });
    };
    deleteMobileUnitHandle(id, { onSuccess, onFailure });
  };

  const style = {
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    iconStyle: {
      margin: " 0 auto",
      height: "100px",
      width: "100px",
    },
    buttonsStyles: {
      display: "flex",
      // alignItems:'center',
      justifyContent: "center",
      gap: "20px",
    },
    deleteBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
  };
  // const styles = {
  //   addHeaderStyle: {
  //     display: "flex",
  //     alignItems: "Center",
  //     justifyContent: "space-between",
  //     padding: "9px 15px",
  //     minHeight: "55px",
  //     borderBottom: "1px solid #ff000014",
  //   },
  // };
  return (
    <Box sx={styles.addHeaderStyle}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
         <BootstrapTooltip title="Back to listing" placement="left">
        <KeyboardBackspaceIcon onClick={handleMuExit} />
        </BootstrapTooltip>
        <h6 className="name-header">
          {editMobileUnit
            ? `${mappedMobileUnitData.name}`
            : viewMobileUnit
              ? `${mappedMobileUnitData.name}`
              : "Add Mobile Unit"}
        </h6>
      </Box>


      <Grid>
        <>
          {viewReadOnly && (
            <button
              sx={{ ml: "100px" }}
              onClick={(e) =>
                handleServiceDelete(
                  e,
                  mappedMobileUnitData && mappedMobileUnitData.mobile_unit_id
                )
              }
              className="action-btn"
            >
               <BootstrapTooltip title="Delete mobile unit ">
              <DeleteIcon

              />
              </BootstrapTooltip>
            </button>
          )}
        </>
        {viewReadOnly && (
          <button
            sx={{ ml: "100px" }}
            onClick={editMU}
            className="action-btn"
          >
            <BootstrapTooltip title="Edit mobile unit ">
            <EditIcon sx={{ color: "#4caf50" }} />
            </BootstrapTooltip>
          </button>

          // <button sx={{ ml: "100px" }} onClick={editServiceDetails}>
          //   <EditIcon />
          // </button>
        )}
      </Grid>
      <Modal open={open}>
        <Box sx={styles.modalStyles}>
          <Avatar src={recycle} sx={styles.iconStyle} />
          <Typography
            sx={style.fontStyle}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to delete ?
          </Typography>

          <Box sx={style.buttonsStyles}>
            <Button
              variant="text"
              sx={style.cancelBtn}
              onClick={handleCloseDeleteModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={style.deleteBtn}
              color="error"
              onClick={(e) =>
                handleDelete(e, mappedMobileUnitData && mappedMobileUnitData.mobile_unit_id)
              }
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MobileUnitWindowRightHeader;
