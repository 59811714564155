import { consoleAPI } from "../../axios";
const getCustomerList = async (page , limit) => {
    const consoleInst = consoleAPI();
    try {
      const res = await consoleInst({
        method: "GET",
        url: `console/customer/list?page=${page}&limit=${limit}`,
      });
      if (res.success) {
        return res.data;
      }
      return Promise.reject(res);
    } catch (err) {
      // Handle the error here
      console.error("Error fetching customer list:", err);
      throw err; // Re-throw the error for the caller to handle
    }
  };

  export { getCustomerList };