import { Box } from "@mui/system";
import React, { useState, useContext } from "react";
import { BookingContext } from "./bookingContext/BookingContext";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  OutlinedInput,
  Grid,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEffect } from "react";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

import Autocomplete from "@mui/material/Autocomplete";
import {
  getPreRequisiteData,
  getModelData,
  getServiceList,
  createBooking,
  getVehicleNums,
  getCustomer,
  getMuList,
} from "../../../api/console/Booking/booking-management";
import { Validation } from "./Ui/Validation";
import axios from "axios";
import BookingsView from "./Ui/BookingsView";

const BookingForm = () => {
  const {
    SetAddBooking,
    viewBooking,
    setViewBooking,
    editBooking,
    SetEditBooking,
    mappedBooking,
    allData,
    setAllData,
    initialState,
    getBooking,
    vechileNumber,
    setVechileNumber,
    viewReadOnly,
    setSubLoading,
    serviceStartTime,
    setServiceStartTime,
    serviceEndTime,
    setServiceEndTime,
  } = useContext(BookingContext);

  const styles = {
    formStyle: {
      padding: "15px",
      maxHeight: "calc(100vh - 215px)",
      overflow: "auto",
    },
    formRowStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: "15px",
      padding: "15px",
    },
    iconLabelStyles: {
      display: "flex",
      gap: "20px",
    },
    cardcash: {
      display: "flex",
      margin: "0 auto",
    },
    fieldError: {
      width: "75%",
    },
    textField: {
      width: "100%",
    },
    btnStyle: {
      color: "#FF8F00",
      fontSize: "12px",
      fontWeight: "500",
      outline: "none",
      backgroundColor: "#fff",
      border: "none",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    buttonsStyles: {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
    },
    addBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    typography: {
      backgroundColor: "lightblue",
      fontSize: "22px",
      fontWeight: "500",
      borderRadius: "5px",
      textAlign: "center",
    },
  };

  let initialFormErrors = {
    name: "",
    phone: "",
    address: "",
    email: "",
    serviceId: "",
  };

  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const [serviceDate, setServiceDate] = useState(dayjs(new Date()));
  // const [serviceStartTime, setServiceStartTime] = useState(dayjs(new Date()));
  // const [serviceEndTime, setServiceEndTime] = useState(dayjs(new Date()));

  const [manufacturers, setManufacturers] = useState([{}]);
  const [variants, setVariants] = useState([]);
  const [models, setModels] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [muListData, setMuListData] = useState([{}]);
  const [openSDate, setOpenSDate] = useState(false);

  let grandTotal =
    (allData.actual_amount ? parseInt(allData.actual_amount) : "") +
    (allData.Additional_Charges ? parseInt(allData.Additional_Charges) : "") -
    (allData.Additional_Discounts
      ? parseInt(allData.Additional_Discounts)
      : "");

  // const handleCancel = () => {
  //   SetAddBooking(!addBooking);
  // };
  const handleOpenServicedate = () => {
    if (!openSDate) {
      setOpenSDate(true);
    } else setOpenSDate(false);
  };
  useEffect(() => {
    const response = getPreRequisiteData();
    response.then((res) => {
      console.log(res, "getPreRequisiteData");
      setManufacturers(res.manufacturers);
      setVariants(res.vehicleVariants);
    });
  }, []);
  useEffect(() => {
    const response = getMuList();
    response.then((res) => {
      console.log(res, "getMuList");
      setMuListData(res);
    });
  }, []);

  useEffect(() => {
    if (viewReadOnly) {
      setError(false);
      setFormErrors();
    }
  }, [viewReadOnly]);
  const handleSubmit = (e) => {
    e.preventDefault();
    // setSubLoading(true);
    if (editBooking) {
      const data = {
        ...allData,
        phone_extension: allData.phone_extension.toString(),
        services: allData.orderServices.map((item) => ({
          ...item,
          order_service_id: null,
          price: allData.actual_amount,
        })),
        service_time:
          allData.service_time == 1
            ? "Morning"
            : allData.service_time == 2
            ? "After Noon"
            : allData.service_time == 3
            ? "Evening"
            : "Night",

        customer_vehicle_id: mappedBooking.customerVehicle.customer_vehicle_id,
        customer_id: mappedBooking.customer.customer_id,
        order_unique_id: mappedBooking.order_unique_id,
        order_id: mappedBooking.order_id,
        order_payment_id: mappedBooking.orderPayments[0].order_payment_id
          ? mappedBooking.orderPayments[0].order_payment_id
          : "",
        paid_amount: grandTotal.toString(),
        payment_type: "upi",
        payment_status: 1,
        actual_amount: grandTotal.toString(),
        payable_amount: grandTotal.toString(),
        service_date: new Date(serviceDate).toLocaleDateString("fr-CA"),
        timeslot:
          new Date(serviceStartTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }) +
          " - " +
          new Date(serviceEndTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
      };

      let errors = Validation(data, allData?.service_id && allData.service_id);
      if (Object.keys(errors).length) {
        setError(true);
        setFormErrors(errors);
        return;
      } else {
        setError(false);
        const response = createBooking(data);
        response.then((res) => {
          getBooking();
        });
        // setSubLoading(false);
        setViewBooking(false);
        SetAddBooking(false);
        setAllData(initialState);
        toast.success("Booking updated successfully", {
          position: "bottom-right",
          autoClose: 1000,
          toastId: "error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      console.log(data, "data cherckbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");
      console.log(
        "END",
        new Date(serviceEndTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    } else {
      const data = {
        ...allData,
        paid_amount: 0,
        payment_type: "",
        payment_status: 0,
        actual_amount: parseInt(grandTotal),
        payable_amount: parseInt(grandTotal),
        service_date: new Date(serviceDate).toLocaleDateString("fr-CA"),
        service_time:
          allData.service_time == 1
            ? "Morning"
            : allData.service_time == 2
            ? "After Noon"
            : allData.service_time == 3
            ? "Evening"
            : "Night",
        timeslot:
          new Date(serviceStartTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }) +
          " - " +
          new Date(serviceEndTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
      };
      console.log(data, "data cherckbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");
      console.log(
        "START",
        new Date(serviceStartTime).toLocaleTimeString("it-IT") +
          "-" +
          new Date(serviceEndTime).toLocaleTimeString("it-IT")
      );
      console.log(
        "END",
        new Date(serviceEndTime).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
      // console.log(timeslot, "timeslot timeslot");

      let errors = Validation(
        data,
        allData?.service_id && allData.service_id,
        false
      );
      if (Object.keys(errors).length) {
        setError(true);
        setFormErrors(errors);
        return;
      } else {
        setError(false);
        const response = createBooking(data);
        response.then((res) => {
          getBooking();
        });
      }
      setViewBooking(false);
      SetAddBooking(false);
      SetEditBooking(false);
      setAllData(initialState);
      toast.success("Booking created successfully", {
        position: "bottom-right",
        autoClose: 1000,
        toastId: "error",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // setSubLoading(false);
    }
  };
  const onChangeValidate = (field, value) => {
    console.log(field, "field", value, "value", "inside onchangevalidation ");

    const validateResponse = Validation(
      allData,
      allData?.service_id && allData.service_id,
      true,
      field,
      value
      // variantIndex
    );

    // if (
    //   field == "variant" &&
    //   Object.keys(validateResponse.errors).includes("variant")
    // ) {
    //   setVehicleVariantMain(validateResponse.variants);
    // }

    if (Object.keys(validateResponse).length != 0) {
      setError(true);

      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = {
          ...formErrors,
          ...validateResponse,
        };
        setFormErrors(updatedErrorObject);
      } else {
        setFormErrors(validateResponse);
      }
    } else if (Object.keys(formErrors).length != 0) {
      delete formErrors[field];
      const updatedErrorObject = { ...formErrors };
      setFormErrors(updatedErrorObject);
    } else {
      setError(false);
      // SetFormErrors(errorFields);
    }
  };
  useEffect(() => {
    if (Object.keys(mappedBooking).length !== 0) {
      let {
        service_date,
        timeslot,
        service_time,
        orderPayments,
        customer,
        customerVehicle,
        ...rest
      } = mappedBooking;
      setAllData((preAllData) => ({
        ...rest,
        customer_name: customer.customer_name,
        phone_number: customer.phone_number,
        phone_extension: customer.phone_extension,
        vehicle_registration_number:
          customerVehicle.vehicle_registration_number,
        vehicle_manufacturer: customerVehicle.vehicle.manufacturer_id,
        vehicle_id: customerVehicle.vehicle.vehicle_id,
        color: mappedBooking.color,
        service_time:
          mappedBooking.service_time == "Morning"
            ? 1
            : mappedBooking.service_time == "After Noon"
            ? 2
            : mappedBooking.service_time == "Evening"
            ? 3
            : 4,
        Vehicle_Variants: customerVehicle.vehicle.vehicle_variant_id,
        service_id: mappedBooking.orderServices[0].service_id,
      }));
      const modelKey = {
        vehicle_manufacturer_id: customerVehicle.vehicle.manufacturer_id,
        vehicle_variant_id: customerVehicle.vehicle.vehicle_variant_id,
      };
      modelArray(modelKey);
      const serviceKey = {
        vehicle_variant_id: customerVehicle.vehicle.vehicle_variant_id,
      };
      serviceArray(serviceKey);
      setServiceDate(dayjs(service_date));
      mappedVehicle(customerVehicle.vehicle_registration_number);
      const timeFormatStart =
        mappedBooking.timeslot && mappedBooking.timeslot.substring(0, 8);
      const timeFormatEnd =
        mappedBooking.timeslot && mappedBooking.timeslot.substring(11, 19);
      setServiceStartTime(
        dayjs(mappedBooking.service_date + " " + timeFormatStart)
      );
      setServiceEndTime(
        dayjs(mappedBooking.service_date + " " + timeFormatEnd)
      );
    }
  }, [mappedBooking, setAllData]);

  // console.log(mappedBooking.timeslot.length , "xbcjufueh")
  const mappedVehicle = (e) => {
    const formData = {
      vehicle_number: e,
    };
    const response = getVehicleNums(formData);
    response.then((res) => {
      setVechileNumber(res);
    });
  };

  const searchVehicle = (e, value) => {
    const formData = {
      vehicle_number: e.target.value,
    };
    setAllData({
      ...allData,
      customer_name: "",
      phone_number: "",
      vehicle_manufacturer: "",
      vehicle_id: "",
      color: "",
      Vehicle_Variants: "",
      vehicle_registration_number: e.target.value,
    });
    const response = getVehicleNums(formData);
    response.then((resVehicle) => {
      setVechileNumber(resVehicle);
    });
  };

  const searchVehicleClick = (e, value) => {
    const formData = {
      vehicle_number: value.vehicle_registration_number,
    };
    if (vechileNumber.length && value.vehicle_registration_number !== "") {
      const responseCustomer = getCustomer(formData);
      responseCustomer.then((res) => {
        setAllData({
          ...allData,
          customer_name: res.customer.customer_name,
          phone_number: res.customer.phone_number,
          vehicle_manufacturer: res.vehicle.manufacturer_id,
          vehicle_id: res.vehicle.vehicle_id,
          color: res.color,
          // phone_extension: res.customer.phone_extension,
          Vehicle_Variants: res.vehicle.vehicle_variant_id,
          vehicle_registration_number: e.target.innerHTML,
        });
        const modelKey = {
          vehicle_manufacturer_id: res.vehicle.manufacturer_id,
          vehicle_variant_id: res.vehicle.vehicle_variant_id,
        };
        modelArray(modelKey);
        const serviceKey = {
          vehicle_variant_id: res.vehicle.vehicle_variant_id,
        };
        serviceArray(serviceKey);
      });
    }
    setVechileNumber([]);
  };

  const variantHandler = (variant_id) => {
    const variantData =
      variants &&
      variants.filter((item) => {
        if (item.vehicle_variant_id === variant_id) {
          return item;
        }
      });
    setAllData({
      ...allData,
      Vehicle_Variants: variantData[0].variant,
    });
    const modelKey = {
      vehicle_manufacturer_id: allData.vehicle_manufacturer,
      vehicle_variant_id: variant_id,
    };
    modelArray(modelKey);
    const serviceKey = {
      vehicle_variant_id: variant_id,
    };
    serviceArray(serviceKey);
  };
  const ManufacturerHandler = (value) => {
    if (value && allData?.Vehicle_Variants) {
      setAllData({
        ...allData,
        vehicle_manufacturer: value,
        Vehicle_Variants: "",
        vehicle_id: "",
      });
    } else {
      setAllData({
        ...allData,
        vehicle_manufacturer: value,
      });
    }
  };
  const modelArray = (data) => {
    const response = getModelData(data);
    response.then((res) => {
      setModels(res);
    });
  };

  const serviceArray = (data) => {
    const serviceResponse = getServiceList(data);
    serviceResponse.then((res) => {
      console.log(res, "serviceResponse");
      setServiceList(res);
    });
  };

  const modelHandler = (id) => {
    const colorData =
      models &&
      models.filter((item) => {
        if (item.vehicle_id === id) {
          return item;
        }
      });
    setAllData({
      ...allData,
      vehicle_id: id,
      // Color: colorData[0].color,
    });
  };

  const serviceHandler = (id) => {
    const priceData =
      serviceList &&
      serviceList.filter((item) => {
        if (item.service_id === id) {
          return item;
        }
      });
    setAllData({
      ...allData,
      service_id: id,
      services: priceData,
      orderServices: priceData,
      actual_amount: priceData[0].price,
    });
  };

  let len = parseInt(allData?.phone_number?.length);

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
    if (len > 9) {
      e.preventDefault();
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const handleKeyText = (e) => {
    var key = e.key;
    var regex = /^[A-Za-z\s]*$/;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  // console.log(mappedBooking.created_at ,"created_at");
  // const cancel_type = mappedBooking.cancelInfo.cancel_type;
  // const capsCancelType = cancel_type;
  // console.log(serviceStartTime, "serviceStartTime");
  // console.log(serviceEndTime, "serviceEndTime");
  const [dateHide, setdateHide] = useState(false);
  // const removePointer = document.getElementById("remove-pointer");
  const dateTrigger = () => {
    setdateHide(true);
    if (editBooking) {
      setdateHide(false);
    }
  };
  //prevent paste text
  const onPaste = (e, type) => {
    const paste = e.clipboardData.getData("text/plain");
    // var regex = /[0-9]|\./;
    const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
    if (type == "customer_name") {
      if (!pattern.test(paste)) {
        e.preventDefault();
      } else if (pattern.test(paste) && !allData.customer_name.length) {
        setAllData({ ...allData, customer_name: paste });
      }
    } 
    else if (type == "phone_number") {
      if (!pattern.test(paste)) {
        e.preventDefault();
      } else if (pattern.test(paste) && !allData.phone_number.length) {
        setAllData({ ...allData, phone_number: paste });
      }
    }
    else if (type == "color") {
      if (!pattern.test(paste)) {
        e.preventDefault();
      } else if (pattern.test(paste) && !allData.color.length) {
        setAllData({ ...allData, color: paste });
      }
    }
    // (paste.match(/[-\.]/)) return

    // setValue(paste)
  };

  return (
    <>
      {viewReadOnly ? (
        <BookingsView />
      ) : (
        <form
          onSubmit={handleSubmit}
          className={`form-block  ${viewReadOnly && "view-form-block"}`}
        >
          <Grid id="sub-form-box" container spacing={2}>
            {/*Vehicle  Number start */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                {mappedBooking && Object.keys(mappedBooking).length ? (
                  <TextField
                    sx={styles.textField}
                    placeholder="Vehicle Number"
                    label={
                      <span className="required">
                        Vehicle Number {!viewReadOnly && <sup> *</sup>}
                      </span>
                    }
                    inputProps={{
                      readOnly: Boolean(viewReadOnly) ? true : false,
                    }}
                    value={allData.vehicle_registration_number}
                  />
                ) : (
                  <Autocomplete
                    freeSolo={true}
                    sx={styles.textField}
                    clearIcon={false}
                    options={vechileNumber}
                    autoSelect={false}
                    onInputChange={(event, value) =>
                      searchVehicle(event, value)
                    }
                    onChange={(event, value) =>
                      searchVehicleClick(event, value)
                    }
                    getOptionLabel={(option) =>
                      option?.vehicle_registration_number.toString()
                    }
                    inputProps={{
                      readOnly: Boolean(viewReadOnly) ? true : false,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span className="required">
                            Vehicle Number {!viewReadOnly && <sup> *</sup>}
                          </span>
                        }
                        placeholder="eg: 7910-TNJ"
                      />
                    )}
                  />
                )}
                {error && formErrors.vehicle_registration_number ? (
                  <span className="form-error text-danger">
                    {formErrors.vehicle_registration_number}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            {/* Customer Name */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="outlined-adornment-amount"
                  autoComplete="off"
                  inputProps={{
                    readOnly: Boolean(viewReadOnly) ? true : false,
                  }}
                  sx={styles.textField}
                  onPaste={(e) => onPaste(e, "customer_name")}
                  placeholder="Customer Name"
                  label={
                    <span className="required">
                      Customer Name {!viewReadOnly && <sup> *</sup>}
                    </span>
                  }
                  value={allData.customer_name}
                  className="formField"
                  onBlur={(e) =>
                    onChangeValidate("customer_name", e.target.value)
                  }
                  onChange={(e) => {
                    setAllData({ ...allData, customer_name: e.target.value });
                    onChangeValidate("customer_name", e.target.value);
                  }}
                  onKeyPress={handleKeyText}
                />
                {error && formErrors.customer_name ? (
                  <span className="form-error text-danger">
                    {formErrors.customer_name}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            {/* phonenumber extension*/}
            <Grid
              item
              xs={2}
              md={2}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="phone-extension">Phone Extension</InputLabel>
                <Select
                  labelId="phone-extension"
                  id="extension-select"
                  label="Phone Extension"
                  InputProps={{
                    disabled: Boolean(viewReadOnly) ? true : false,
                  }}
                  value={allData.phone_extension}
                  autoWidth
                  onChange={(e) =>
                    setAllData({ ...allData, phone_extension: e.target.value })
                  }
                >
                  <MenuItem value={+971}>+971</MenuItem>
                </Select>
                {error && formErrors.phone_extension ? (
                  <span className="form-error text-danger">
                    {formErrors.phone_extension}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            {/* phonenumber */}
            <Grid
              item
              xs={10}
              md={10}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="outlined-adornment-amount"
                  autoComplete="off"
                  inputProps={{
                    readOnly: Boolean(viewReadOnly) ? true : false,
                  }}
                  sx={styles.textField}
                  placeholder="Phone Number"
                  label={
                    <span className="required">
                      Phone Number {!viewReadOnly && <sup> *</sup>}
                    </span>
                  }
                  onPaste={(e) => onPaste(e, "phone_number")}
                  onKeyPress={handleKeyPress}
                  value={allData.phone_number}
                  onBlur={(e) =>
                    onChangeValidate("phone_number", e.target.value)
                  }
                  onChange={(e) => {
                    setAllData({ ...allData, phone_number: e.target.value });
                    onChangeValidate("phone_number", e.target.value);
                  }}
                />
                {error && formErrors.phone_number ? (
                  <span className="form-error text-danger">
                    {formErrors.phone_number}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
          {/* Cancel Bookings */}
          {mappedBooking.status != null && mappedBooking.status === 6 ? (
            <>
              <Box sx={{ mb: "40px", mt: "30px" }} className="p-8">
                <Typography className="block-heading">
                  <span className="heading">CANCEL DETAILS</span>
                </Typography>
              </Box>
              <Grid id="sub-form-box" container spacing={2}>
                {/* Canceled By*/}
                <Grid
                  item
                  xs={6}
                  md={6}
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Cancelled By
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      sx={styles.textField}
                      placeholder="Cancelled By"
                      label=" Cancelled By"
                      className="formField"
                      value={
                        mappedBooking.cancelInfo.cancel_type
                          .charAt(0)
                          .toUpperCase() +
                        mappedBooking.cancelInfo.cancel_type.slice(1)
                      }
                      // inputProps={{
                      //   readOnly: Boolean(viewReadOnly) ? true : false,
                      //   disabled: true,
                      // }}
                    />
                  </FormControl>
                </Grid>
                {/* reason*/}
                <Grid
                  item
                  xs={6}
                  md={6}
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Reason
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      sx={styles.textField}
                      placeholder="Reason"
                      label="Reason"
                      className="formField"
                      value={mappedBooking.cancelInfo.reason}
                      // inputProps={{
                      //   readOnly: Boolean(viewReadOnly) ? true : false,
                      //   disabled: true,
                      // }}
                    />
                  </FormControl>
                </Grid>{" "}
              </Grid>
            </>
          ) : (
            " "
          )}
          <Box sx={{ mb: "40px", mt: "30px" }} className="p-8">
            <Typography className="block-heading">
              <span className="heading">VEHICLE DETAILS</span>
            </Typography>
          </Box>
          <Grid id="sub-form-box" container spacing={2}>
            {/* Vehicle Manufacturer */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  <span className="required">
                    Vehicle Manufacturer {!viewReadOnly && <sup> *</sup>}
                  </span>
                </InputLabel>
                <Select
                  value={allData.vehicle_manufacturer}
                  label="Vehicle Manufacturer"
                  onChange={(e) => {
                    ManufacturerHandler(e.target.value);
                    // setAllData({
                    //   ...allData,
                    //   vehicle_manufacturer: e.target.value,
                    // });
                  }}
                  inputProps={{
                    disabled: Boolean(viewBooking || editBooking)
                      ? true
                      : false,
                  }}
                  // inputProps={{
                  //   disabled: Boolean(viewBooking && !editBooking) ? true : false,
                  // }}
                  className="formField"
                >
                  {manufacturers &&
                    manufacturers.map((item) => (
                      <MenuItem value={item.vehicle_manufacturer_id}>
                        {item.manufacturer}
                      </MenuItem>
                    ))}
                </Select>
                {error && formErrors.vehicle_manufacturer ? (
                  <span className="form-error text-danger">
                    {formErrors.vehicle_manufacturer}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>

            {/* Vehicle Variants */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  <span className="required">
                    Vehicle Variants {!viewReadOnly && <sup> *</sup>}
                  </span>
                </InputLabel>
                <Select
                  value={allData.Vehicle_Variants}
                  label="Vehicle Variants"
                  onChange={(e) => {
                    variantHandler(e.target.value);
                    setAllData({
                      ...allData,
                      Vehicle_Variants: e.target.value,
                    });
                  }}
                  inputProps={{
                    disabled: Boolean(viewBooking || editBooking)
                      ? true
                      : false,
                  }}
                  className="formField"
                >
                  {variants &&
                    variants.map((item) => (
                      <MenuItem value={item.vehicle_variant_id}>
                        {item.variant}
                      </MenuItem>
                    ))}
                </Select>
                {error && formErrors.Vehicle_Variants ? (
                  <span className="form-error text-danger">
                    {formErrors.Vehicle_Variants}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            {/* Vehicle Model */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  <span className="required">
                    Vehicle Model {!viewReadOnly && <sup> *</sup>}
                  </span>
                </InputLabel>
                <Select
                  value={allData.vehicle_id}
                  label="Vehicle Model"
                  onChange={(e) => modelHandler(e.target.value)}
                  inputProps={{
                    disabled: Boolean(viewBooking || editBooking)
                      ? true
                      : false,
                  }}
                  className="formField"
                >
                  {models &&
                    models.map((item) => (
                      <MenuItem value={item.vehicle_id}>{item.model}</MenuItem>
                    ))}
                </Select>
                {error && formErrors.Vehicle_Variants ? (
                  <span className="form-error text-danger">
                    {formErrors.Vehicle_Variants}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            {/* Manufactured Year */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="outlined-adornment-amount"
                  autoComplete="off"
                  sx={styles.textField}
                  placeholder="Manufactured Year"
                  label={<span className="required">Manufactured Year </span>}
                  className="formField"
                  value={allData.Manufactured_Year}
                  inputProps={{
                    readOnly: Boolean(viewReadOnly) ? true : false,
                  }}
                  onChange={(e) =>
                    setAllData({
                      ...allData,
                      Manufactured_Year: e.target.value,
                    })
                  }
                  // onKeyPress={handleKeyPress}
                />
              </FormControl>
            </Grid>
            {/* Color */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="outlined-adornment-amount"
                  sx={styles.textField}
                  placeholder="Color"
                  label={<span className="required">Color </span>}
                  className="formField"
                  autoComplete="off"
                  onPaste={(e) => onPaste(e, "color")}
                  value={allData.color}
                  inputProps={{
                    readOnly: Boolean(viewReadOnly) ? true : false,
                  }}
                  onChange={(e) =>
                    setAllData({ ...allData, color: e.target.value })
                  }
                  onKeyPress={handleKeyText}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* SERVICE AND PAYMENT DETAILS */}

          <Box sx={{ mb: "40px", mt: "30px" }} className="p-8">
            <Typography className="block-heading">
              <span className="heading">SERVICE AND PAYMENT DETAILS</span>
            </Typography>
          </Box>

          <Grid id="sub-form-box" container spacing={2}>
            {/* Service Date */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={
                    <span className="required">
                      Service Date {!viewReadOnly && <sup> *</sup>}
                    </span>
                  }
                  inputFormat="DD/MM/YYYY"
                  open={openSDate}
                  onClose={handleOpenServicedate}
                  value={serviceDate}
                  onChange={(newDate) => {
                    setServiceDate(newDate);
                  }}
                  inputProps={{
                    disabled: Boolean(viewBooking && !editBooking)
                      ? true
                      : false,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%", margin: "8px" }}
                      onClick={handleOpenServicedate}
                    />
                  )}
                  views={["day", "month", "year"]}
                  showDaysOutsideCurrentMonth
                />
              </LocalizationProvider>
            </Grid>
            {/* Service Time */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel>
                  <span className="required">
                    Time{!viewReadOnly && <sup> *</sup>}
                  </span>
                </InputLabel>
                <Select
                  className="formField"
                  value={allData.service_time}
                  onChange={(e) => {
                    setAllData({
                      ...allData,
                      service_time: e.target.value,
                    });
                    // if (addStaff) {
                    //   if (e.target.value) {
                    //     setFormChange(true);
                    //   } else {
                    //     setFormChange(formChange);
                    //   }
                    // } else {
                    //   if (editStaff) {
                    //     if (allData.staff_id_proof_type !== e.target.value) {
                    //       setFormChange(true);
                    //     } else {
                    //       setFormChange(formChange);
                    //     }
                    //   }
                    // }
                  }}
                  // onBlur={(e) =>
                  //   onChangeValidate(
                  //     "staff_id_proof_type",
                  //     e.target.innerText
                  //       ? e.target.innerText
                  //       : allData.staff_id_proof_type
                  //   )
                  // }
                  // onClose={(e) =>
                  //   onChangeValidate(
                  //     "staff_id_proof_type",
                  //     e.target.innerText
                  //       ? e.target.innerText
                  //       : allData.staff_id_proof_type
                  //   )
                  // }
                >
                  <MenuItem value={1}>Morning</MenuItem>
                  <MenuItem value={2}>Afternoon</MenuItem>
                  <MenuItem value={3}>Evening</MenuItem>
                  <MenuItem value={4}>Night</MenuItem>
                </Select>
                {/* {error && formErrors.staff_id_proof_type ? (
                  <span className="form-error text-danger">
                    {formErrors.staff_id_proof_type}
                  </span>
                ) : (
                  ""
                )} */}
              </FormControl>
            </Grid>
            {/* Services */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  <span className="required">
                    Services {!viewReadOnly && <sup> *</sup>}
                  </span>
                </InputLabel>
                <Select
                  value={allData.service_id}
                  label="Services"
                  onChange={(e) => serviceHandler(e.target.value)}
                  // inputProps={{
                  //   disabled:
                  //     Boolean(viewBooking && !editBooking) ||
                  //     allData.vehicle_id === ""
                  //       ? true
                  //       : false,
                  // }}
                  className="formField"
                >
                  {serviceList &&
                    serviceList.map((item) => {
                      return (
                        <MenuItem value={item.service_id}>
                          {item.service_name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {error && formErrors.serviceId ? (
                  <span className="form-error text-danger">
                    {formErrors.serviceId}
                  </span>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            {/*  Service Price */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="outlined-adornment-amount"
                  sx={styles.textField}
                  placeholder="Service Price"
                  label={<span className="required">Service Price </span>}
                  className="formField"
                  inputProps={{ readOnly: true, disabled: true }}
                  value={allData.actual_amount}
                />
              </FormControl>
            </Grid>
            {/* Grand Total*/}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="outlined-adornment-amount"
                  sx={styles.textField}
                  placeholder="Grand Total"
                  label={<span className="required">Grand Total </span>}
                  className="formField"
                  value={grandTotal === 0 ? "" : parseInt(grandTotal)}
                  inputProps={{
                    readOnly: Boolean(viewReadOnly) ? true : false,
                    disabled: true,
                  }}
                />
              </FormControl>
            </Grid>
            {/* Choose MobileUnit */}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  <span className="required">
                    Mobile Unit {!viewReadOnly && <sup> *</sup>}
                  </span>
                </InputLabel>
                {/* ---{allData.mobile_unit_id}--- */}
                <Select
                  value={allData.mobile_unit_id}
                  // label="Vehicle Manufacturer"
                  onChange={(e) => {
                    setAllData({
                      ...allData,
                      mobile_unit_id: e.target.value,
                    });
                    // dateTrigger();
                  }}
                  className="formField"
                >
                  {muListData &&
                    muListData.map((item) => (
                      <MenuItem value={item.mobile_unit_id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                {/* {error && formErrors.vehicle_manufacturer ? (
                  <span className="form-error text-danger">
                    {formErrors.vehicle_manufacturer}
                  </span>
                ) : (
                  ""
                )} */}
              </FormControl>
            </Grid>

            {allData.mobile_unit_id ? (
              <>
                {/* Service StartTime */}
                <Grid
                  item
                  xs={6}
                  md={6}
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label={
                        <span className="required">
                          Start Time {!viewReadOnly && <sup> *</sup>}
                        </span>
                      }
                      value={serviceStartTime}
                      onChange={(newTime) => {
                        setServiceStartTime(newTime);
                      }}
                      inputProps={{
                        disabled: Boolean(viewBooking && !editBooking)
                          ? true
                          : false,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%", margin: "8px" }}
                          id="remove-pointer"
                          // className={` ${dateHide ? "" : "pointer-event"}`}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* Service End Time */}
                <Grid
                  item
                  xs={6}
                  md={6}
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label={
                        <span className="required">
                          End Time {!viewReadOnly && <sup> *</sup>}
                        </span>
                      }
                      value={serviceEndTime}
                      onChange={(newTime) => {
                        setServiceEndTime(newTime);
                      }}
                      inputProps={{
                        disabled: Boolean(viewBooking && !editBooking)
                          ? true
                          : false,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%", margin: "8px" }}
                          id="remove-pointer"
                          // className={` ${dateHide ? "" : "pointer-event"}`}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            ) : (
              ""
            )}

            {/* Any Comments*/}
            <Grid
              item
              xs={6}
              md={6}
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  id="outlined-adornment-amount"
                  sx={styles.textField}
                  placeholder="Any Comments"
                  autoComplete="off"
                  label={<span className="required">Any Comments</span>}
                  value={allData.comments}
                  inputProps={{
                    readOnly: Boolean(viewReadOnly) ? true : false,
                  }}
                  onChange={(e) =>
                    setAllData({ ...allData, comments: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* Coupon Code */}

          {/* <Box>
      <Box sx={styles.formRowStyle}>
        <Box sx={styles.iconLabelStyles}>
          <AttachMoneyOutlinedIcon />
          <label>Additional Charges</label>
        </Box>
        <TextField
          sx={styles.textField}
          placeholder=''
          label=""
          value={allData.Additional_Charges}
          onChange={(e) =>
            setAllData({ ...allData, Additional_Charges: e.target.value })
          }
        />
      </Box>
    </Box>

    <Box>
      <Box sx={styles.formRowStyle}>
        <Box sx={styles.iconLabelStyles}>
          <AttachMoneyOutlinedIcon />
          <label>Additional Discounts</label>
        </Box>
        <TextField
          sx={styles.textField}
          placeholder=''
          label=''
          value={allData.Additional_Discounts}
          onChange={(e) =>
            setAllData({ ...allData, Additional_Discounts: e.target.value })
          }
        />
      </Box>
    </Box> */}

          {/* <Box sx={styles.formRowStyle} className="form-box">
      <Box sx={styles.iconLabelStyles}>
        <AttachMoneyOutlinedIcon />
        <label>Grand Total</label>
      </Box>
      <TextField
        placeholder="0"
        label=""
        sx={styles.textField}
        value={grandTotal && parseInt(grandTotal)}
        inputProps={{
          readOnly: Boolean(viewReadOnly) ? true : false,
        }}
      />
    </Box> */}

          {/* <Box sx={styles.formRowStyle} className="form-box">
      <Box sx={styles.iconLabelStyles}>
        <CommentOutlinedIcon />
        <label>Any Comments</label>
      </Box>
      <TextField
        sx={styles.textField}
        placeholder=""
        label="Any Comments"
        value={allData.comments}
        inputProps={{
          readOnly: Boolean(viewReadOnly) ? true : false,
        }}
        onChange={(e) => setAllData({ ...allData, comments: e.target.value })}
      />
    </Box> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "10px 0 10px 10px",
            }}
          >
            {/* <Box sx={{ margin: "10px" }}>
        <Button variant="contained" onClick={handleCancel}>
          Cancel
        </Button>
      </Box> */}
            {!viewReadOnly ? (
              <>
                <Box>
                  <BootstrapTooltip title="Save bookings">
                    <Button variant="contained" type="submit">
                      Save
                    </Button>
                  </BootstrapTooltip>
                </Box>
              </>
            ) : null}
          </Box>
        </form>
      )}
    </>
  );
};

export default BookingForm;
