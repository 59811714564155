// import React from "react";
// import mainLoader from "../../../assets/img/loader/loader1.gif";
// const Loader = () => {
//   return (
//     <div className="main-loader">
//       <div className="main-loader-block">
//         <img className="img-fluid" src={mainLoader} />
//       </div>
//     </div>
//   );
// };

// export default Loader;


import React from 'react'
// import './LoadingIcon.css'
const Loader = () => {
  return (
    <div className='loading-div'>
    <div class="loader">Loading...</div>
    </div>
  )
}
export default Loader

