import { consoleAPI } from "../../axios";

const getMobileUnitList = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/mu/list",
    });
    if (res.success) {
      console.log(res, "res getMobileUnitList");
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getMobileUnit = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/mu/data",
      data,
    });
    if (res.success) {
      console.log(res, "res getMobileUnit");
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const deleteMobileUnit = async (data) => {
  const consoleInst = consoleAPI();
  const url = "console/mu/delete";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

const createMobileUnit = async (data) => {
  const consoleInst = consoleAPI();
  const url = "console/mu/create";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
const changeStatus = async (data) => {
  console.log(data, "hangeStatus ");
  const consoleInst = consoleAPI();
  const url = "console/mu/change-status";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
// const getMuListByStatus = async () => {
//   const consoleInst = consoleAPI();
//   try {
//     const res = await consoleInst({
//       method: "GET",
//       url: "console/mu/get-req-data?action=create",
//     });
//     if (res.success) {
//       console.log(res, "res getMuListByStatus");
//       return res.data;
//     }
//     return Promise.reject(res);
//   } catch (err) {}
// };

export {
  getMobileUnitList,
  getMobileUnit,
  createMobileUnit,
  deleteMobileUnit,
  changeStatus,
  // getMuListByStatus,
};
