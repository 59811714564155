import React, { useContext } from "react";
import { ServiceContext } from "./serviceContext/ServiceContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Avatar, Box, Button, Grid, Modal, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import recycle from "../../../assets/img/deleteIcon/recycle.svg";

import "./ui/service.css";
import { useState } from "react";
import { deleteService } from "../../../api/console/Service/service-management";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ToastContainer, toast } from "react-toastify";

function ServiceWindowHeader() {
  const {
    addService,
    setAddService,
    editService,
    setEditService,
    viewService,
    setViewService,
    mappedService,
    setMappedService,
    setViewReadOnly,
    viewReadOnly,
    getServiceDelete,
    getList,
  } = useContext(ServiceContext);
  const [open, setOpen] = useState(false);

  const onSuccess = () => {
    toast.success("Deleted Successfully", {
      position: "bottom-right",
      autoClose: 1000,
      toastId: "update",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const styles = {
    addHeaderStyle: {
      display: "flex",
      alignItems: "Center",
      justifyContent: "space-between",
      padding: "9px 15px",
      minHeight: "55px",
      borderBottom: "1px solid #ff000014",
    },
  };

  const [saveClicked, setSaveClicked] = useState(false);

  const handleAddServiceExit = () => {
    setAddService(false);
    setViewService(false);
    setEditService(false);
    setViewReadOnly(false);
    console.log("exit view Service");
  };
  const editServiceDetails = () => {
    setEditService(true);
    setViewReadOnly(false);
    console.log("hai");
  };
  const handleServiceDelete = (e, id) => {
    console.log(e, id, "delete service");
    setOpen(true);
    console.log("handleServiceDelete");
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const style = {
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "55px",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    iconStyle: {
      margin: " 0 auto",
      height: "100px",
      width: "100px",
    },
    buttonsStyles: {
      display: "flex",
      // alignItems:'center',
      justifyContent: "center",
      gap: "20px",
    },
    deleteBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
  };

  const handleCloseDeleteModal = () => {
    setOpen(false);
  };
  const handleDelete = (e, id) => {
    const data = {
      service_id: id,
    };
    const response = deleteService(data);
    response.then((res) => {
      console.log(res, "deleet id");
      getList();
      // onSuccess();
      onSuccess();
      setOpen(false);
      setAddService(false);
      setViewService(false);
      setEditService(false);
      setViewReadOnly(false);
    });
  };
  return (
    <Box sx={styles.addHeaderStyle}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <BootstrapTooltip title="Back to listing" placement="left">
          <KeyboardBackspaceIcon onClick={handleAddServiceExit} />
        </BootstrapTooltip>
        <h6 className="name-header">
          {editService
            ? mappedService.service_name
            : viewReadOnly
            ? mappedService.service_name
            : "Add Service"}
        </h6>
      </Box>

      <Grid>
        <>
          {viewReadOnly && (
            <button
              sx={{ ml: "100px" }}
              onClick={(e) =>
                handleServiceDelete(
                  e,
                  mappedService && mappedService.service_id
                )
              }
              className="action-btn"
            >
              <BootstrapTooltip title="Delete services">
                <DeleteIcon />
              </BootstrapTooltip>
            </button>
          )}
        </>
        {viewReadOnly && (
          <button
            sx={{ ml: "100px" }}
            onClick={editServiceDetails}
            className="action-btn"
          >
            <BootstrapTooltip title="Edit services">
              <EditIcon sx={{ color: "#4caf50" }} />
            </BootstrapTooltip>
          </button>

          // <button sx={{ ml: "100px" }} onClick={editServiceDetails}>
          //   <EditIcon />
          // </button>
        )}
      </Grid>
      <Modal open={open}>
        <Box sx={style.modalStyles}>
          <Avatar src={recycle} sx={style.iconStyle} />
          <Typography
            sx={style.fontStyle}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to delete ?
          </Typography>

          <Box sx={style.buttonsStyles}>
            <Button
              variant="text"
              sx={style.cancelBtn}
              onClick={handleCloseDeleteModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={style.deleteBtn}
              color="error"
              onClick={(e) =>
                handleDelete(e, mappedService && mappedService.service_id)
              }
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ServiceWindowHeader;
