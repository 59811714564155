import { Avatar, Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { BookingContext } from "./bookingContext/BookingContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import recycle from "../../../assets/img/deleteIcon/recycle.svg";
import {
  getBookingsList,
  getCancelBooking,
} from "../../../api/console/Booking/booking-management";
import { toast } from "react-toastify";

const BookingWindowHeader = () => {
  const {
    addBooking,
    SetAddBooking,
    viewBooking,
    setViewBooking,
    editBooking,
    SetEditBooking,
    mappedBooking,
    viewReadOnly,
    setViewReadOnly,
    setBookingAllData,
    userCheck, setUserCheck
  } = useContext(BookingContext);
  const cancelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [cancelled, setCancelled] = useState("");
  const [reason, setReason] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleCancelOpen = () => setOpen(true);
  const handleCancelClose = () => {
    cleanupFunc();
    setOpen(false);
  };

  const styles = {
    addHeaderStyle: {
      display: "flex",
      alignItems: "Center",
      justifyContent: "space-between",
      padding: "9px 15px",
      borderBottom: "1px solid #ff000014",
    },
    text: {
      padding: "10px",
      marginLeft: "15px",
      borderRadius: "5px",
    },
    btn: {
      marginLeft: "20px",
      borderRadius: "5px",
      border: "none",
    },
  };
  const style = {
    modalStyles: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      border: "2px taransparent #fff",
      boxShadow: 24,
      borderRadius: "14px",
      p: "25px",
    },
    fontStyle: {
      fontFamily: "inherit",
      fontWeight: 700,
      textAlign: "center",
      mb: "20px",
    },
    iconStyle: {
      margin: " 0 auto",
      height: "80px ",
      width: "80px",
    },
    buttonsStyles: {
      display: "flex",
      // alignItems:'center',
      justifyContent: "center",
      gap: "20px",
    },
    deleteBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
    cancelBtn: {
      borderRadius: "30px",
      letterSpacing: ".8px",
      padding: "5px 15px",
    },
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const cleanupFunc = () => {
    setCancelled("");
    setReason("");
  };
  const handleBookingExit = () => {
    SetAddBooking(false);
    setViewBooking(false);
  };
  const editBookings = () => {
    SetEditBooking(true);
    SetAddBooking(false);
    setViewReadOnly(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      order_id: mappedBooking.order_id,
      reason: reason,
      cancel_type: cancelled,
    };
    const cancelledBooking = getCancelBooking(data);
    cancelledBooking
      .then((res) => {
        console.log(res, "getCancelBooking res");
        // toast.success("Booking Cancelled Successfully", {
        //   position: "bottom-right",
        // });
        const getBookingsData = getBookingsList();
        getBookingsData &&
          getBookingsData.then((res) => {
            setBookingAllData(res);
            setOpen(false);
            SetAddBooking(false);
            setViewBooking(false);
            cleanupFunc();
          });
        toast.success("Booking Cancelled Successfully", {
          position: "bottom-right",
        });
      })
      .catch((err) => {
        toast.error(err, {
          position: "bottom-right",
        });
      });
  };

  return (
    <Box sx={styles.addHeaderStyle}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <BootstrapTooltip title="Back to listing" placement="left">
          <KeyboardBackspaceIcon onClick={handleBookingExit} />
        </BootstrapTooltip>
        <h6 className="name-header" style={styles.text}>
          {editBooking
            ? `${
                mappedBooking.customerVehicle &&
                mappedBooking.customerVehicle.vehicle_registration_number
              }`
            : viewBooking
            ? `${
                mappedBooking.customerVehicle &&
                mappedBooking.customerVehicle.vehicle_registration_number
              }`
            : addBooking && "Add Booking"}
        </h6>
      </Box>
      <Grid sx={{ display: "flex", gap: "10px" }}>
        {viewReadOnly && userCheck && userCheck.usertype == 1? (
          <>
            {mappedBooking.status != null && mappedBooking.status === 6 ? ("") : (
            <button className="action-btn cancel-btn-block" onClick={handleCancelOpen}>Cancel Booking</button>)}
            
            {mappedBooking.status != null && mappedBooking.status === 6 ? (
              "") : (
              <button onClick={editBookings} className="action-btn">
                <BootstrapTooltip title="Edit bookings">
                  <EditIcon />
                </BootstrapTooltip>
              </button>)}
            <Modal
              open={open}
              onClose={handleCancelClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style.modalStyles}>
                <Avatar src={recycle} sx={style.iconStyle} />
                <Typography
                  sx={style.fontStyle}
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  {`Are you sure to cancel 
                  this booking of ${
                    mappedBooking.customer &&
                    mappedBooking.customer.customer_name
                  }?`}
                </Typography>
                <form onSubmit={handleSubmit}>
                  <div className="cancelled-block">
                    <div className="input-group input-group-custom">
                      <label htmlFor="" className="mb-0">
                        Cancelled By
                      </label>
                      <div className="col-12 d-flex">
                        <div className="radio-title mr-4">
                          <input
                            type="radio"
                            value="admin"
                            name="vehicleType"
                            onChange={(e) => setCancelled(e.target.value)}
                            checked={cancelled === "admin"}
                          />
                          Admin
                        </div>
                        <div className="radio-title mr-4">
                          <input
                            type="radio"
                            value="customer"
                            name="vehicleType"
                            onChange={(e) => setCancelled(e.target.value)}
                            checked={cancelled === "customer"}
                          />
                          Customer
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-group input-group-custom reason-group">
                    {/* 
                  <label htmlFor="" className="mb-0 mt-3 ml-3">
              Reason
            </label> */}
                    <div className="col-12 pl-0 pr-0">
                      <div className="form-group cancel-form-group">
                        <textarea
                          placeholder="Reason for cancellation"
                          className="form-control"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <Box sx={style.buttonsStyles}>
                    <Button
                      variant="text"
                      sx={style.cancelBtn}
                      onClick={handleCancelClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="submitBtn"
                      variant="contained"
                      sx={style.deleteBtn}
                      color="error"
                      type="submit"
                      // onClick={(e) => handleDelete(e)}
                      disabled={
                        cancelled.length && reason.length ? false : true
                      }
                    >
                      Confirm
                    </Button>
                  </Box>
                </form>
              </Box>
            </Modal>
          </>
        ) : (
          ""
        )}
      </Grid>
    </Box>
  );
};

export default BookingWindowHeader;
