import { useEffect, useState } from "react";
import { createContext } from "react";
import {getCustomerList} from '../../../../api/console/Customer/customer-management'
export const CustomerContext = createContext();

export const CustomerProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [page,setPage]= useState(1);
  const [itemCount,setItemCount]= useState();

  useEffect(() => {
    getList(page,10);
  }, []);
  const getList = (page,limit) => {
    setLoading(true);
    const response = getCustomerList(page,limit);
    response.then((res) => {
      setCustomerData(res.customers);
      setItemCount(res.totalCount);
      setLoading(false);
    });
  };
  let initialValues = {
    loading,
    setLoading,
    customerData,
    setCustomerData,
    getList,
    setPage,
    page,
    itemCount
  };
  return (
    <CustomerContext.Provider value={initialValues}>
      {props.children}
    </CustomerContext.Provider>
  );
};