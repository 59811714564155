import { Box, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useForm } from 'react-hook-form'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const defaultInputValues = {
  username: '',
  password: ''
}

const LoginForm = ({ Login, error }) => {

  const [details, setDetails] = useState(defaultInputValues);

  const modalStyles = {

    inputFields: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '40px',
      alignItems: 'center',
      marginBottom: '15px',
      '.MuiFormControl-root': {
        marginBottom: '20px',
        width: '75%',
      },
      '& label': {
        color: "warning.main",

      },
      '& .MuiInputBase-root': {
        color: "#111"
      },
    },

    textFieldStyles: {
      backgroundColor: '#e0dbd7',
      border: " transparent",
      borderRadius: '6px',
      position: 'relative',
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: "#ff9800",
        },
      },
    },

    btnStyle: {
      border: '1px solid transparent',
      borderRadius: ' 0.25rem',
      fontWeight: 400,
      lineHeight: '1.5',
      padding: '0.375rem 0.75rem',
      fontSize: '0.8rem',
    }

  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    console.log(data);
    Login(details);
  }

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Box sx={{ width: "100%", alignItems: "left" }} >
      <form onSubmit={handleSubmit(onSubmit)} >
        <Box sx={modalStyles.inputFields}>
          <TextField
            sx={modalStyles.textFieldStyles}
            autoFocus={true}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            color="warning"
            label="Email"
            autoComplete="off"
            variant="outlined"
            {...register('username')}
            error={errors.username ? true : false}
            helperText={errors.username?.message}
            value={details.username}
            onChange={(event) => setDetails({ ...details, username: event.target.value })}
          />
          <TextField
            sx={modalStyles.textFieldStyles}
            color="warning"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            // required
            {...register('password')}
            error={errors.password ? true : false}
            helperText={errors.password?.message}
            value={details.password}
            onChange={(event) => setDetails({ ...details, password: event.target.value })}
          />
          {(error !== "") ? <div className="error text-danger text-center">{error}</div> : ""}
          <Button id="submitButton" variant="contained" color="warning" type="submit" style={modalStyles.btnStyle}>Login</Button>
        </Box>
      </form>
    </Box>
  );
};

export default LoginForm;