import React, { useState, useContext, Fragment, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridOverlay } from "@mui/x-data-grid";
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import MobileUnitWindowRight from "./MobileUnitWindowRight";
import { Box } from "@mui/system";
import { MobileUnitContext } from "./context/MobileUnitContext";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { ToastContainer } from "react-toastify";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Loader from "../layout/Loader";
import {
  changeStatus,
  getMobileUnitList,
} from "../../../api/console/MobileUnit/mobileunit-management";
const MobileUnitMainBlock = () => {
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const {
    muDatas,
    setMUDatas,
    addMobileUnit,
    setAddMobileUnit,
    viewMobileUnit,
    fetchMobileUnit,
    setViewMobileUnit,
    mappedMobileUnitData,
    setMappedMobileUnitData,
    setViewReadOnly,
    setEditMobileUnit,
    loading,
    setLoading,
  } = useContext(MobileUnitContext);
  console.log(muDatas, "muDatas");

  // const { data, ...rest } = muDatas;
  // console.log(data, "rest of data");
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const handleMuToggle = (event, status, id) => {
    event.stopPropagation();
    event.preventDefault();
    const data = {
      status: status == 1 ? 0 : 1,
      mobile_unit_id: id,
    };
    console.log(data, "statusstatus");
    const response = changeStatus(data);
    response.then((res) => {
      console.log(res, "getStatusChange data");
      listTable();
      // console.log(response, "getStatusChange response");
    });
  };
  const listTable = () => {
    const response = getMobileUnitList();
    response.then((res) => {
      console.log(res, "getMUList");
      setMUDatas(res);
    });
  };
  const columns = [
    { field: "name", headerName: "Unit Name", flex: 1 },
    // { field: "contact_number", headerName: "Mobile Number", flex: 1 },
    {
      field: "contact_number",
      headerName: "Mobile Number",
      // width: 400,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.phone_extension != null
              ? params.row.phone_extension
              : ""}
            <span className="word-space ">{params.row.contact_number}</span>{" "}
          </>
        );
      },
    },
    { field: "emirate_name", headerName: "Location ", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.current_status === "Available" && (
              <Chip label="Available" color="success" />
            )}
            {params.row.current_status === "Not Working" && (
              <Chip label="Off Day" color="error" />
            )}
            {params.row.current_status === "Engaged" && (
              <Chip label="Engaged" color="warning" />
            )}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      flex: 1,
      // getAction:(params) => [
      //   <GridActionsCellItem>
      //   </GridActionsCellItem>
      // ]
      renderCell: (params, index) => {
        return (
          <>
            {/* <Button
              // onClick={(e) => onButtonClick(e, params.row)}
              variant="contained"
              >
              Delete
            </Button> */}
            {/* <BootstrapTooltip title="Change status" placement="right">
              <Switch
                {...label}
                defaultChecked
                color="warning"
                onClick={(event) => event.stopPropagation()}
              />
            </BootstrapTooltip> */}
            <BootstrapTooltip title="Change status" placement="right">
              <Switch
                {...label}
                onClick={(e) =>
                  handleMuToggle(
                    e,
                    params.row.status,
                    params.row.mobile_unit_id
                  )
                }
                defaultChecked={params.row.status == 1 ? true : false}
                color="warning"
              />
            </BootstrapTooltip>
          </>
        );
      },
    },
  ];

  let rowFilter;
  const handleViewClick = (e) => {
    setViewMobileUnit(true);
    setViewReadOnly(true);
    setEditMobileUnit(false);
    rowFilter = muDatas.filter((item) => {
      return item.name === e.name;
    });
    fetchMobileUnit(rowFilter[0].mobile_unit_id);
  };

  const styles = {
    dataTableStyle: { width: "100%", background: "#fff" },
    windowChange: {
      height: "120px",
    },
  };
  const handleAddMobileUnit = () => {
    // setViewMobileUnit(true);
    setViewMobileUnit(false);
    setViewReadOnly(false);
    setMappedMobileUnitData({});
    setAddMobileUnit(true);
    setEditMobileUnit(false);
  };
  const customRowsOverlay = () => {
    return (
      <GridOverlay>
        <p>No Data Available</p>
      </GridOverlay>
    );
  };
  return (
    <Fragment>
      <Box className="mainBlock">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box className="title-search">
              <Box className="box-left">
                <span className="main-header">Mobile Unit</span>
                <Button
                  variant="outlined"
                  sx={{ mb: "15px" }}
                  onClick={handleAddMobileUnit}
                >
                  Add New
                </Button>
              </Box>
              {/* <Box
                className="box-right"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button className="btn-icon">
                  Filter <FilterAltOutlinedIcon />
                </Button>
                <Button className="btn-icon">
                  Sort <FilterListOutlinedIcon />
                </Button>
              </Box> */}
            </Box>
            <Box
              className={`dataTable-block ${addMobileUnit && "windowChange"} ${
                viewMobileUnit && "windowChange"
              }`}
            >
              <Box className="data-table-sub-block" sx={styles.dataTableStyle}>
                <DataGrid
                  components={{
                    NoRowsOverlay: customRowsOverlay,
                  }}
                  onRowClick={(e) => handleViewClick(e.row)}
                  rows={muDatas}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  getRowId={(row) => row.mobile_unit_id}

                  // getRowId={(row) => row.mobile_unit_id}
                  // getRowId={(row) => row.name}
                  // checkboxSelection
                />
              </Box>
              <MobileUnitWindowRight setAddMobileUnit={setAddMobileUnit} />
            </Box>
          </>
        )}
      </Box>
      <ToastContainer />
    </Fragment>
  );
};

export default MobileUnitMainBlock;
