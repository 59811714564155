import { Box, Grid } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { BookingHistoryContext } from "./bookinghistoryContext/BookingHistoryContext";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { getActivityData } from "../../../api/console/BookingHistory/bookinghistory-management";
import { useEffect } from "react";
const BookingHistoryContent = () => {
  const { mappedBookingHistory, setMappedBookingHistory } = useContext(
    BookingHistoryContext
  );
  const headerStyles = {
    logoutBlock: {
      background: "#ffffff",
      color: "#000",
      borderRadius: "6px",
    },
    ulBlock: {
      // list-style-type: none;
      padding: "18px",
      boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
      width: "100%",
      borderRadius: "12px",
      marginBottom: "20px",
    },
    typoBlock: {
      borderBottom: "1px solid #75757529",
      fontSize: "15px",
      paddingBottom: "5px",
      letterSpacing: "0.8px",
    },
    spanBlock: {
      marginTop: "5px",
      color: "#111",
      fontWeight: "600",
      fontSize: "15px",
    },
  };
  // const steps = [
  //   "Select campaign settings",
  //   "Create an ad group",
  //   "Create an ad",
  //   "Cr an ad",
  //   "Cre ad",
  //   "C an ad",
  //   "Cd",
  // ];
  // useEffect(() => {
  //   handleServiceToggle();
  // }, []);
  // const handleServiceToggle = (event, status, id) => {
  //   const data = {
  //     order_id: mappedBookingHistory.order_id,
  //   };
  //   const response = getActivityData(data);
  //   response.then((res) => {
  //     console.log(res, "getActivityData");
  //   });
  // };
  // useEffect(() => {
  //   var element = document.getElementById("formTopBlock");
  //   element.scrollIntoView({ behavior: "smooth", block: "start" });
  // }, [mappedBookingHistory]);
  return (
    <Box className="form-block" id="formTopBlock">
      <Grid container>
        <Grid item md={8}>
          <Grid container spacing={2}>
            <Grid item sx={{ padding: "10px 10px" }} md={6}>
              {/* Order Details */}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Typography variant="h6" sx={headerStyles.typoBlock}>
                    Order Id :{" "}
                    <span>{mappedBookingHistory.order_unique_id}</span>
                  </Typography>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  >
                    {/* {mappedBookingHistory &&
                    mappedBookingHistory.customer.customer_name} */}
                  </p>
                  <Box className="row">
                    <Box className="col-md-6 mb-3">
                      <p>0rder Status :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.order_status}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      <p>Scheduled time :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.service_time}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      <p>Total Duration :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.customer != null
                          ? mappedBookingHistory.customer.phone_number &&
                            mappedBookingHistory.customer.phone_number
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      <p>Date :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.service_date}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid sx={{ padding: "10px 10px" }} item md={6}>
              {/* Vehicle Details  */}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Typography variant="h6" sx={headerStyles.typoBlock}>
                    Vehicle Details
                  </Typography>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                  </p>
                  <Box className="row">
                    <Box className="col-md-6 mb-3">
                      <p>Manufacturer :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.customerVehicle != null
                          ? mappedBookingHistory.customerVehicle.vehicle
                              .manufacturer &&
                            mappedBookingHistory.customerVehicle.vehicle
                              .manufacturer
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      <p>Model :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.customerVehicle != null
                          ? mappedBookingHistory.customerVehicle.vehicle
                              .model &&
                            mappedBookingHistory.customerVehicle.vehicle.model
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      <p>Color :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.customerVehicle != null
                          ? mappedBookingHistory.customerVehicle.vehicle
                              .color &&
                            mappedBookingHistory.customerVehicle.vehicle.color
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      <p>Year :</p>
                      <span style={headerStyles.spanBlock}>
                        {/* {mappedBookingHistory.customer != null
                      ? mappedBookingHistory.customer.phone_number &&
                        mappedBookingHistory.customer.phone_number
                      : ""} */}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid sx={{ padding: "10px 10px" }} item md={6}>
              {/* Customer Details */}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Typography variant="h6" sx={headerStyles.typoBlock}>
                    Customer Details
                  </Typography>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  ></p>
                  <Box className="row">
                    <Box className="col-md-6 mb-3">
                      <p> Customer Name :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.customer != null
                          ? mappedBookingHistory.customer.customer_name &&
                            mappedBookingHistory.customer.customer_name
                          : ""}
                      </span>
                    </Box>
                    <Box className="col-md-6 mb-3">
                      <p>Phone Number :</p>
                      <span style={headerStyles.spanBlock}>
                        <span className="word-space-right">
                          {mappedBookingHistory.customer != null
                            ? mappedBookingHistory.customer.phone_extension &&
                              mappedBookingHistory.customer.phone_extension
                            : ""}
                        </span>
                        {mappedBookingHistory.customer != null
                          ? mappedBookingHistory.customer.phone_number &&
                            mappedBookingHistory.customer.phone_number
                          : ""}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid sx={{ padding: "10px 10px" }} item md={6}>
              {/* Payment Details */}
              <Box sx={headerStyles.logoutBlock} item md={6}>
                <Box sx={headerStyles.ulBlock}>
                  <Typography variant="h6" sx={headerStyles.typoBlock}>
                    Payment Details
                  </Typography>
                  <p
                    style={{
                      fontSize: "1rem",
                      letterSpacing: "0.8px",
                      margin: "15px 0 5px 0",
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  ></p>
                  <Box className="row">
                    <Box className="col-md-6 mb-3">
                      <p>Total Amount :</p>
                      <span style={headerStyles.spanBlock}>
                        {mappedBookingHistory.payable_amount}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="box-block" container></Box>
        </Grid>
        <Grid item sx={{ padding: "0px 10px" }} md={4}>
          <Box sx={{ width: "100%" }} className="stepper-block">
            <Stepper
              activeStep={
                mappedBookingHistory.current_step &&
                mappedBookingHistory.current_step
              }
              orientation="vertical"
            >
              {mappedBookingHistory.activity_log &&
                mappedBookingHistory.activity_log.map((label, i) => (
                  <Step key={i}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
            </Stepper>
          </Box>
          {/* <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingHistoryContent;
