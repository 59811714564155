import React, { useContext } from "react";

import { Box } from "@mui/material";
import SubAdminForm from "./SubAdminForm";
import SubAdminWindowRightHeader from "./SubAdminWindowRightHeader";
import SubLoader from "../layout/SubLoader";
import { SubAdminContext } from "./subadminContext/SubAdminContext";

const SubAdminWindowRight = () => {
  const {
    subLoading
  } = useContext(SubAdminContext);
  const styles = {
    windowWidth: {
      width: "calc(100% - 250px)",
    },
  };
  return (
    <Box className="windowRightBlock" sx={styles.windowWidth}>
      {subLoading ? (
        <SubLoader />
      ) : (
        <>
          <SubAdminWindowRightHeader />
          <SubAdminForm />
        </>
      )}
    </Box>
  );
};

export default SubAdminWindowRight;
