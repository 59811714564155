import React, {useEffect, useState} from "react";
import { Box, Button, Popover } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import icon from "../../../assets/img/icon/icon.jpg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { logout } from "../../../api/auth/logout";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { red } from "@mui/material/colors";

import dateFormat from "dateformat";
import Calendar from 'react-calendar';
import "react-calendar/dist/Calendar.css";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  zIndex: "1",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));


function Header() {
  const [user, setUser] = useState([]);
useEffect(() => {
  let userName = localStorage.getItem("username");
  let userId = localStorage.getItem("userid");
  let userType = localStorage.getItem("userType");
  setUser({
    username: userName,
    userid: userId,
    usertype: userType,
  });
}, []);
// console.log(user,"...............user type");

  const headerStyles = {
    Wrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    icon: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "15px",
      mr: "10px",
      width: "calc(100% - 0px)",
      justifyContent: "flex-end",
    },
    logoutBlock: {
      background: "#ffffff",
      top: "65px",
      right: "20px",
      color: "#000",
      borderRadius: "6px",
      cursor: "pointer",
      width: "100%",
      maxWidth: "200px",
      textAlign: "center"
    },
    ulBlock: {
      // list-style-type: none;
      display: "inline-grid",
      padding: "18px",
      placeItems: "center",
      boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
      width: "100%",
    },
    paddingBlock: {
      marginBottom: "0px !important",
      color: "red",
    },
    logoutBtn: {
      padding: "6px 12px",
      color: "#252525",
      borderRadius: "3px",
      fontSize: "0.8rem",
      border: "solid 1px #e0e0e0",
      width: "100%",
      cursor: "pointer",
    },
    BtnStyles: {
      background: "#f5f5f5",
      height: "40px",
      width: "40px",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const showPopup = () => {
    setPopup(!popup);
  };
  const handleLogout = () => {
    const returnedPromise = logout();
    returnedPromise.then((res) => {
      navigate("/");
    });
  };
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  }
  const drawerWidth = 240;
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          background: "#ffffff !important",
          border: "none !important",
        }}
        className="appBar"
      >
        <Toolbar sx={headerStyles.Wrapper}>
          {/* <Box> */}
          {/* <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: "#252525" }}
            >
              Seahorse
            </Typography> */}
          {/* Seahorse */}
          {/* </Typography> */}
          {/* </Box> */}
​
​
          <Box sx={headerStyles.icon}>
              {/* <Search sx={{background: "#f7f7f7 !important", padding: "0px 10px"}}>
                <SearchIconWrapper sx={{background: "#f7f7f7", padding: "0px"}}>
                  <SearchIcon sx={{color: "#a5a5a5"}} />
                </SearchIconWrapper>
                <StyledInputBase  className="search-input"
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
            <Box sx={headerStyles.BtnStyles}>
              <NotificationsIcon sx={{ color: "#252525" }} />
            </Box> */}
            {/* <Avatar alt="icon" src={icon} onClick={handleLogout} /> */}
            <Avatar className="avatar-styles" alt="icon" src={icon} />
            <span
              onClick={handleOpen}
              style={{
                cursor: "pointer",
                color: "#000000",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                letterSpacing: "0.5px",
              }}
            >
              {user && user.username} <ArrowDropDownIcon sx={{ color: "#e8e8e8" }} />
            </span>
            {/* <AccountCircleIcon /> */}
          </Box>
          <Popover 
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 0,
                mt: 1.5,
                ml: 0.75,
                width: 180,
                '& .MuiMenuItem-root': {
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <Box 
            sx={{ my: 1.5, px: 2.5 }}
            >
              <Box sx={headerStyles.logoutBlock}>
                {/* <span>Profile</span> */}
                <Avatar
                  sx={{ width: "60px", height: "60px;" , m:'0 auto'}}
                  alt="icon"
                  src={icon}
                />
                <p
                  style={{
                    fontSize: "1rem",
                    letterSpacing: "0.8px",
                    margin: "15px 0 5px 0",
                    textTransform: "capitalize",
                    fontWeight: "500",
                  }}
                >
                  {user?.usertype == "1" ?  "Admin" :user?.usertype == "3" ? "Manager" : user?.usertype == "6" && "SubAdmin"}
                </p>
                <p style={{ fontSize: "0.8rem", letterSpacing: "0.8px" }}>
                  {user && user.username}
                </p>
                <button onClick={handleLogout} style={headerStyles.logoutBtn} className="btn-focus">
                  Logout
                </button>
              </Box>
            </Box>
            </Popover>
        </Toolbar>
      </AppBar>
      {/* <Outlet /> */}
    </>
  );
}
export default Header;