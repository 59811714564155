import React from "react";

const Validation = (values, serviceId, isOnChange, field, value) => {
  console.log(serviceId, "selecter service");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
  let errors = {};
  if (!isOnChange) {
    if (!values.vehicle_registration_number) {
      errors.vehicle_registration_number = "Vehicle number is required";
      console.log(
        values.vehicle_registration_number,
        "vehicle_registration_number valdn"
      );
    }
    if (!values.customer_name) {
      errors.customer_name = "Customer Name is required";
      console.log(values.customer_name, "customer_name valdn");
    }
    if (!values.phone_number) {
      errors.phone_number = "Phone Number is required";
    } else if (values.phone_number?.length < 9) {
      errors.phone_number = "9 to 10 characters required for phone number";
      console.log(values.phone_number?.length, "values.phone_number?.length");
    }
    if (!values.vehicle_manufacturer) {
      errors.vehicle_manufacturer = "Vehicle Manufacturer is required";
    }
    if (!values.Vehicle_Variants) {
      errors.Vehicle_Variants = "Vehicle Variants is required";
    }
    if (!serviceId) {
      errors.serviceId = "Service is required";
    }
  } else {
    if (field == "vehicle_registration_number") {
      if (!value)
        errors.vehicle_registration_number = "Vehicle number is required";
    } else if (field == "customer_name") {
      if (!value) {
        errors.customer_name = "Customer Name is required";
      }
    } else if (field == "phone_number") {
      if (!value) errors.phone_number = "Phone Number is required";
    }
  }

  //   let dateofBirth = new Date(dateOfserv).toLocaleDateString("fr-CA");
  //   let dateOfJoining = timeofServ == undefined ? new Date() : new Date(timeofServ);
  //  doj = new Date(doj).getMilliseconds();
  //  dob = new Date(dob).getMilliseconds();

  //   console.log(dateOfserv, "dateOfserv", timeofServ,"timeofServ" , dateofBirth,"dateofBirth" ,dateOfJoining ,"dateOfJoining----------------------------------------");
  //   if (dateOfJoining.toLocaleDateString() == dateofBirth.toLocaleDateString()) {
  //     errors.dob = " Date of birth cannot be same as date of joining";
  //   } else if (dateofBirth > dateOfJoining) {
  //     errors.dob = " Date of Birth cannot be greater than date of joining";
  //   }

  return errors;
};

export { Validation };
