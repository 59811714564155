import React, { useContext, useState } from "react";
import { SubAdminContext } from "./subadminContext/SubAdminContext";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Stack,
  Autocomplete,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  OutlinedInput,
} from "@mui/material";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CarRepairOutlinedIcon from "@mui/icons-material/CarRepairOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useEffect } from "react";
import {
  getMuList,
  getMUonEdit,
} from "../../../api/console/SubAdmin/sub-admin-management";
import { Validation } from "./Ui/Validation";

const SubAdminForm = () => {
  const {
    subAdminData,
    setSubAdminData,
    addSubAdmin,
    viewReadOnly,
    mappedSubAdmin,
    viewSubAdmin,
    handleSubmit,
    initialState,
    error,
    formErrors,
    muList,
    setMulist,
    subCredential,
    setSubCredential,
    setSelectedMuList,
    selectedMuList,
    setCredOpen,
    editSubAdmin,
    setFormErrors,
    SetError,
    setFormChange,
    formChange,
    handleOpenCredModal,
    initialCredential,
    credOpen,
  } = useContext(SubAdminContext);
  //MUI Styles
  const styles = {
    formStyle: {
      padding: "15px",
      maxHeight: "calc(100vh - 215px)",
      overflow: "auto",
    },
    formRowStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: "15px",
    },
    iconLabelStyles: {
      display: "flex",
      gap: "20px",
    },
    fieldError: {
      width: "100%",
    },
    phoneExtension: {
      width: "100%",
    },
    selectField: {
      width: "80%",
    },
    textField: {
      width: "100%",
    },
    typographyStyles: {
      backgroundColor: "#b3e5fc",
      mb: "10px",
      alignItems: "center",
      // display: "flex",
      justifyContent: "space-between",
      margin: "0",
      borderRadius: "4px 4px 0 0",
      display: "inline-block",
      padding: "5px 15px",
      textTransform: "uppercase",
      fontWeight: 410,
    },
    spanStyles: {
      background: "#b3e5fc",
    },
    cardStyles: {
      width: "150px",
      margin: 0,
      background: "#fafafa",
      border: "1px dotted #e0e0e0",
      borderRadius: "3px!important",
      color: "#01579b",
      fontSize: "14px!important",
      height: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    btnStyle: {
      color: "#FF8F00",
      fontSize: "12px",
      fontWeight: "500",
      outline: "none",
      backgroundColor: "#fff",
      border: "none",
      marginTop: "10px",
      width: "100%",
      maxWidth: "60%",
      margin: "0 auto",
    },
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowCPassword = () => setShowCPassword(!showCPassword);
  const handleMouseDownCPassword = () => setShowCPassword(!showCPassword);

  //string only
  const handleKeyText = (e) => {
    var key = e.key;
    var regex = /^[A-Za-z\s]*$/;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  //Numeric Only fn
  let len = parseInt(subAdminData?.phone_number?.length);

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
    if (len > 9) {
      e.preventDefault();
    }
  };

  const editMuSybAdmin = () => {
    const data = {
      subadmin_id: mappedSubAdmin.subadmin_id,
    };
    const getMUonEditData = getMUonEdit(data);
    getMUonEditData.then((res) => {
      console.log(res, "getMUonEditgetMUonEdit");
    });
  };
  useEffect(() => {
    editMuSybAdmin();
  }, []);

  useEffect(() => {
    const data = getMuList();
    data.then((res) => {
      setMulist(res);
    });
    if (viewSubAdmin && mappedSubAdmin.length !== 0) {
      SetError(false);
      setFormErrors({});
      setCredOpen(false);
      let { subadminMus, user, ...rest } = mappedSubAdmin;
      setSubAdminData(rest);
      setSubCredential({
        username: user?.user_name,
      });
      console.log(user, "user...................................");

      //Selected MU list  Array
      let mlist =
        subadminMus?.length &&
        subadminMus.map((data) => ({
          mobile_unit_id: data.mobile_unit_id,
          name: data.name,
        }));
      // // setSubCredential(subadminMus)
      setSelectedMuList(mlist);
      // let allList = mu
    } else {
      setSubAdminData(initialState);
      setSubCredential(initialCredential);
      setSelectedMuList([]);
    }
    // console.log(mappedSubAdmin,"mappedSubAdmin...................................");
  }, [mappedSubAdmin, viewSubAdmin, addSubAdmin, editSubAdmin]);

  const handleSelectMu = (e, value) => {
    if (value) {
      setSelectedMuList(value);
      console.log(value, "..............value");
    }
  };
  // console.log(selectedMuList, ".........................selectedMuList");

  // const clickUpdateHandler = () => {
  //   setCredOpen(true);
  // };

  //On Change Validate fn
  const onChangeValidate = (field, value) => {
    // console.log(field, "field", value, "value", "inside onchangevalidation ");
    const errors = Validation(subAdminData, field, value, true, false);
    if (Object.keys(errors).length !== 0) {
      SetError(true);
      if (Object.keys(formErrors).length !== 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        setFormErrors(updatedErrorObject);
      } else {
        setFormErrors(errors);
      }
    } else {
      if (Object.keys(formErrors).length !== 0) {
        delete formErrors[field];
        const updatedErrorObject = { ...formErrors };
        setFormErrors(updatedErrorObject);
      } else {
        SetError(false);
        // SetFormErrors(errorFields);
      }
    }
  };

  //prevent paste text
  const onPaste = (e, type) => {
    const paste = e.clipboardData.getData("text/plain");
    // var regex = /[0-9]|\./;
    let pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
    if (type == "phone") {
      if (!pattern.test(paste)) {
        e.preventDefault();
      } else if (pattern.test(paste) && !subAdminData.phone.length) {
        setSubAdminData({ ...subAdminData, phone_number: paste });
      }
    }
  };

  useEffect(() => {
    var element = document.getElementById("sub-form-box");
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [mappedSubAdmin]);

  //  const sList = selectedMuList && selectedMuList.map((option, index) => ( selectedMuList+"["+option.mobile_unit_id+"]"));

  return (
    <Box
      sx={styles.formStyle}
      className={`form-block  ${viewReadOnly && "view-form-block"}`}
    >
      <form onSubmit={handleSubmit}>
        <Grid id="sub-form-box" container spacing={2}>
          {/* Name */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                placeholder="Name"
                label={
                  <span className="required">
                    Name {!viewReadOnly && <sup> *</sup>}
                  </span>
                }
                autoComplete="off"
                value={subAdminData.name}
                onKeyPress={handleKeyText}
                onChange={(e) => {
                  setSubAdminData({ ...subAdminData, name: e.target.value });
                  if (addSubAdmin) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editSubAdmin) {
                      if (subAdminData.name !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }

                  onChangeValidate("name", e.target.value);
                }}
                onBlur={(e) => {
                  onChangeValidate("name", e.target.value);
                }}
              />
              {error && formErrors.name ? (
                <span className="form-error text-danger">
                  {formErrors.name}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* E-mail */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                placeholder="Email"
                label={
                  <span className="required">
                    Email {!viewReadOnly && <sup> *</sup>}
                  </span>
                }
                autoComplete="off"
                value={subAdminData.email}
                onChange={(e) => {
                  setSubAdminData({ ...subAdminData, email: e.target.value });
                  if (addSubAdmin) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editSubAdmin) {
                      if (subAdminData.email !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }

                  {
                    error &&
                      formErrors.email &&
                      onChangeValidate("email", e.target.value);
                  }
                }}
                onBlur={(e) => {
                  onChangeValidate("email", e.target.value);
                }}
              />
              {error && formErrors.email ? (
                <span className="form-error text-danger">
                  {formErrors.email}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Phone Extension */}
          <Grid
            item
            xs={2}
            md={3}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="phone-extension">Phone Extension</InputLabel>
              <Select
                labelId="phone-extension"
                id="extension-select"
                label="Phone Extension"
                defaultValue={"+971"}
                value={subAdminData.phone_number_extension}
                placeholder="Phone Extension"
                InputProps={{
                  autoWidth: true,
                  disabled: Boolean(viewReadOnly) ? true : false,
                }}
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                onChange={(e) =>
                  setSubAdminData({
                    ...subAdminData,
                    phone_number_extension: e.target.value,
                  })
                }
              >
                <MenuItem value={"+971"}>+971</MenuItem>
              </Select>
              {error && formErrors.phone_number_extension ? (
                <span className="form-error text-danger">
                  {formErrors.phone_number_extension}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          {/* Phone Number */}
          <Grid
            item
            xs={6}
            md={9}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                inputProps={{
                  readOnly: Boolean(viewReadOnly) ? true : false,
                }}
                sx={styles.textField}
                placeholder="Phone Number"
                label={
                  <span className="required">
                    Phone Number {!viewReadOnly && <sup> *</sup>}
                  </span>
                }
                autoComplete="off"
                onKeyPress={handleKeyPress}
                value={subAdminData.phone_number}
                onPaste={(e) => onPaste(e, "phone")}
                onChange={(e) => {
                  setSubAdminData({
                    ...subAdminData,
                    phone_number: e.target.value,
                  });
                  if (addSubAdmin) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editSubAdmin) {
                      if (subAdminData.phone_number !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }

                  {
                    error &&
                      formErrors.phone_number &&
                      onChangeValidate("phone_number", e.target.value);
                  }
                }}
                onBlur={(e) => {
                  onChangeValidate("phone_number", e.target.value);
                }}
              />
              {error && formErrors.phone_number ? (
                <span className="form-error text-danger">
                  {formErrors.phone_number}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ mb: "40px", mt: "30px" }}>
          <Typography className="block-heading">
            <span className="heading">MOBILE UNITS</span>
          </Typography>
        </Box>
        {/* MU List */}
        <Grid id="sub-form-box" container spacing={2}>
          <Grid
            item
            xs={6}
            md={12}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Autocomplete
              multiple
              id="tags-outlined"
              options={muList?.length ? muList : []}
              getOptionLabel={(option) => option?.name && option.name}
              isOptionEqualToValue={
                mappedSubAdmin &&
                ((option, value) =>
                  option?.mobile_unit_id === value?.mobile_unit_id)
              }
              value={selectedMuList ? selectedMuList : []}
              onChange={(event, value) => {
                handleSelectMu(event, value);
                if (addSubAdmin) {
                  if (value.length) {
                    setFormChange(true);
                  } else {
                    setFormChange(formChange);
                  }
                } else {
                  if (editSubAdmin) {
                    if (selectedMuList?.length !== value.length) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  }
                }
              }}
              filterSelectedOptions
              inputProps={{
                readOnly: Boolean(viewReadOnly) ? true : false,
              }}
              // disabled={Boolean(viewReadOnly) ? true : false}
              className="formField"
              renderInput={(params) => (
                <TextField
                  label={<span className="required">Mobile units </span>}
                  {...params}
                  multiline
                  rows={2}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box>
          <Box sx={{ mb: "40px", mt: "30px" }}>
            <Typography className="block-heading">
              <span className="heading">CREDENTIALS</span>
            </Typography>
          </Box>
        </Box>
        <Grid id="sub-form-box" container spacing={2}>
          {/* USername */}
          <Grid
            item
            xs={6}
            md={6}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                inputProps={{
                  // autoComplete: "off",
                  form: {
                    autoComplete: "off",
                  },
                  readOnly: Boolean(addSubAdmin) ? false : true,
                  disabled: Boolean(addSubAdmin) ? false : true,
                }}
                label={
                  <span className="required">
                    Username {!viewReadOnly && <sup> *</sup>}
                  </span>
                }
                placeholder="Username"
                // autoComplete="off"
                key="username"
                value={subCredential.username}
                // onKeyPress={handleKeyText}
                onChange={(e) => {
                  setSubCredential({
                    ...subCredential,
                    username: e.target.value,
                  });
                  if (addSubAdmin) {
                    if (e.target.value && e.target.value.length > 0) {
                      setFormChange(true);
                    } else {
                      setFormChange(formChange);
                    }
                  } else {
                    if (editSubAdmin) {
                      if (subCredential.username !== e.target.value) {
                        setFormChange(true);
                      } else {
                        setFormChange(formChange);
                      }
                    }
                  }
                  {
                    error &&
                      formErrors.username &&
                      onChangeValidate("username", e.target.value);
                  }
                }}
                onBlur={(e) => {
                  onChangeValidate("username", e.target.value);
                }}
              />
              {error && formErrors.username ? (
                <span className="form-error text-danger">
                  {formErrors.username}
                </span>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          {addSubAdmin && (
            <>
              <Grid
                item
                xs={6}
                md={6}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    placeholder="Password"
                    label={
                      <span className="required">
                        Password {!viewReadOnly && <sup> *</sup>}
                      </span>
                    }
                    type={showPassword ? "text" : "password"}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputProps={{
                      readOnly: Boolean(viewReadOnly) ? true : false,
                      disabled: Boolean(viewReadOnly) ? true : false,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={subCredential.password}
                    onChange={(e) => {
                      setSubCredential({
                        ...subCredential,
                        password: e.target.value,
                      });
                      if (addSubAdmin) {
                        if (e.target.value && e.target.value.length > 0) {
                          setFormChange(true);
                        } else {
                          setFormChange(formChange);
                        }
                      } else {
                        if (editSubAdmin) {
                          if (subCredential.password !== e.target.value) {
                            setFormChange(true);
                          } else {
                            setFormChange(formChange);
                          }
                        }
                      }

                      onChangeValidate("password", e.target.value);
                    }}
                    onBlur={(e) => {
                      onChangeValidate("password", e.target.value);
                    }}
                  />
                  {error && formErrors.password ? (
                    <span className="form-error text-danger">
                      {formErrors.password}
                    </span>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <FormControl fullWidth sx={{ m: 1 }}>
                  <TextField
                    label={
                      <span className="required">
                        Confirm Password {!viewReadOnly && <sup> *</sup>}
                      </span>
                    }
                    placeholder="Confirm Password "
                    type={showCPassword ? "text" : "password"}
                    inputProps={{
                      // autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    InputProps={{
                      readOnly: Boolean(viewReadOnly) ? true : false,
                      disabled: Boolean(viewReadOnly) ? true : false,

                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCPassword}
                            onMouseDown={handleMouseDownCPassword}
                          >
                            {showCPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={subCredential.cpassword}
                    onChange={(e) => {
                      setSubCredential({
                        ...subCredential,
                        cpassword: e.target.value,
                      });
                      if (addSubAdmin) {
                        if (e.target.value && e.target.value.length > 0) {
                          setFormChange(true);
                        } else {
                          setFormChange(formChange);
                        }
                      } else {
                        if (editSubAdmin) {
                          if (subCredential.cpassword !== e.target.value) {
                            setFormChange(true);
                          } else {
                            setFormChange(formChange);
                          }
                        }
                      }

                      onChangeValidate("cpassword", e.target.value);
                    }}
                    onBlur={(e) => {
                      onChangeValidate("cpassword", e.target.value);
                    }}
                  />
                  {error && formErrors.cpassword ? (
                    <span className="form-error text-danger">
                      {formErrors.cpassword}
                    </span>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </>
          )}
          {editSubAdmin && (
            <Grid
              item
              md={12}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              className="grid-spacing"
            >
              <Grid
                item
                md={6}
                sx={{ display: "flex", alignItems: "center" }}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Button
                  sx={styles.btnStyle}
                  onClick={handleOpenCredModal}
                  className="grid-btn"
                >
                  Update Credentials
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Box sx={{ textAlign: "right", mt: "5px" }}>
          <>
            {!viewReadOnly && !credOpen && (
              <>
                <Button
                  type="submit"
                  // className="action-btn"
                  variant="contained"
                >
                  Save
                </Button>
              </>
            )}
          </>
        </Box>
      </form>
    </Box>
  );
};

export default SubAdminForm;
