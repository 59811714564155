import { consoleAPI } from "../../axios";

const getBookingsList = async (data) => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/booking/list",
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

// const getBookingCount = async () => {
//   const consoleInst = consoleAPI();
//   try {
//     const res = await consoleInst({
//       method: "POST",
//       url: "dashboard/bookings-count",
//     });
//     if (res.success) {
//       return res.data;
//     }
//     return Promise.reject(res);
//   } catch (err) {}
// };
export { getBookingsList , 
  // getBookingCount
 };


