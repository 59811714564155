import { consoleAPI } from "../../axios";

const getServiceList = async () => {
  const consoleInst = consoleAPI();
  try {
    const res = await consoleInst({
      method: "POST",
      url: "console/service/list",
    });
    if (res.success) {
      console.log(res, "res getServiceList");
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const createService = async (data) => {
  const consoleInst = consoleAPI();
  const url = "console/service/create";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getCategories = async () => {
  const consoleInst = consoleAPI();
  const url = "common/get-categories";
  try {
    const res = await consoleInst({
      method: "GET",
      url,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getService = async (data) => {
  const consoleInst = consoleAPI();
  const url = "console/service/data";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getVehicleVariants = async () => {
  const consoleInst = consoleAPI();
  const url = "common/get-vehicle-variants";
  try {
    const res = await consoleInst({
      method: "GET",
      url,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
const changeStatus = async (data) => {
  console.log(data, "hangeStatus ");
  const consoleInst = consoleAPI();
  const url = "console/service/change-status";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
const deleteService = async (data) => {
  const consoleInst = consoleAPI();
  const url = "console/service/delete";
  try {
    const res = await consoleInst({
      method: "POST",
      url,
      data,
    });
    if (res.success) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
export {
  getServiceList,
  createService,
  getCategories,
  getService,
  getVehicleVariants,
  changeStatus,
  deleteService,
};
